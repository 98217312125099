import { FC, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { parseISO, lightFormat } from "date-fns";
import { ModalHeader } from "components/Modal";
import { Formik, FieldArray } from "formik";
import { CaseLookupCard } from "../CaseLookupCard";
import { CaseLookupParams } from "../NewAppointmentRequestPage";
import gql from "graphql-tag";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { BlockSpinner } from "components/BlockSpinner";
import { localDateToISO, localDateRegex } from "lib/localDateToISO";
import { Button } from "components/Button";
import { icdRequired as icdRequiredForState } from "lib/stateRegulations";

const EXTERNAL_CASE_LOOKUPS = gql`
  query LookupCases($casesParams: [CaseLookupParams!]!) {
    externalCasesLookup(casesParams: $casesParams) {
      results {
        error {
          message
        }
        caseInfo {
          isP2pEligible
          message
          caseNumber
          episodeId
          memberFirstName
          memberLastName
          memberDob
          memberId
          caseSkills {
            id
            name
          }
          healthPlan {
            id
            name
          }
          healthPlanType
          sslState {
            id
            name
          }
          modality {
            id
            name
          }
          insurancePlanCode
          levelOfReview
          systemName
          p2pValidUntilDate
          p2pValidUntilDatetime
          allowedProviderDomainIds
          excludedProviderDomainIds
          sameSpecialtyMatchRequired
          sameStateLicensureRequired
          orderingPhysicianSpecialty {
            id
            name
          }
          sameStateLicensureState {
            id
            name
            abbreviation
          }
          json
        }
      }
    }
  }
`;

/**
 * Struct version of the lookup API response
 */
export type CaseInfo = {
  isP2pEligible?: boolean;
  message?: string;
  caseNumber?: string;
  episodeId?: string;
  memberFirstName?: string;
  memberLastName?: string;
  memberDob?: string;
  memberId?: string;
  caseSkills?: {
    id: string;
    name: string;
  }[];
  healthPlan?: {
    id: string;
    name: string;
  };
  healthPlanType?: string;
  sslState?: {
    id: string;
    name: string;
  };
  modality?: {
    id: string;
    name: string;
  };
  insurancePlanCode?: string;
  levelOfReview?: string;
  systemName?: string;
  p2pValidUntilDate?: string;
  p2pValidUntilDatetime?: string;
  allowedProviderDomainIds?: string[];
  excludedProviderDomainIds?: string[];
  sameSpecialtyMatchRequired: boolean;
  sameStateLicensureRequired: boolean;
  orderingPhysicianSpecialty?: {
    id: string;
    name: string;
  };
  sameStateLicensureState?: {
    id: string;
    name: string;
    abbreviation: string;
  };
  json: JSONObject;
};

type CaseLookupResult = {
  error?: { message: string };
  caseInfo?: CaseInfo;
};

interface Data {
  externalCasesLookup: {
    results: CaseLookupResult[];
  };
}

export type FormCaseProfileFields = {
  submitThisCase: boolean;
  isP2pEligible: boolean;
  lookupMessage: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  healthPlanId: string;
  healthPlanType: string;
  memberMembershipNumber: string;
  memberStateId: string;
  modalityId: string;
  caseReferenceNumber: string;
  episodeId: string;
  insurancePlanCode: string;
  levelOfReview: string;
  externalSystemName: string;
  p2pValidUntilDate: string;
  p2pValidUntilDatetime: string | void;
  pathwaySkillIds: string[];
  allowedProviderDomainIds?: string[];
  excludedProviderDomainIds?: string[];
  sameSpecialtyMatchRequired: boolean;
  orderingPhysicianSpecialtyId?: string;
  sameStateLicensureRequired: boolean;
  sameStateLicensureStateId?: string;
  icd10Code: string;
  evicoreApiResponse: JSONObject;
};

type FormValues = {
  caseProfiles: FormCaseProfileFields[];
};

function reformatDob(dob: string | void): string {
  if (!!dob) {
    const [yyyy, mm, dd] = dob.split("-");
    return `${mm}/${dd}/${yyyy}`;
  } else {
    return "";
  }
}

function caseResultAsFieldsets(
  caseResult: CaseLookupResult
): FormCaseProfileFields {
  const { caseInfo, error } = caseResult;
  if (caseInfo) {
    return {
      submitThisCase:
        caseInfo.isP2pEligible ||
        caseInfo.levelOfReview === "p2p_consult_only" ||
        false,
      isP2pEligible: caseInfo.isP2pEligible || false,
      lookupMessage: caseInfo.message || "",
      memberFirstName: caseInfo.memberFirstName || "",
      memberLastName: caseInfo.memberLastName || "",
      memberDob: reformatDob(caseInfo.memberDob),
      healthPlanId: caseInfo.healthPlan ? caseInfo.healthPlan.id : "",
      healthPlanType: caseInfo.healthPlanType || "",
      memberMembershipNumber: caseInfo.memberId || "",
      memberStateId: caseInfo.sslState ? caseInfo.sslState.id : "",
      modalityId: caseInfo.modality ? caseInfo.modality.id : "",
      caseReferenceNumber: caseInfo.caseNumber || "",
      episodeId: caseInfo.episodeId || "",
      insurancePlanCode: caseInfo.insurancePlanCode || "",
      levelOfReview: caseInfo.levelOfReview || "",
      externalSystemName: caseInfo.systemName || "",
      p2pValidUntilDate: caseInfo.p2pValidUntilDate || "",
      p2pValidUntilDatetime: caseInfo.p2pValidUntilDatetime,
      pathwaySkillIds: (caseInfo.caseSkills || []).map((s) => s.id),
      allowedProviderDomainIds: caseInfo.allowedProviderDomainIds,
      excludedProviderDomainIds: caseInfo.excludedProviderDomainIds,
      sameSpecialtyMatchRequired: caseInfo.sameSpecialtyMatchRequired,
      sameStateLicensureRequired: caseInfo.sameStateLicensureRequired,
      orderingPhysicianSpecialtyId: caseInfo.orderingPhysicianSpecialty?.id,
      sameStateLicensureStateId: caseInfo.sameStateLicensureState?.id,
      icd10Code: "",
      evicoreApiResponse: caseInfo.json,
    };
  } else {
    return {
      submitThisCase: false,
      isP2pEligible: false,
      lookupMessage: error ? error.message : "Case lookup failed.",
      memberFirstName: "",
      memberLastName: "",
      memberDob: "",
      healthPlanId: "",
      healthPlanType: "",
      memberMembershipNumber: "",
      memberStateId: "",
      modalityId: "",
      caseReferenceNumber: "",
      episodeId: "",
      insurancePlanCode: "",
      levelOfReview: "",
      externalSystemName: "",
      p2pValidUntilDate: "",
      p2pValidUntilDatetime: "",
      pathwaySkillIds: [],
      allowedProviderDomainIds: [],
      excludedProviderDomainIds: [],
      sameSpecialtyMatchRequired: false,
      sameStateLicensureRequired: false,
      orderingPhysicianSpecialtyId: undefined,
      sameStateLicensureStateId: undefined,
      icd10Code: "",
      evicoreApiResponse: {},
    };
  }
}

const CREATE_DRAFT_APPOINTMENT_REQUEST = gql`
  mutation CreateDraftAppointmentRequest($caseProfiles: [CaseProfileInput!]!) {
    createDraftAppointmentRequest(caseProfiles: $caseProfiles) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        caseProfiles {
          id
          memberFirstName
        }
      }
    }
  }
`;

interface MutationData {
  createDraftAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      caseProfiles: {
        id: string;
        memberFirstName: string;
      }[];
    };
  };
}

function prepareValidUntilDate(dateString: string): string | undefined {
  if (!dateString) {
    return undefined;
  } else {
    return lightFormat(parseISO(dateString), "yyyy-MM-dd");
  }
}

interface CaseLookupModalProps {
  caseLookups: CaseLookupParams[];
  onClose(): void;
  onCreate(appointmentRequestId: string): void;
}

export const CaseLookupModal: FC<CaseLookupModalProps> = ({
  caseLookups,
  onCreate,
  onClose,
}) => {
  const vars = {
    casesParams: caseLookups,
  };
  const [editingCards, setEditingCards] = useState(false);

  const { data, loading, error } = useQuery<Data>(EXTERNAL_CASE_LOOKUPS, {
    variables: vars,
    fetchPolicy: "network-only",
  });

  const [createDraftAppointmentRequest] = useMutation<MutationData>(
    CREATE_DRAFT_APPOINTMENT_REQUEST
  );

  const icdRequired = !!data?.externalCasesLookup.results.find(
    (caseLookupResult) =>
      icdRequiredForState(
        caseLookupResult.caseInfo?.sslState?.name || "",
        caseLookupResult.caseInfo?.healthPlanType || ""
      )
  );

  return (
    <div className="CaseLookupModal bg-gray-100 rounded-lg">
      <ModalHeader icon="search" title="Case Lookup" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="flex flex-col items-center justify-around px-4 pt-4 pb-8">
            <p className="mb-4 text-xl text-gray-700">
              Fetching case information from eviCore systems
            </p>
            <BlockSpinner />
          </div>
        ) : error || !data?.externalCasesLookup.results ? (
          <h1>Failed to load</h1>
        ) : (
          <div>
            <Formik<FormValues>
              initialValues={{
                caseProfiles: data.externalCasesLookup.results.map(
                  (caseLookupResult, index) => {
                    const caseProfile = caseResultAsFieldsets(caseLookupResult);
                    if (!caseProfile.caseReferenceNumber) {
                      caseProfile.caseReferenceNumber =
                        caseLookups[index].caseReferenceNumber;
                    }
                    if (!caseProfile.memberDob) {
                      caseProfile.memberDob = caseLookups[index].memberDob;
                    }
                    // if (!caseProfile.externalSystemName) {
                    //   caseProfile.externalSystemName =
                    //     caseLookups[index].externalSystemName;
                    // }
                    return caseProfile;
                  }
                ),
              }}
              validationSchema={Yup.object().shape({
                caseProfiles: Yup.array()
                  .of(
                    Yup.object()
                      .shape({
                        submitThisCase: Yup.bool()
                          .test(
                            "submitThisCase",
                            "You must either check this box to override this case or click 'Remove'.",
                            (value) => value === true
                          )
                          .required("Required"),
                        memberFirstName: Yup.string().required("Required"),
                        memberLastName: Yup.string().required("Required"),
                        memberDob: Yup.string()
                          .required("Required")
                          .matches(localDateRegex, {
                            message: "Invalid date",
                          }),
                        healthPlanId: Yup.string().required("Required"),
                        memberMembershipNumber: Yup.string().required(
                          "Required"
                        ),
                        memberStateId: Yup.string().required("Required"),
                        modalityId: Yup.string().required("Required"),
                        caseReferenceNumber: Yup.string().required("Required"),
                        levelOfReview: Yup.string().required("Required"),
                        externalSystemName: Yup.string()
                          .required("Required")
                          .oneOf(["Isaac", "ImageOne", "eviCore Platform"]),
                        icd10Code: icdRequired
                          ? Yup.string().required("Required")
                          : Yup.string(),
                      })
                      .required("Required")
                  )
                  .required("Required"),
              })}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });
                const vals = {
                  caseProfiles: values.caseProfiles
                    .filter((cp) => cp.submitThisCase)
                    .map((cp) => ({
                      ...cp,
                      memberDob: localDateToISO(cp.memberDob),
                      p2pValidUntilDatetime: cp.p2pValidUntilDatetime,
                      p2pValidUntilDate: prepareValidUntilDate(
                        cp.p2pValidUntilDate
                      ),
                      submitThisCase: undefined,
                      isP2pEligible: undefined,
                    })),
                };
                return createDraftAppointmentRequest({
                  variables: { caseProfiles: vals.caseProfiles },
                }).then(
                  (resp) => {
                    if (resp?.data?.createDraftAppointmentRequest.errors) {
                      setEditingCards(true);
                      setStatus({
                        errors: resp.data.createDraftAppointmentRequest.errors,
                      });
                    } else if (
                      resp?.data?.createDraftAppointmentRequest
                        .appointmentRequest
                    ) {
                      // it worked...
                      return onCreate(
                        resp.data.createDraftAppointmentRequest
                          .appointmentRequest.id
                      );
                    }
                    setSubmitting(false);
                  },
                  () => setSubmitting(false)
                );
              }}
              validateOnMount
            >
              {({ status, isSubmitting, values, isValid, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />
                  <FieldArray
                    name="caseProfiles"
                    render={(arrayHelpers) => (
                      <div>
                        {values.caseProfiles &&
                        values.caseProfiles.length > 0 ? (
                          values.caseProfiles.map((caseProfile, index) => (
                            <CaseLookupCard
                              key={index}
                              index={index}
                              caseLookupParams={caseLookups[index]}
                              caseInfo={
                                data.externalCasesLookup.results[index].caseInfo
                              }
                              isEditable={!!caseProfile.isP2pEligible}
                              isEditing={editingCards}
                              toggleEditing={() =>
                                setEditingCards(!editingCards)
                              }
                              onRemove={() => arrayHelpers.remove(index)}
                              caseProfileValues={caseProfile}
                            />
                          ))
                        ) : (
                          <div>No cases being looked up</div>
                        )}
                      </div>
                    )}
                  />
                  <div
                    className="flex items-center justify-around"
                    style={{ marginTop: "1rem", padding: "1rem" }}
                  >
                    {!editingCards && !isValid ? (
                      <Button
                        type="button"
                        kind="primary"
                        color="gold"
                        onClick={() => setEditingCards(true)}
                      >
                        Edit Values
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        kind="primary"
                        color="gold"
                        size="lg"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Continue
                      </Button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};
