// @ts-check
const LOCAL_STORAGE_TOKEN_KEY = "ovicare-token";

const tokenStore = {
  /**
   * Stores the given token in localStorage
   * param {Token} token The JSON object representing the auth token (including jwt, exp, etc.)
   */
  storeToken(token: Token) {
    // console.log("::storeToken", token);
    return localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(token));
  },

  /**
   * Gets the token stored in localStorage
   * returns {Token|null} The token object or null if there's no stored token
   */
  getStoredToken(): Token | null {
    const stringToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    const token = stringToken ? JSON.parse(stringToken) : null;
    // console.log("::getStoredToken", token);
    return token;
  },

  /**
   * Drops the currently saved token
   * @returns {void}
   */
  forgetToken() {
    // console.log("::forgetToken");
    return localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }
};

export default tokenStore;
