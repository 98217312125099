import { FC, useState, useRef, InputHTMLAttributes } from "react";
import cx from "classnames";
import { ErrorMessage, useField } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { FAIcon } from "components/FAIcon";
import { usePrevious } from "hooks/usePrevious";

interface NumberControls {
  /* Whether the input can be editted manually or only the increment/decrement buttons can be used to change the value */
  canEditManually?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

interface InputNumberProps extends NumberControls {
  value: number;
  icon?: IconProp;
  inputProps?: Partial<InputHTMLAttributes<HTMLInputElement>>;
  onChange(newValue: number): void;
  onBlur?(e: any): void;
  className?: string;
}

function parseNumber(str: string) {
  if (str === "") return undefined;
  const number = Number(str);
  if (Number.isNaN(number)) return undefined;
  return number;
}

export const InputNumber: FC<InputNumberProps> = (props) => {
  const {
    value,
    canEditManually = true,
    min = 0,
    max = Number.MAX_VALUE,
    step = 1,
    onChange,
    onBlur,
    inputProps = {},
    className = "",
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  /* Keep track of intermediary string values, even though we're not getting valid number out on each keystroke */
  const [rawInputValue, setRawInputValue] = useState(String(value));
  const previousValue = usePrevious(value);

  if (previousValue !== value && rawInputValue !== String(value)) {
    setRawInputValue(String(value));
  }

  const isDecrementDisabled = value <= min;
  const isIncremenetDisabled = value >= max;

  /**
   * @param {number} incrementBy - amount by which to increment the current value.
   */
  function add(incrementBy: number) {
    if (!inputRef.current) return;

    const currentValue = parseNumber(inputRef.current.value);
    if (
      currentValue !== undefined &&
      currentValue + incrementBy >= min &&
      currentValue + incrementBy <= max
    ) {
      inputRef.current.focus();
      onChange(currentValue + incrementBy);
    }
  }

  function onInputChange(newValue: string) {
    const number = parseNumber(newValue);

    setRawInputValue(newValue);

    if (number !== undefined && number >= min && number <= max) {
      onChange(number);
    }
  }

  function onInputBlur(evt: any) {
    if (rawInputValue !== String(value)) {
      setRawInputValue(String(value));
    }
    if (onBlur) {
      onBlur(evt);
    }
  }

  return (
    <div className="inputNumber relative w-full" style={{ maxWidth: 150 }}>
      <div className="input">
        <div className="input-wrapper relative flex items-center">
          <input
            {...inputProps}
            className={`appearance-none block w-full px-3 py-1.5 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${className}`}
            type="number"
            ref={inputRef}
            value={rawInputValue}
            min={min}
            max={max}
            step={step}
            readOnly={!canEditManually}
            onChange={(evt) => onInputChange(evt.target.value)}
            onBlur={onInputBlur}
          />
        </div>
      </div>
      <div
        className="inputButtons absolute flex bg-gray-200 rounded-r"
        style={{ top: 1, right: 1, bottom: 1, width: 60 }}
      >
        <span
          className={cx(
            "flex-1 text-center text-xl select-none border-l border-gray-300",
            {
              "disabled bg-gray-400 text-white cursor-not-allowed": isDecrementDisabled,
              "cursor-pointer hover:bg-blue-200 text-gray-600 hover:text-gray-800": !isDecrementDisabled,
            }
          )}
          onClick={() => add(-step)}
        >
          -
        </span>
        <span
          className={cx(
            "rounded-r flex-1 text-center text-xl select-none border-l border-gray-300",
            {
              "disabled bg-gray-400 text-white cursor-not-allowed": isIncremenetDisabled,
              "cursor-pointer hover:bg-blue-200 text-gray-600 hover:text-gray-800": !isIncremenetDisabled,
            }
          )}
          onClick={() => add(+step)}
        >
          +
        </span>
      </div>
    </div>
  );
};

interface NumberInputProps extends NumberControls {
  id?: string;
  name: string;
  placeholder?: string;
  icon?: IconProp;
  autoFocus?: boolean;
  className?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const NumberInput: FC<NumberInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    icon,
    autoFocus = false,
    className = "",
    inputProps = {},
  } = props;

  const [field, meta, helpers] = useField<number>(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  const cn =
    meta && meta.touched && meta.error
      ? `${className} border border-red-500`
      : className;

  return (
    <>
      <InputNumber
        inputProps={{
          id: id || name,
          placeholder,
          name,
          autoFocus,
          className: cn,
          ...inputProps,
        }}
        icon={icon}
        value={value}
        onChange={setValue}
        onBlur={onBlur}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * NumberField.
 */

interface NumberFieldProps extends NumberInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const NumberField: FC<NumberFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <NumberInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalNumberField: FC<NumberFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <NumberInput {...rest} />
    </HorizontalField>
  );
};
