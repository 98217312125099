import React, { FC, useEffect } from "react";
import qs from "query-string";
import { Redirect, useLocation } from "react-router-dom";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useAuth } from "components/AuthContext";
import { coalesceString } from "lib/coalesce";

/**
 * This is used after an IdP has sent us a SAML assertion.
 * We'll redirec the user's browser to this page, which will
 * POST to our API, where there's a valid SAML assertion in
 * the session, and this Mutation will return a JWT for our
 * client-side SPA to use to authenticate the user.
 *
 * After retriving the JWT from the server, we'll finally
 * redirect the user to the SPA page they were originally
 * trying to access (or the /dashboard) if there isn't one.
 */
const ISSUE_JWT_FOR_SAML_AUTHD_TOKEN = gql`
  mutation IssueJWTForSAMLAuthdToken($id: UUID4!, $token: String!) {
    issueJwtForSamlAuthenticatedToken(id: $id, token: $token) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  issueJwtForSamlAuthenticatedToken: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

export const SAMLRedirectPage: FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search);
  const { id: userId, token, return_to: returnToPath } = params;

  const { signIn } = useAuth();

  const [fetchJwtForSaml, { data, loading, error }] = useMutation<MutationData>(
    ISSUE_JWT_FOR_SAML_AUTHD_TOKEN
  );

  useEffect(() => {
    fetchJwtForSaml({
      variables: {
        id: userId,
        token: token,
      },
    }).then(
      (resp) => {
        if (resp?.data?.issueJwtForSamlAuthenticatedToken?.session) {
          const { token } = resp.data.issueJwtForSamlAuthenticatedToken.session;
          signIn(token);
        }
      },
      (err) => {
        console.error(err);
        throw err;
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="SAMLRedirectPage hero is-fullheight is-medium is-bold">
      <div className="hero-body">
        <div className="container text-left">
          {/* <div className="columns is-centered"> */}
          <div>
            <h1>SAMLRedirectPage</h1>
          </div>
          <p>Return Path:</p>
          <pre>{returnToPath}</pre>
          <p>location.search:</p>
          <pre>{location.search}</pre>
          <p>params:</p>
          <pre>{JSON.stringify(params, null, 2)}</pre>
          <div>
            {loading || (!data && !error) ? (
              <h1>Loading</h1>
            ) : error ? (
              <div>
                <p>Error:</p>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </div>
            ) : data && data.issueJwtForSamlAuthenticatedToken ? (
              <div>
                <p>Success:</p>
                <Redirect to={coalesceString(returnToPath)} />
                <pre>
                  {JSON.stringify(
                    data.issueJwtForSamlAuthenticatedToken,
                    null,
                    2
                  )}
                </pre>
              </div>
            ) : (
              <h1>
                {/* This should never happen. "Error Code 81" is arbitrary, but grep-able */}
                Something Went Wrong. Error Code 81.
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
