import { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import ReactModal from "react-modal";
import { TextField, TextInput } from "components/formik/TextField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { PhoneMaskField } from "components/formik/PhoneMaskField";
import { FAIcon } from "components/FAIcon";
import { SelectField } from "components/formik/SelectField";
import { PlainSelectField } from "components/formik/PlainSelectField";
import { useToast } from "layouts/PortalLayout/Toast";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { Button } from "components/Button";
import { VerticalField } from "components/formik/FieldStructure";
import { CallNotesInput, combineCallNotes } from "./CallNotesInput";

/**
 * AppointmentSlotNotAvailableModal
 */

interface AppointmentSlotNotAvailableModalProps {
  onBack(): void;
}

const AppointmentSlotNotAvailableModal: FC<AppointmentSlotNotAvailableModalProps> = (
  props
) => {
  const { onBack } = props;
  return (
    <div className="AppointmentSlotNotAvailableModal" style={{ width: 500 }}>
      <div className="text-center bg-white rounded-lg shadow-lg">
        <div
          style={{
            padding: "2.5rem 2rem",
            margin: "0 auto",
            fontSize: "4.5rem",
          }}
        >
          <FAIcon icon={["far", "calendar-times"]} />
        </div>
        <section style={{ padding: "0 1.5rem 2rem" }}>
          <h1 style={{ fontSize: "1.5rem", fontWeight: 600 }}>
            Appointment no longer available
          </h1>
          <p style={{ fontSize: "1.25rem" }}>
            Please select a new appointment time.
          </p>
        </section>
        <section className="px-6 pb-8">
          <Button type="button" kind="primary" color="gold" onClick={onBack}>
            <span className="mr-2">
              <FAIcon icon="arrow-left" />
            </span>
            Back to search results
          </Button>
        </section>
      </div>
    </div>
  );
};

const CREATE_APPOINTMENT_REQUEST_MUTATION = gql`
  mutation SendAppointmentRequestWithAppointment(
    $id: UUID4!
    $appointmentRequest: ContactDetailsInput!
    $appointment: AppointmentInput!
  ) {
    sendAppointmentRequestWithAppointment(
      id: $id
      appointmentRequest: $appointmentRequest
      appointment: $appointment
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  sendAppointmentRequestWithAppointment: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

const contactPersonLocationOptions: Option[] = [
  {
    value: "Provider Office",
    label: "Provider Office",
  },
  {
    value: "Health Plan",
    label: "Health Plan",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const credentialOptions: Option[] = [
  { value: "", label: "Select..." },
  ...[
    "MD",
    "NP",
    "PA",
    "DO",
    "APN/APRN",
    "OB",
    "PT",
    "OT",
    "ST",
    "DC",
    "Acupuncturist",
    "MT",
    "Genetic Counselor",
    "Other",
  ].map((val) => ({
    value: val,
    label: val,
  })),
];

const whoIsPerformingP2pOptions: Option[] = [
  {
    value: "Requesting Provider",
    label: "Requesting Provider",
  },
  {
    value: "Contact Person",
    label: "Contact Person",
  },
  {
    value: "Other",
    label: "Someone else",
  },
];

interface FormValues {
  requestingProviderFirstName: string;
  requestingProviderLastName: string;
  requestingProviderCredential: string;
  otherRequestingProviderCredential: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonLocation: string;
  contactPersonCredential: string;
  otherContactPersonCredential: string;
  whoIsPerformingP2p: string;
  otherP2pContactFirstName: string;
  otherP2pContactLastName: string;
  otherP2pContactCredential: string;
  otherP2pContactOtherCredential: string;
  contactPhoneNumber: string;
  contactPhoneNumberExtension: string;
  alternateContactPhoneNumber: string;
  alternateContactPhoneNumberExtension: string;
  contactEmail: string;
  contactInstructions: string;
  callNotes: string[];
  procedureDate?: string;
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    requestingProviderFirstName: Yup.string().required("Required"),
    requestingProviderLastName: Yup.string().required("Required"),
    requestingProviderCredential: Yup.string().required("Required"),
    contactPersonFirstName: Yup.string().required("Required"),
    contactPersonLastName: Yup.string().required("Required"),
    whoIsPerformingP2p: Yup.string().required("Required"),
    contactPhoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    alternateContactPhoneNumber: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    contactEmail: Yup.string().email("Invalid email address"),
    contactInstructions: Yup.string(),
  })
  .required();

interface ConfirmationFormProps {
  appointmentRequestId: string;
  startTime: string;
  finishTime: string;
  providerId: string;
}

export const ConfirmationForm: FC<ConfirmationFormProps> = (props) => {
  const { appointmentRequestId, startTime, finishTime, providerId } = props;
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const toast = useToast();

  const [sendAppointmentRequestWithAppointment] = useMutation<MutationData>(
    CREATE_APPOINTMENT_REQUEST_MUTATION
  );

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      const { setStatus, setSubmitting, setFieldError } = formikHelpers;

      setStatus({ errors: null });

      const extraErrors = validateExtras(formValues);

      if (Object.keys(extraErrors).length > 0) {
        for (let key in extraErrors) {
          setFieldError(key, extraErrors[key]);
        }
        setSubmitting(false);
        return;
      }

      const values = combineCallNotes("callNotes", formValues);

      const variables = {
        id: appointmentRequestId,
        appointmentRequest: {
          ...values,
          requestingProviderCredential:
            values.requestingProviderCredential === "Other"
              ? values.otherRequestingProviderCredential.trim()
              : values.requestingProviderCredential,
          otherRequestingProviderCredential: undefined,
          contactPersonCredential:
            values.contactPersonCredential === "Other"
              ? values.otherContactPersonCredential.trim()
              : values.contactPersonCredential,
          otherContactPersonCredential: undefined,
          otherP2pContactCredential:
            values.otherP2pContactCredential === "Other"
              ? values.otherP2pContactOtherCredential.trim()
              : values.otherP2pContactCredential,
          otherP2pContactOtherCredential: undefined,
        },
        appointment: {
          timeRange: {
            start: startTime,
            finish: finishTime,
          },
          receivingProviderId: providerId,
        },
      };

      try {
        const { data } = await sendAppointmentRequestWithAppointment({
          variables,
        });

        if (data?.sendAppointmentRequestWithAppointment.errors) {
          const { errors } = data.sendAppointmentRequestWithAppointment;
          if (isScheduleConflict(errors)) {
            setModalOpen(true);
          }
          setStatus({ errors });
        } else if (
          data?.sendAppointmentRequestWithAppointment.appointmentRequest
        ) {
          // it worked...
          const {
            appointmentRequest,
          } = data.sendAppointmentRequestWithAppointment;
          return history.push(`/o/requests/${appointmentRequest.id}`);
        }
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
        setSubmitting(false);
      }
    },
    [
      toast,
      appointmentRequestId,
      sendAppointmentRequestWithAppointment,
      setModalOpen,
      startTime,
      finishTime,
      providerId,
      history,
    ]
  );

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={150}
      >
        <AppointmentSlotNotAvailableModal
          onBack={() => {
            setModalOpen(false);
            history.goBack();
          }}
        />
      </ReactModal>

      <Formik<FormValues>
        initialValues={{
          requestingProviderFirstName: "",
          requestingProviderLastName: "",
          requestingProviderCredential: "",
          otherRequestingProviderCredential: "",
          contactPersonFirstName: "",
          contactPersonLastName: "",
          contactPersonCredential: "",
          otherContactPersonCredential: "",
          whoIsPerformingP2p: "",
          otherP2pContactFirstName: "",
          otherP2pContactLastName: "",
          otherP2pContactCredential: "",
          otherP2pContactOtherCredential: "",
          contactPersonLocation: "",
          contactPhoneNumber: "",
          contactPhoneNumberExtension: "",
          alternateContactPhoneNumber: "",
          alternateContactPhoneNumberExtension: "",
          contactEmail: "",
          contactInstructions: "",
          callNotes: [],
          procedureDate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-4">
              <RadioGroupField
                name="whoIsPerformingP2p"
                label="Who will be performing the P2P consultation?"
                options={whoIsPerformingP2pOptions}
                indicateRequired
              />
            </div>

            <section className="p-4 mt-4 border rounded">
              <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                <FAIcon icon="user-md" className="mr-2" />
                Requesting Provider
              </h4>
              <div className="flex mt-4">
                <div className="flex-1">
                  <VerticalField
                    label="Name of Provider on Case"
                    indicateRequired
                  >
                    <div className="gap-x-1 grid grid-cols-2">
                      <div>
                        <TextInput
                          name="requestingProviderFirstName"
                          placeholder="First Name"
                        />
                      </div>
                      <div>
                        <TextInput
                          name="requestingProviderLastName"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </VerticalField>
                </div>
                <div className="w-36 ml-2">
                  <PlainSelectField
                    name="requestingProviderCredential"
                    label="Credential"
                    options={credentialOptions}
                    indicateRequired
                  />
                </div>
              </div>

              {values.requestingProviderCredential === "Other" ? (
                <div className="mt-4">
                  <TextField
                    name="otherRequestingProviderCredential"
                    label="Please specify provider credential"
                    indicateRequired
                  />
                </div>
              ) : null}
            </section>

            <section className="p-4 mt-4 border rounded">
              <div className="flex items-center">
                <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                  <FAIcon icon={["far", "address-card"]} className="mr-2" />
                  Contact Person
                </h4>

                <SameAsProviderCheckbox className="ml-4" />
              </div>

              <div className="flex mt-4">
                <div className="grid flex-1 grid-cols-2 gap-3">
                  <div>
                    <TextField
                      name="contactPersonFirstName"
                      label="Contact First Name"
                      indicateRequired
                    />
                  </div>
                  <div>
                    <TextField
                      name="contactPersonLastName"
                      label="Contact Last Name"
                      indicateRequired
                    />
                  </div>
                </div>
                {values.whoIsPerformingP2p === "Contact Person" ? (
                  <div className="w-36 ml-2">
                    <PlainSelectField
                      name="contactPersonCredential"
                      label="Credential"
                      options={credentialOptions}
                      indicateRequired
                    />
                  </div>
                ) : null}
              </div>

              {values.whoIsPerformingP2p === "Contact Person" &&
              values.contactPersonCredential === "Other" ? (
                <div className="mt-4">
                  <TextField
                    name="otherContactPersonCredential"
                    label="Please specify provider credential"
                    indicateRequired
                  />
                </div>
              ) : null}

              <div className="mt-4">
                <SelectField
                  name="contactPersonLocation"
                  label="Contact Person Location"
                  options={contactPersonLocationOptions}
                  indicateRequired
                />
              </div>
            </section>

            <section className="p-4 mt-4 border rounded">
              <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                <FAIcon icon={"phone"} className="mr-2" />
                P2P Information
              </h4>
              {values.whoIsPerformingP2p === "Other" ? (
                <>
                  <div className="flex mt-4">
                    <div className="flex-1">
                      <VerticalField
                        label="Name of Person Performing P2P"
                        indicateRequired
                      >
                        <div className="gap-x-1 grid grid-cols-2">
                          <div>
                            <TextInput
                              name="otherP2pContactFirstName"
                              placeholder="First Name"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="otherP2pContactLastName"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </VerticalField>
                    </div>
                    <div className="w-36 ml-2">
                      <PlainSelectField
                        name="otherP2pContactCredential"
                        label="Credential"
                        options={credentialOptions}
                        indicateRequired
                      />
                    </div>
                  </div>
                  {values.otherP2pContactCredential === "Other" ? (
                    <div className="mt-4">
                      <TextField
                        name="otherP2pContactOtherCredential"
                        label="Please specify credential for Person Performing P2P"
                        indicateRequired
                      />
                    </div>
                  ) : null}
                </>
              ) : null}

              <div className="flex mt-4">
                <div className="flex-1">
                  <PhoneMaskField
                    name="contactPhoneNumber"
                    label="Phone Number for P2P"
                    icon="phone"
                    indicateRequired
                  />
                </div>

                <div className="w-36 ml-2">
                  <TextField
                    name="contactPhoneNumberExtension"
                    label="Phone Ext."
                    icon="phone"
                  />
                </div>
              </div>

              <div className="flex mt-4">
                <div className="flex-1">
                  <PhoneMaskField
                    name="alternateContactPhoneNumber"
                    label="Alternate Phone"
                    icon="phone"
                  />
                </div>

                <div className="w-36 ml-2">
                  <TextField
                    name="alternateContactPhoneNumberExtension"
                    label="Phone Ext."
                    icon="phone"
                  />
                </div>
              </div>

              <div className="mt-4">
                <TextField
                  name="contactEmail"
                  label="Requesting Provider Email"
                />
              </div>

              <div className="mt-4">
                <TextField
                  name="contactInstructions"
                  label="Contact Instructions"
                />
              </div>

              <div className="mt-4">
                <VerticalField label="Call Notes">
                  <CallNotesInput name="callNotes" />
                </VerticalField>
              </div>
            </section>

            <div className="flex justify-end mt-6">
              <Button
                type="submit"
                kind="primary"
                color="gold"
                size="lg"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Schedule Appointment
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

function SameAsProviderCheckbox(props: { className?: string }) {
  const { className = "" } = props;
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [checked, setChecked] = useState(false);

  function handleChange() {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      setFieldValue(
        "contactPersonFirstName",
        values.requestingProviderFirstName
      );
      setFieldValue("contactPersonLastName", values.requestingProviderLastName);
      setFieldValue(
        "contactPersonCredential",
        values.requestingProviderCredential
      );
    } else {
      setFieldValue("contactPersonFirstName", "");
      setFieldValue("contactPersonLastName", "");
      setFieldValue("contactPersonCredential", "");
    }
  }

  return values.whoIsPerformingP2p === "Requesting Provider" ? (
    <label className={`checkbox flex items-center text-sm ${className}`}>
      <input
        id="sameAsProvider"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ marginRight: "0.5rem" }}
        className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
      />
      Same as Requesting Provider
    </label>
  ) : null;
}

export function isScheduleConflict(errors: InputError[]): boolean {
  return errors.map((e) => e.key).includes("schedule_conflict");
}

function validateExtras(values: FormValues): Record<string, string> {
  let extraErrors: Record<string, string> = {};

  if (
    values.requestingProviderCredential === "Other" &&
    values.otherRequestingProviderCredential.trim() === ""
  ) {
    extraErrors.otherRequestingProviderCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential.trim() === ""
  ) {
    extraErrors.contactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential === "Other" &&
    values.otherContactPersonCredential.trim() === ""
  ) {
    extraErrors.otherContactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactFirstName.trim() === ""
  ) {
    extraErrors.otherP2pContactFirstName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactLastName.trim() === ""
  ) {
    extraErrors.otherP2pContactLastName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential === "Other" &&
    values.otherP2pContactOtherCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactOtherCredential = "Required";
  }

  return extraErrors;
}
