import React, { FC } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportItem } from "./ExportItem";
import "./ExportsWidget.css";

const PAGE_SIZE = 10;

export const LIST_EXPORTS = gql`
  query ListDataExports($first: Int, $after: UUID4) {
    dataExports(first: $first, after: $after) {
      cursor
      endOfList
      items {
        id
        filename
        windowStart
        windowFinish
        insertedAt
        completedAt
        status
      }
    }
  }
`;

interface Data {
  dataExports: {
    cursor?: string;
    endOfList: boolean;
    items: DataExport[];
  };
}

export type DataExport = {
  id: string;
  filename: string;
  windowStart: string;
  windowFinish: string;
  status: string;
  insertedAt: string;
  completedAt: string;
};

type ExportsWidgetProps = {};

const EmptyState: FC = () => {
  return (
    <div style={{ textAlign: "center", margin: "1.5rem auto" }}>
      <div style={{ color: "#bbb", fontSize: "3rem", margin: "1.25rem auto" }}>
        <FontAwesomeIcon icon="file-download" />
      </div>
      <p style={{ color: "#666" }}>No exports yet</p>
    </div>
  );
};

export const ExportsWidget: FC<ExportsWidgetProps> = () => {
  const { data, loading, error } = useQuery<Data>(LIST_EXPORTS, {
    variables: { first: PAGE_SIZE }
  });
  return (
    <div className="ExportsWidget">
      {loading ? (
        <div style={{ textAlign: "center", margin: "1rem" }}>
          <Spinner />
        </div>
      ) : error || !(data && data.dataExports) ? (
        <div style={{ margin: "1rem" }}>
          <p>Failed to load</p>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      ) : (
        <div>
          {data.dataExports.items && data.dataExports.items.length === 0 ? (
            <EmptyState />
          ) : null}
          {data.dataExports.items.map((dataExport: DataExport) => (
            <ExportItem key={dataExport.id} export={dataExport} />
          ))}
        </div>
      )}
    </div>
  );
};
