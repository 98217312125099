import { FC } from "react";
import { Field, useField } from "formik";
import { DateMaskInput } from "components/formik/DateMaskField";
import { required, validateLocalDate } from "components/formik/validation";

export const procedureDateLabel = "Procedure was performed on:";

const OPTIONS = [
  "ALT REC declined",
  "Procedure has not been performed",
  procedureDateLabel,
  "Caller requested MD Specialty match",
  "Appeal LOR attestation requirement",
  "Caller is aware P2P is for education only and will not change case status",
  "TX licensed physician - Caller is aware P2P does not meet SSL match and wants to proceed with P2P per same-specialty match requirement.",
  "TX licensed same specialty - Caller is aware P2P does not meet TX SSL/specialty match and wants to proceed with P2P",
];

interface CallNotesInputProps {
  name: string;
}

export const CallNotesInput: FC<CallNotesInputProps> = (props) => {
  const { name } = props;

  const [{ onChange, onBlur, ...field }, meta] = useField(name);

  const showError = !!(meta && meta.touched && meta.error);

  const controlClassName = [
    "w-full",
    showError && "-m-2 p-2 border border-red-500 rounded",
  ]
    .filter(Boolean)
    .join(" ");

  const procedurePerformed = (field.value || []).includes(procedureDateLabel);

  return (
    <div className="_CallNotesInput max-w-lg p-2">
      <div className={controlClassName}>
        {OPTIONS.map((option) => (
          <CheckboxInput
            key={option}
            name={name}
            value={option}
            className="mb-2"
          >
            <div className="flex items-center">
              {option}
              {option === procedureDateLabel ? (
                <DateMaskInput
                  className="h-6 ml-2"
                  name="procedureDate"
                  validate={
                    procedurePerformed ? [required, validateLocalDate] : []
                  }
                  quickError
                />
              ) : null}
            </div>
          </CheckboxInput>
        ))}
      </div>
    </div>
  );
};

interface CheckboxInputProps {
  id?: string;
  name: string;
  value: string;
  className?: string;
  children?: React.ReactNode;
}

function CheckboxInput(props: CheckboxInputProps) {
  const { id, name, value, className = "", children } = props;

  return (
    <label className={`checkbox flex items-start mb-1 text-sm ${className}`}>
      <Field
        type="checkbox"
        id={`${id || name}--${value}`}
        name={name}
        value={value}
        style={{ marginRight: "0.5rem" }}
        className="focus:ring-blue-500 w-4 h-4 my-1 text-blue-600 border-gray-300 rounded"
      />
      {children}
    </label>
  );
}

/**
 * Update the call notes field value with the procedure date if it is present.
 */
export function combineCallNotes<
  FormValues extends {
    procedureDate?: string;
    [key: string]: any;
  }
>(fieldName: string, values: FormValues): FormValues {
  const callNotesFieldValue: string[] = values[fieldName] || [];
  let newCallNotesFieldValue: string[] = [];

  // Loop through the fieldValue and check if the procedureDateLabel is present
  // If it is present, then update the fieldValue with the new date
  callNotesFieldValue.forEach((option) => {
    if (option === procedureDateLabel) {
      newCallNotesFieldValue.push(
        procedureDateLabel + " " + (values.procedureDate || "")
      );
    } else {
      newCallNotesFieldValue.push(option);
    }
  });

  // Return new `values` object
  let newValues = { ...values, [fieldName]: newCallNotesFieldValue };
  delete newValues.procedureDate;
  return newValues;
}
