import { OperationVariables } from "react-apollo";

export interface UpdateQueryOptions<
  TData = any,
  TVariables = OperationVariables
> {
  fetchMoreResult?: TData;
  variables?: TVariables;
}

/**
 * Used to create an `updateQuery` function for use in Apollo's `fetchMore`
 * query options.
 *
 * Example:
 *
 *    const updateQuery = makeAppendItems<ProvidersData>("providers");
 *
 *    ...
 *
 *    <button
 *      className="button"
 *      onClick={() =>
 *        fetchMore({
 *          query: ORGANIZATION_PROVIDERS,
 *          variables: {
 *            first: PAGE_SIZE,
 *            after: data.providers.cursor
 *          },
 *          updateQuery
 *        })
 *      }
 *     >
 *       Load More
 *     </button>
 */
export function makeAppendItems<TData = any, TVariables = OperationVariables>(
  key: keyof TData
): (
  previousQueryResult: TData,
  options: UpdateQueryOptions<TData, TVariables>
) => TData {
  return (prev: TData, options: UpdateQueryOptions<TData, TVariables>) => {
    const { fetchMoreResult } = options;
    if (!fetchMoreResult) {
      return prev;
    } else {
      return Object.assign({}, fetchMoreResult, {
        [key]: {
          ...fetchMoreResult[key],
          items: [
            ...(prev[key] as any).items,
            ...(fetchMoreResult[key] as any).items
          ]
        }
      });
    }
  };
}
