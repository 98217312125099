import { FC, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { useRouteMatch } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { Calendar } from "./Calendar";
import { parseISO, startOfWeek, endOfWeek } from "date-fns";
import { iso8601Date } from "lib/dateFormatters";

const PROVIDER_TIME_BLOCKS = gql`
  query ProviderTimeBlocks(
    $providerId: UUID4!
    $appointmentsFilter: AppointmentsFilter!
    $timeBlocksFilter: TimeBlocksFilter!
  ) {
    provider(id: $providerId) {
      id
      firstName
      lastName
    }
    appointments(filter: $appointmentsFilter) {
      id
      appointmentRequestId
      timeRange {
        start
        finish
      }
    }
    timeBlocks(filter: $timeBlocksFilter) {
      id
      available
      timeRange {
        start
        finish
      }
      isShiftStart
      isShiftEnd
      isLunch
      isMeeting
      collectionName
      reservedForExternalSystem
      insertedAt
      updatedAt
    }
  }
`;

interface Data {
  provider: {
    id: string;
    firstName: string;
    lastName: string;
  };
  timeBlocks: TimeBlockModel[];
  appointments: AppointmentModel[];
}

interface TimeBlockModel {
  id: string;
  available: boolean;
  timeRange: {
    start: string;
    finish: string;
  };
  isShiftStart: boolean;
  isShiftEnd: boolean;
  isLunch: boolean;
  isMeeting: boolean;
  collectionName: string;
  reservedForExternalSystem: string;
}

interface AppointmentModel {
  id: string;
  appointmentRequestId: string;
  timeRange: {
    start: string;
    finish: string;
  };
}

interface Variables {
  providerId: string;
  appointmentsFilter: AppointmentsFilter;
  timeBlocksFilter: TimeBlocksFilter;
}

interface TimeBlocksFilter {
  providerId: string;
}

interface AppointmentsFilter {
  providerId: string;
}

interface RouteParams {
  providerId: string;
}

interface ProviderCalendarProps {}

export const ProviderCalendar: FC<ProviderCalendarProps> = (props) => {
  const match = useRouteMatch<RouteParams>();
  const { providerId } = match.params;

  const [date, setDate] = useState<Date>(new Date());
  const weekStartIso = iso8601Date(startOfWeek(date));
  const weekFinishIso = iso8601Date(endOfWeek(date));

  const dateRange = {
    start: weekStartIso,
    finish: weekFinishIso,
  };
  const timeBlocksFilter = { providerId, dateRange };
  const appointmentsFilter = {
    providerId,
    dateRange,
    excludeMissedAndCancelled: true,
  };

  const { data, loading, error } = useQuery<Data, Variables>(
    PROVIDER_TIME_BLOCKS,
    {
      variables: { providerId, appointmentsFilter, timeBlocksFilter },
    }
  );

  return (
    <div className="_ProviderCalendar p-4 bg-white border rounded-lg shadow-xl">
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !data?.timeBlocks ? (
        <p>Failed to load.</p>
      ) : (
        <div>
          <p className="pb-3 text-xl font-bold">
            Provider: {data.provider.firstName} {data.provider.lastName}
          </p>
          <div className="p-3">
            <p className="mb-2 text-sm font-semibold underline">Legend:</p>
            <div className="flex gap-4 text-sm">
              <p className="flex items-center mb-2">
                <span
                  className="inline-block w-4 h-4 mr-1"
                  style={{ backgroundColor: "rgba(8, 124, 55, 0.7)" }}
                />
                = Available (IEX)
              </p>
              <p className="flex items-center mb-2">
                <span
                  className="inline-block w-4 h-4 mr-1"
                  style={{ backgroundColor: "rgba(73, 73, 73, 0.7)" }}
                />
                = Unavailable (IEX)
              </p>
              <p className="flex items-center mb-2">
                <span
                  className="inline-block w-4 h-4 mr-1"
                  style={{ backgroundColor: "rgba(174, 33, 42, 0.7)" }}
                />
                = Unavailable (Outlook)
              </p>
              <p className="flex items-center mb-2">
                <span
                  className="inline-block w-4 h-4 mr-1"
                  style={{ backgroundColor: "rgba(38, 27, 195, 0.7)" }}
                />
                = P2P Appointment (Preferral)
              </p>
            </div>
          </div>

          <Calendar
            date={date}
            onNavigate={setDate}
            onSelectEvent={(event: any) => {
              if (event.appointmentRequestId) {
                window
                  .open(`/o/requests/${event.appointmentRequestId}`, "_blank")
                  ?.focus();
              }
            }}
            events={data.timeBlocks
              .map((tb) => ({
                system: tb.collectionName,
                available: tb.available,
                title: title(tb),
                start: parseISO(tb.timeRange.start),
                finish: parseISO(tb.timeRange.finish),
              }))
              .concat(
                data.appointments.map((a) => ({
                  appointmentRequestId: a.appointmentRequestId,
                  system: "Preferral",
                  available: false,
                  title: "P2P",
                  start: parseISO(a.timeRange.start),
                  finish: parseISO(a.timeRange.finish),
                }))
              )}
          />
        </div>
      )}
    </div>
  );
};

function title(timeBlock: TimeBlockModel): string {
  if (timeBlock.available) {
    return timeBlock.reservedForExternalSystem
      ? `Available (${timeBlock.reservedForExternalSystem})`
      : "Available";
  } else {
    if (timeBlock.isLunch) {
      return "Lunch";
    } else if (timeBlock.isMeeting) {
      return "Outlook Blocked";
    } else {
      return "Unavailable";
    }
  }
}
