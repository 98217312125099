import { FC } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik } from "formik";
import * as Yup from "yup";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextAreaField } from "components/formik/TextArea";
import { HorizontalSingleCheckboxField } from "components/formik/SingleCheckboxField";
import { Modal, ModalHeader } from "components/Modal";
import { Button } from "components/Button";
import {
  AppointmentModel,
  AppointmentRequestModel,
  APPOINTMENT_REQUEST_SHOW_QUERY,
} from "../../AppointmentRequestShowPage";
import { BigAppointmentDetails } from "../ViewAppointmentModal/BigAppointmentDetails";

const MISS_APPOINTMENT = gql`
  mutation MissAppointment($id: UUID4!, $appointment: MissAppointmentInput!) {
    missAppointment(id: $id, appointment: $appointment) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  missAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MissAppointmentModalProps {
  appointmentRequest: AppointmentRequestModel;
  appointment: null | AppointmentModel;
  isOpen: boolean;
  onClose(): void;
}

export const MissAppointmentModal: FC<MissAppointmentModalProps> = (props) => {
  const { appointmentRequest, appointment, isOpen, onClose } = props;

  const toast = useToast();
  const [missAppointment] = useMutation<MutationData>(MISS_APPOINTMENT);

  if (appointment === null) {
    return null;
  } else {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="_MissAppointmentModal"
      >
        <ModalHeader
          icon={["far", "calendar-times"]}
          title="Mark Appointment as Missed"
          onClose={onClose}
          color="red"
        />
        <div style={{ padding: "1rem 2rem" }}>
          <BigAppointmentDetails
            appointmentRequest={appointmentRequest}
            appointment={appointment}
          />
        </div>
        <div style={{ padding: "1rem" }}>
          <Formik
            initialValues={{
              missedReason: "",
              requiresReschedule: appointment.requiresReschedule,
            }}
            validationSchema={Yup.object().shape({
              missedReason: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              missAppointment({
                variables: {
                  id: appointment.id,
                  appointment: values,
                },
                refetchQueries: [
                  {
                    query: APPOINTMENT_REQUEST_SHOW_QUERY,
                    variables: {
                      id: appointment.appointmentRequestId,
                    },
                  },
                ],
              }).then((resp) => {
                if (resp?.data?.missAppointment.errors) {
                  setStatus({
                    errors: resp.data.missAppointment.errors,
                  });
                } else if (resp?.data?.missAppointment.appointment) {
                  // it worked...
                  toast.success("Marked Appointment as Missed");
                  onClose();
                }
                setSubmitting(false);
              });
            }}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <HorizontalTextAreaField
                  name="missedReason"
                  label="Reason"
                  placeholder="Missed Reason"
                  minHeight={80}
                />

                <HorizontalSingleCheckboxField
                  name="requiresReschedule"
                  checkboxLabel={<>Reschedule Required</>}
                  label=""
                />

                <div className="mx-auto mt-6 text-right">
                  <Button
                    type="submit"
                    kind="primary"
                    color="red"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Mark Appointment as Missed
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
};
