export function translateLOR(
  levelOfReviewLabel: string | undefined,
  insurancePlanCode: string | null | undefined,
  state: string | undefined
) {
  if (
    (state === "KY" || state === "Kentucky") &&
    insurancePlanCode === "MEDICAID" &&
    levelOfReviewLabel?.toLowerCase().includes("reconsideration")
  ) {
    if (levelOfReviewLabel === "Reconsideration P2P") {
      return "Post Review Consult";
    } else {
      return levelOfReviewLabel.replace(
        "Reconsideration",
        "Post Review Consult"
      );
    }
  }
  return levelOfReviewLabel;
}
