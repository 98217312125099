import { FC, useCallback, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { parseISO, lightFormat } from "date-fns";
import { FAIcon } from "components/FAIcon";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { ModalHeader, Modal } from "components/Modal";
import { makeAppendItems } from "lib/makeAppendItems";
import { EditProviderForm } from "./EditProviderForm";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuLink,
} from "components/Dropdown";
import {
  ProvidersFilterPanel,
  blankFilter,
  ProvidersFilterModel,
} from "./ProvidersFilterPanel";
import { Button } from "components/Button";
import { AddProviderModal } from "./AddProviderModal";
import { Spinner } from "components/Spinner";
import { Table, TH, TD, TableContainer } from "components/Table";

const PAGE_SIZE = 25;

export const ORGANIZATION_PROVIDERS = gql`
  query ListProviders(
    $first: Int
    $after: UUID4
    $filter: ListProvidersFilter
  ) {
    providers(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        email
        firstName
        lastName
        domainId
        imageoneId
        primarySpecialty
        stateLicensures {
          id
          abbreviation
        }
        specialties {
          id
          name
        }
        insertedAt
      }
    }
  }
`;

type Provider = {
  id: string;
  email?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  primarySpecialty?: string;
  stateLicensures: {
    id: string;
    abbreviation: string;
  }[];
  specialties: {
    id: string;
    name: string;
  }[];
  prefix?: string;
  suffix?: string;
  credentialText?: string;
  insertedAt: string;
};

interface ProvidersData {
  providers: {
    cursor?: string;
    endOfList: boolean;
    items: Provider[];
  };
}

/**
 * NoResults
 */

interface NoResultsProps {}

const NoResults: FC<NoResultsProps> = () => {
  return (
    <div className="_NoResults" style={{ padding: "2rem 0" }}>
      <div
        className="flex items-center justify-center mx-auto bg-blue-100 rounded-full"
        style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
      >
        <FAIcon icon="search" />
      </div>
      <div className="text-center" style={{ marginTop: "0.75rem" }}>
        <h3 className="text-lg font-medium leading-6 text-blue-900">
          No Results
        </h3>
      </div>
    </div>
  );
};

/**
 * Providers
 */

const updateQuery = makeAppendItems<ProvidersData>("providers");

interface ProvidersProps {}

type ActiveModal =
  | { type: "ADD_PROVIDER" }
  | { type: "EDIT_PROVIDER"; providerId: string };

export const Providers: FC<ProvidersProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), [setActiveModal]);

  const editProvider = useCallback(
    (providerId: string) =>
      setActiveModal({ type: "EDIT_PROVIDER", providerId }),
    [setActiveModal]
  );

  const addProvider = useCallback(
    () => setActiveModal({ type: "ADD_PROVIDER" }),
    [setActiveModal]
  );

  const [filter, setFilter] = useState<ProvidersFilterModel>(blankFilter);

  const { data, loading, error, fetchMore } = useQuery<ProvidersData>(
    ORGANIZATION_PROVIDERS,
    { variables: { first: PAGE_SIZE, filter } }
  );

  return (
    <>
      <NavTitle title="Settings » Providers" />

      <AddProviderModal
        isOpen={activeModal?.type === "ADD_PROVIDER"}
        onClose={closeModal}
      />
      {activeModal?.type === "EDIT_PROVIDER" ? (
        <Modal isOpen onRequestClose={closeModal}>
          <ModalHeader
            icon="user-md"
            title="Edit Provider"
            onClose={closeModal}
          />
          <EditProviderForm
            id={activeModal.providerId}
            onSuccess={closeModal}
            refetchQueries={[
              {
                query: ORGANIZATION_PROVIDERS,
                variables: { first: PAGE_SIZE },
              },
            ]}
          />
        </Modal>
      ) : null}

      <div className="bg-white rounded-lg shadow-lg">
        <GradientHeader
          title="Providers"
          subtitle="Manage your organization's providers"
        />
        <div style={{ padding: "1rem" }}>
          <div className="flex items-end justify-between">
            <div className="flex-grow">
              <ProvidersFilterPanel value={filter} onChange={setFilter} />
            </div>
            <Button
              type="button"
              kind="primary"
              color="gold"
              onClick={addProvider}
            >
              <span className="mr-2">
                <FAIcon icon="user-plus" />
              </span>
              Add Provider
            </Button>
          </div>
          {loading ? (
            <div className="p-6">
              <Spinner />
            </div>
          ) : error || !data ? (
            <div style={{ padding: "1rem" }}>
              <h1>Error Loading</h1>
            </div>
          ) : data.providers.items.length === 0 ? (
            <NoResults />
          ) : (
            <div className="pt-6">
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH>Specialties</TH>
                      <TH>State Licensures</TH>
                      <TH>Created On</TH>
                      <TH />
                    </tr>
                  </thead>
                  <tbody>
                    {data.providers.items.map((provider: Provider) => (
                      <tr key={provider.id}>
                        <TD>
                          <p className="font-semibold">
                            {provider.firstName} {provider.lastName}
                          </p>
                          <p className="text-xs">{provider.email}</p>
                        </TD>
                        <TD>
                          {provider.specialties?.length > 0 ? (
                            <p className="text-xs">
                              {provider.specialties
                                .map((spec) => spec.name)
                                .join(", ")}
                            </p>
                          ) : null}
                        </TD>
                        <TD>
                          <p>
                            {provider.stateLicensures
                              .map((state) => state.abbreviation)
                              .join(", ")}
                          </p>
                        </TD>
                        <TD>
                          {lightFormat(parseISO(provider.insertedAt), "M/d/yy")}
                        </TD>
                        <TD>
                          <Dropdown>
                            <OptionsButton label="Actions" />
                            <DropdownMenu>
                              <DropdownMenuButton
                                onClick={() => editProvider(provider.id)}
                              >
                                Edit Provider
                              </DropdownMenuButton>
                              <DropdownMenuLink
                                to={`/o/settings/providers/${provider.id}/calendar`}
                              >
                                View Calendar
                              </DropdownMenuLink>
                            </DropdownMenu>
                          </Dropdown>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="p-3 text-center border-t">
                  {!data.providers.endOfList ? (
                    <Button
                      type="button"
                      kind="tertiary"
                      color="blue"
                      size="sm"
                      className="border"
                      onClick={() =>
                        fetchMore({
                          query: ORGANIZATION_PROVIDERS,
                          variables: {
                            first: PAGE_SIZE,
                            after: data.providers.cursor,
                            filter,
                          },
                          updateQuery,
                        })
                      }
                    >
                      Load More
                    </Button>
                  ) : (
                    <p className="text-gray-500"> End of List</p>
                  )}
                </div>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
