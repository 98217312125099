import { FC } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavMenu } from "./NavMenu";
import { LinkType } from "./NavMenu/NavMenu";
import { ProfileForm as UserProfileForm } from "./User/ProfileForm";
import { AccountForm as UserAccountForm } from "./User/AccountForm";
import { NotificationPreferences as UserNotificationPreferences } from "./User/NotificationPreferences";
import { ProfileForm as OrganizationProfileForm } from "./Organization/ProfileForm";
import { OrganizationUsers } from "./Organization/OrganizationUsers";
import { OrganizationUserShow } from "./Organization/OrganizationUserShow";
import { Providers } from "./Organization/Providers";
import { Skills } from "./Organization/Skills";
import { CasePrograms } from "./Organization/CasePrograms";
import { HealthPlans } from "./Organization/HealthPlans";
import { Specialties } from "./Organization/Specialties";
import { Tags } from "./Organization/Tags";
import { ProviderShow } from "./Organization/ProviderShow";

const MY_ROLE = gql`
  query MyRoleForSettingsAccessQuery {
    me {
      id
      role
      isSsoUser
      organization {
        id
        isHost
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    role: string;
    isSsoUser: boolean;
    organization: {
      id: string;
      isHost: boolean;
    };
  };
}

const userSettingsLinks: LinkType[] = [
  {
    icon: "user-edit",
    path: "/o/settings/profile",
    label: "Profile",
  },
  {
    icon: "lock",
    path: "/o/settings/account",
    label: "Account",
  },
  {
    icon: "bell",
    path: "/o/settings/notifications",
    label: "Notifications",
  },
];

const internalAdminOrgSettingsLinks: LinkType[] = [
  {
    icon: "hospital-alt",
    path: "/o/settings/organization_profile",
    label: "Profile",
  },
  {
    icon: "users",
    path: "/o/settings/users",
    label: "Users",
  },
];

const externalAdminOrgSettingsLinks: LinkType[] = [
  {
    icon: "user-md",
    path: "/o/settings/providers",
    label: "Providers",
  },
  {
    icon: "stethoscope",
    path: "/o/settings/case_programs",
    label: "Case Programs",
  },
  {
    icon: "stethoscope",
    path: "/o/settings/skills",
    label: "Skills",
  },
  {
    icon: "stethoscope",
    path: "/o/settings/specialties",
    label: "Specialties",
  },
  {
    icon: "stethoscope",
    path: "/o/settings/health_plans",
    label: "Health Plans",
  },
  {
    icon: "tags",
    path: "/o/settings/tags",
    label: "Tags",
  },
];

interface SettingsPageProps {}

export const SettingsPage: FC<SettingsPageProps> = () => {
  const { loading, data, error } = useQuery<Data>(MY_ROLE);
  return (
    <div className="_SettingsPage max-w-7xl lg:py-12 lg:px-8 pb-10 mx-auto text-left">
      {loading ? (
        <h1>Loading...</h1>
      ) : error || !data ? (
        <div style={{ padding: "1rem" }}>
          <h1>Error Loading</h1>
        </div>
      ) : (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="sm:px-6 lg:py-0 lg:px-0 lg:col-span-3 px-2 py-6">
            {/* style={{ maxWidth: 336, margin: "0 1.5rem", marginBottom: 40 }} */}
            {/* <SideNav title="User Settings" /> */}
            <NavMenu title="User Settings" links={userSettingsLinks} />
            {data.me.role === "admin" ? (
              <>
                <NavMenu
                  title="Organization Settings"
                  links={internalAdminOrgSettingsLinks}
                />
                {data.me.organization.isHost ? (
                  <NavMenu
                    title="System Settings"
                    links={externalAdminOrgSettingsLinks}
                  />
                ) : null}
              </>
            ) : null}
          </aside>

          <div className="sm:px-6 lg:px-0 lg:col-span-9 space-y-6">
            <Switch>
              <Route exact path="/o/settings">
                <Redirect to="/o/settings/profile" />
              </Route>
              <Route path="/o/settings/profile">
                <UserProfileForm />
              </Route>
              <Route path="/o/settings/account">
                <UserAccountForm />
              </Route>

              <Route path="/o/settings/notifications">
                <UserNotificationPreferences />
              </Route>
            </Switch>

            {data.me.role === "admin" ? (
              <Switch>
                <Route path="/o/settings/organization_profile">
                  <OrganizationProfileForm />
                </Route>

                <Route exact path="/o/settings/users">
                  <OrganizationUsers />
                </Route>

                <Route path="/o/settings/users/:userId">
                  <OrganizationUserShow />
                </Route>
              </Switch>
            ) : null}

            {data.me.role === "admin" && data.me.organization.isHost ? (
              <Switch>
                <Route exact path="/o/settings/providers">
                  <Providers />
                </Route>

                <Route path="/o/settings/providers/:providerId">
                  <ProviderShow />
                </Route>

                <Route exact path="/o/settings/case_programs">
                  <CasePrograms />
                </Route>

                <Route exact path="/o/settings/skills">
                  <Skills />
                </Route>

                <Route exact path="/o/settings/specialties">
                  <Specialties />
                </Route>

                <Route exact path="/o/settings/health_plans">
                  <HealthPlans />
                </Route>

                <Route exact path="/o/settings/tags">
                  <Tags />
                </Route>
              </Switch>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
