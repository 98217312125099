/**
 * Regex for "MM/DD/YYYY":
 */
export const localDateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

/**
 * Pre-condition: `localDateString` is a string formatted like "MM/DD/YYYY";
 */
export function localDateToISO(localDateString: string): string {
  const [mm, dd, yyyy] = localDateString.split("/");
  return `${yyyy}-${mm}-${dd}`;
}
