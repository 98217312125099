import React, { FC } from "react";

interface BorderLabelProps {
  show: boolean;
  label: string;
  className?: string;
}

export const BorderLabel: FC<BorderLabelProps> = (props) => {
  const { show, label, className = "", children } = props;
  if (!show) return <>{children}</>;

  return (
    <div
      className={`_BorderLabel border border-blue-700 rounded-lg ${className}`}
      style={{
        marginTop: "1rem",
        position: "relative",
        padding: "0.25rem",
        borderTopLeftRadius: 0,
      }}
    >
      <span
        className="block text-xs text-white bg-blue-700"
        style={{
          position: "absolute",
          top: 0,
          left: -1,
          padding: "0.25rem",
          transform: "translateY(-100%)",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          margin: 0,
        }}
      >
        {label}
      </span>
      {children}
    </div>
  );
};
