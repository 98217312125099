import React, { FC } from "react";
import { ErrorMessage, useField } from 'formik';
import { ColorSwatchPicker, ColorSwatchPickerProps } from 'components/ColorSwatchPicker';
import { HorizontalField, VerticalField } from '../FieldStructure'

export interface ColorSwatchInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  inputProps?: Partial<ColorSwatchPickerProps>;
  disabled?: boolean;
};

export const ColorSwatchInput: FC<ColorSwatchInputProps> = props => {
  const {
    id,
    name,
    autoFocus = false,
    className = '',
    inputProps = {},
    disabled,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <ColorSwatchPicker
        inputProps={{
          id: id || name,
          name,
          autoFocus,
          ...inputProps,
        }}
        className={
          meta && meta.touched && meta.error
            ? `border border-red-500 ${className}`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        disabled={disabled}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

export interface ColorSwatchFieldProps extends ColorSwatchInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const ColorSwatchField: FC<ColorSwatchFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ColorSwatchInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalColorSwatchField: FC<ColorSwatchFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ColorSwatchInput {...rest} />
    </HorizontalField>
  );
};
