import React, { FC, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { GradientHeader } from "../../GradientHeader";
import { ToggleSwitch } from "components/ToggleSwitch";
import { useToast } from "layouts/PortalLayout/Toast";
import gql from "graphql-tag";

const CURRENT_USER_NOTIFICATION_PREFERENCES = gql`
  query MyNotificationPreferencesQuery {
    communicationTypes {
      medium
      notification
      label
      description
      defaultValue
    }
    me {
      id
      communicationPreferences {
        medium
        notification
        value
      }
    }
  }
`;

interface Data {
  communicationTypes: CommunicationType[];
  me: {
    id: string;
    communicationPreferences: CommunicationPreference[];
  };
}

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation UpdateUserNotificationPreference(
    $medium: String!
    $notification: String!
    $value: Boolean!
  ) {
    updateCurrentUserCommunicationPreference(
      medium: $medium
      notification: $notification
      value: $value
    )
  }
`;

interface MutationData {
  updateCurrentUserCommunicationPreference: boolean;
}

interface CommunicationType {
  medium: string;
  notification: string;
  label: string;
  description: string;
  defaultValue: boolean;
}

interface CommunicationPreference {
  medium: string;
  notification: string;
  value: boolean;
}

type MergedPreference = CommunicationType & CommunicationPreference;

function isMatchingPreference(
  communicationType: CommunicationType,
  preference: CommunicationPreference
): boolean {
  return (
    communicationType.medium === preference.medium &&
    communicationType.notification === preference.notification
  );
}

function preferredValue(
  communicationType: CommunicationType,
  preferences: CommunicationPreference[]
): boolean {
  const preference = preferences.find((pref) =>
    isMatchingPreference(communicationType, pref)
  );
  return preference ? preference.value : communicationType.defaultValue;
}

function mergeDefaultsAndPreferences(
  communicationTypes: CommunicationType[],
  preferences: CommunicationPreference[]
): MergedPreference[] {
  return communicationTypes.map((commType) => ({
    ...commType,
    value: preferredValue(commType, preferences),
  }));
}

/**
 * PreferenceToggle.
 */

interface PreferenceToggleProps {
  communicationPreference: CommunicationPreference;
  onUpdate(): void;
}

const PreferenceToggle: FC<PreferenceToggleProps> = (props) => {
  const { communicationPreference, onUpdate } = props;

  const [updatePreference, { loading }] = useMutation<MutationData>(
    UPDATE_NOTIFICATION_PREFERENCE
  );

  const handleClick = useCallback(() => {
    const newPref = {
      medium: communicationPreference.medium,
      notification: communicationPreference.notification,
      value: !communicationPreference.value,
    };
    return updatePreference({
      variables: newPref,
      refetchQueries: [{ query: CURRENT_USER_NOTIFICATION_PREFERENCES }],
    }).then((res) => {
      onUpdate();
    });
  }, [communicationPreference, onUpdate, updatePreference]);

  return (
    <ToggleSwitch
      id={`PreferenceToggle-${communicationPreference.medium}-${communicationPreference.notification}`}
      checked={communicationPreference.value}
      onChange={handleClick}
      loading={loading}
      showLabel
    />
  );
};

/**
 * NotificationPreference.
 */

type NotificationPreferencesProps = {};

export const NotificationPreferences: FC<NotificationPreferencesProps> = () => {
  const toast = useToast();
  const { data, loading, error } = useQuery<Data>(
    CURRENT_USER_NOTIFICATION_PREFERENCES
  );

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Notifications" />
      <GradientHeader
        title="Notification Preferences"
        subtitle="Configure which notifications you'd like to receive."
      />
      <div style={{ padding: "2rem" }}>
        {loading ? (
          <p>Loading...</p>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : data.communicationTypes.length === 0 ? (
          <div>
            <h1>No Communication Preferences</h1>
          </div>
        ) : (
          <>
            {groupByMedium(
              mergeDefaultsAndPreferences(
                data.communicationTypes,
                data.me.communicationPreferences
              )
            ).map((medium) => (
              <div key={medium.medium} style={{ marginBottom: "2rem" }}>
                <h3 className="subtitle is-5 capitalize">{medium.medium}</h3>
                {medium.items.map((item) => (
                  <form key={`${item.medium}-${item.notification}`}>
                    {/* TODO: extract this into <WideField> component */}
                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{
                          flexGrow: 5,
                          textAlign: "left",
                          marginLeft: "1.5em",
                        }}
                      >
                        <label className="block pt-1 pb-3 text-base font-semibold">
                          {item.label}
                        </label>
                        <p className="help">{item.description}</p>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            {/* TODO: Make layers in PreferenceToggle more reusable. */}
                            <PreferenceToggle
                              communicationPreference={item}
                              onUpdate={() =>
                                toast.success(
                                  "Updated communication preference."
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

/**
 * Then use it to render the form below
 * Then make a data model for user communicationPreferences
 * Then make a mutation endpoint to update user communicationPreferences
 * Then make the toggleSwitches send the request to update the values
 * Then write tests
 * Then wire up the form and make sure it works
 * Then implement User.wants_notifications_for() or something
 */

// TODO: Finish this function. Return something like this:
/**
 * [
 *  {
 *    medium: "Email",
 *    items: []
 *  },
 *  {
 *    medium: "Text Message",
 *    items: []
 *  }
 * ]
 */
function groupByMedium(preferences: MergedPreference[]) {
  const obj: { [x: string]: MergedPreference[] } = preferences.reduce(
    (acc: { [x: string]: MergedPreference[] }, elem) => {
      if (acc[elem.medium]) {
        acc[elem.medium].push(elem);
      } else {
        acc[elem.medium] = [elem];
      }
      return acc;
    },
    {}
  );
  return Object.keys(obj).map((key) => ({ medium: key, items: obj[key] }));
}
