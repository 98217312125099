import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { DEFAULT_AFTER_SIGN_IN_PATH } from "config/constants";
import { useAuth } from "../AuthContext";

// TODO: Passing this a component doesn't actually work right now...
// getting some error like: "You should not use <Route component> and <Route render> in the same route; <Route render> will be ignored"

const AuthRoute: React.SFC<RouteProps> = props => {
  const { token } = useAuth();
  const location = useLocation();

  if (!!token) {
    return <Redirect to={afterSignInPath(location)} />;
  }

  return <Route {...props} />;
};

export function afterSignInPath(location: any) {
  const from = location.state ? location.state.from : null;
  if (from) {
    return makePushPath(from);
  } else {
    return DEFAULT_AFTER_SIGN_IN_PATH;
  }
}

function makePushPath(location: {
  pathname: string;
  hash?: string;
  search?: string;
}) {
  const { pathname } = location;
  const hash = location.hash || "";
  const search = location.search || "";
  return `${pathname}${hash}${search}`;
}

export { AuthRoute };
