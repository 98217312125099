import React, { FC } from "react";
import { ErrorMessage, Field } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";

type CheckboxOption = {
  value: string;
  label: string | React.ReactNode;
};

interface CheckboxInputProps {
  id?: string;
  name: string;
  option: CheckboxOption;
  showIndividualError?: boolean;
}

const CheckboxInput: FC<CheckboxInputProps> = (props) => {
  const { id, name, option, showIndividualError = true } = props;
  return (
    <>
      <label className="checkbox flex items-center mb-1">
        <Field
          type="checkbox"
          id={`${id || name}--${option.value}`}
          name={name}
          value={option.value}
          style={{ marginRight: "0.5rem" }}
          className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
        />
        {option.label}
      </label>
      {showIndividualError ? (
        <ErrorMessage
          component="p"
          name={name}
          className="mt-2 text-xs italic text-red-500"
        />
      ) : null}
    </>
  );
};

interface CheckboxesInputProps {
  id?: string;
  name: string;
  options: CheckboxOption[];
  labeledBy?: string;
}

export const CheckboxesInput: FC<CheckboxesInputProps> = (props) => {
  const { id, name, options, labeledBy } = props;

  return (
    <>
      <div role="group" className="pl-2" aria-labelledby={labeledBy}>
        {options.map((option) => (
          <CheckboxInput
            key={option.value}
            id={id}
            name={name}
            option={option}
            showIndividualError={false}
          />
        ))}
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * CheckboxesField.
 */

interface CheckboxesFieldProps extends CheckboxesInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const CheckboxesField: FC<CheckboxesFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;
  const labelId = `field-label--${rest.id || rest.name}`;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      labelId={labelId}
      indicateOptional={indicateOptional}
    >
      <CheckboxesInput {...rest} labeledBy={labelId} />
    </VerticalField>
  );
};

export const HorizontalCheckboxesField: FC<CheckboxesFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;
  const labelId = `field-label--${rest.id || rest.name}`;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      labelId={labelId}
      indicateOptional={indicateOptional}
    >
      <CheckboxesInput {...rest} labeledBy={labelId} />
    </HorizontalField>
  );
};
