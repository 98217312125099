import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DropdownMenu,
  DropdownTrigger,
  DropdownContent
} from "components/DropdownMenu";
import defaultProfileImagePath from "./assets/default.avatar.png";
import "./UserDropdown.css";

export const CURRENT_USER = gql`
  query CurrentUserForUserDropdown {
    me {
      id
      firstName
      lastName
      email
      confirmedAt
    }
  }
`;

interface Data {
  me: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    confirmedAt?: string;
  };
}

type UserDropdownProps = {
  signOut: () => void;
};

const UserDropdown: React.SFC<UserDropdownProps> = ({ signOut }) => (
  <div className="UserDropdown">
    <Query<Data> query={CURRENT_USER}>
      {({ loading, error, data }) =>
        loading ? (
          <span>Loading</span>
        ) : (
          <DropdownMenu>
            <DropdownTrigger>
              <div className="UserDropdown__trigger">
                <img src={defaultProfileImagePath} alt="profile" />
                <span>
                  {data &&
                    data.me &&
                    `${data.me.firstName} ${data.me.lastName}`}{" "}
                  <FontAwesomeIcon icon="angle-down" />
                </span>
              </div>
            </DropdownTrigger>
            <DropdownContent>
              <div>
                <ul>
                  {/* <li>
                    <Link to="/o/user_settings">
                      Personal Settings
                    </Link>
                  </li> */}
                  <li>
                    {/* eslint-disable-next-line */}
                    <span id="logout" onClick={signOut}>
                      Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </DropdownContent>
          </DropdownMenu>
        )
      }
    </Query>
  </div>
);

UserDropdown.propTypes = {
  signOut: PropTypes.func.isRequired
};

export { UserDropdown };
