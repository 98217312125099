import React from "react";
import { components, OptionProps } from "react-select";
import { SelectInput } from "components/formik/SelectField";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { Button } from "components/Button";
import { TextInput } from "components/formik/TextField";
import { TagModel } from "components/TagCombobox";

/**
 * TagOption.
 */

const TagOption: React.FC<OptionProps<TagModel, true>> = React.memo((props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center font-semibold">
        <div
          className="w-5 h-5 mr-2 rounded-full shadow-inner"
          style={{ backgroundColor: props.data.color }}
        />
        <p className="flex-grow">{props.data.label}</p>
      </div>
    </components.Option>
  );
});

export interface Filter extends Record<string, any> {
  team: "PAC" | "PSU" | "ALL";
  status: "open" | "closed" | "ALL";
  tagIds: string[];
  caseReferenceNumber?: string;
}

const teamOptions = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "PAC",
    label: "PAC",
  },
  {
    value: "PSU",
    label: "PSU",
  },
];

const statusOptions = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

const defaultFilter: Filter = {
  team: "ALL",
  status: "open",
  caseReferenceNumber: "",
  tagIds: [],
};

/**
 * FilterPanel.
 */

interface FilterPanelProps {
  value: Filter;
  onChange(value: Filter): void;
  isLoading?: boolean;
  dataLoading?: boolean;
  filterData: {
    tags: void | TagModel[];
  };
}

export const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const {
    value,
    onChange,
    isLoading = false,
    filterData,
    dataLoading = false,
  } = props;

  return (
    <FilterForm<Filter>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values: Filter) => onChange(removeVoidKeys(values as any))}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="team" icon="filter" label="Team">
          <div className="w-48">
            <SelectInput name="team" options={teamOptions} placeholder="Any" />
          </div>
        </FilterField>

        <FilterField htmlFor="status" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput
              name="status"
              options={statusOptions}
              placeholder="Any"
            />
          </div>
        </FilterField>

        <FilterField htmlFor="tagIds" icon="tags" label="Tags">
          <div className="w-56">
            <SelectInput<TagModel>
              name="tagIds"
              options={filterData.tags || []}
              getOptionLabel={(o) => o.label}
              getOptionValue={(o) => o.id}
              placeholder="Any"
              isLoading={dataLoading}
              components={{ Option: TagOption }}
              isClearable
              isMulti
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="caseReferenceNumber"
          icon="search"
          label="Case Ref #"
        >
          <div className="w-48">
            <TextInput
              name="caseReferenceNumber"
              placeholder="Case Ref #"
              icon="search"
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function removeVoidKeys(filterWithNulls: Record<keyof Filter, any>): Filter {
  let filter: Partial<Filter> = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter as Filter;
}
