import { FC } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Modal, ModalHeader } from "components/Modal";
import { EditCaseProgramForm } from "./EditCaseProgramForm";
import { EditModalitiesForm } from "./EditModalitiesForm";
import { EditSkillPrioritizations } from "./EditSkillPrioritizations";
import "./EditCaseProgramModal.css";
import { Spinner } from "components/Spinner";

export const FETCH_CASE_PROGRAM = gql`
  query GetCaseProgram($id: UUID4!) {
    caseProgram(id: $id) {
      id
      name
      modalities {
        id
        name
      }
      caseProgramSkillPrioritizations {
        id
        priority
        skill {
          id
          name
          externalSystemName
        }
      }
    }
  }
`;

interface Data {
  caseProgram: CaseProgram;
}

export type Modality = {
  id: string;
  name: string;
};

export type Skill = {
  id: string;
  name: string;
  externalSystemName: string;
};

export type CaseProgramSkillPrioritization = {
  id: string;
  priority: number;
  skill: Skill;
};

export type CaseProgram = {
  id: string;
  name: string;
  modalities: Modality[];
  caseProgramSkillPrioritizations: CaseProgramSkillPrioritization[];
};

interface EditCaseProgramModalProps {
  id: string;
  isOpen: boolean;
  onClose(): void;
}

export const EditCaseProgramModal: FC<EditCaseProgramModalProps> = (props) => {
  const { id, isOpen, onClose } = props;

  const { data, loading, error } = useQuery<Data>(FETCH_CASE_PROGRAM, {
    variables: { id },
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="pencil-alt"
        title="Edit Case Program"
        onClose={onClose}
      />
      <div className="p-4">
        {loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : (
          <div>
            <div className="EditCaseProgramModal__section">
              <h3>Case Program Details</h3>
              <article>
                <EditCaseProgramForm
                  caseProgram={data.caseProgram}
                  onClose={onClose}
                />
              </article>
            </div>
            <div className="EditCaseProgramModal__section mt-8">
              <h3>Modalities</h3>
              <article>
                <EditModalitiesForm caseProgram={data.caseProgram} />
              </article>
            </div>
            <div className="EditCaseProgramModal__section mt-8">
              <h3>Skill Prioritizations</h3>
              <article>
                <EditSkillPrioritizations caseProgram={data.caseProgram} />
              </article>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
