import React, { FC } from "react";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { AuthConsumer } from "components/AuthContext";
import { coalesceString } from "lib/coalesce";
import evicoreLogoSrc from "images/eviCore_big_logo.png";

export const ResetPasswordPage: FC = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);
  return (
    <div>
      <img src={evicoreLogoSrc} alt="logo" width="200" />
      <div>
        {id && token ? (
          <AuthConsumer>
            {({ signIn }) => (
              <ResetPasswordForm
                id={coalesceString(id)}
                token={coalesceString(token)}
                onSignIn={signIn}
              />
            )}
          </AuthConsumer>
        ) : (
          <div>
            <h1 className="is-size-2">Invalid Password Reset URL</h1>
            <p>
              <Link to="/auth/forgot_password">Click Here</Link> to request a
              new password reset link
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
