import React, { useContext } from "react";
import tokenStore from "lib/tokenStore";
import { AuthPubSub, AuthEvents } from "./AuthPubSub";
import { expandJWT } from "lib/auth";

interface AuthContextState {
  token: Token | null;
  forgetToken: () => void;
  signOut: () => void;
  signIn: (jwt: string) => void;
  saveToken: (jwt: string) => void;
}

const AuthContext = React.createContext<AuthContextState>({
  token: null,
  forgetToken: () => null,
  signOut: () => null,
  signIn: () => null,
  saveToken: () => null
});

interface AuthProviderProps {
  bus: AuthPubSub;
  onSignOut?: () => void;
  onSignIn?: () => void;
}

class AuthProvider extends React.Component<
  AuthProviderProps,
  AuthContextState
> {
  unauthdSub: null | string = null;

  constructor(props: AuthProviderProps) {
    super(props);
    this.state = {
      token: tokenStore.getStoredToken(),
      forgetToken: () => this.forgetToken(),
      signOut: () => this.signOut(),
      signIn: (jwt: string) => this.signIn(jwt),
      saveToken: (jwt: string) => this.saveToken(jwt)
    };
    this.unauthdSub = this.props.bus.syncSubscribe(
      AuthEvents.Unauthenticated,
      this.forgetToken
    );
  }

  componentWillUnmount() {
    if (this.unauthdSub) {
      const { bus } = this.props;
      bus.unsubscribe(this.unauthdSub);
    }
  }

  forgetToken = () => {
    this.setState({ token: null });
    tokenStore.forgetToken();
  };

  signOut = () => {
    const { onSignOut } = this.props;
    if (onSignOut) {
      onSignOut();
    }
    this.forgetToken();
  };

  signIn = (jwt: string) => {
    this.saveToken(jwt);
    const { onSignIn } = this.props;
    if (onSignIn) {
      onSignIn();
    }
  };

  saveToken = (jwt: string) => {
    const token = expandJWT(jwt);
    tokenStore.storeToken(token);
    this.setState({ token });
  };

  render() {
    const { children } = this.props;

    return (
      <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

interface InjectedAuthProps {
  isAuthenticated: boolean;
}

interface AuthProps {
  children: (props: InjectedAuthProps) => JSX.Element;
}

class Auth extends React.Component<AuthProps> {
  render() {
    const { children } = this.props;
    return (
      <AuthConsumer>
        {({ token }) => children({ isAuthenticated: !!token })}
      </AuthConsumer>
    );
  }
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}

export { Auth, AuthProvider, AuthConsumer };
