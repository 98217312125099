import React, { FC } from "react";
import cx from "classnames";
import { FAIcon } from "components/FAIcon";
import {
  AppointmentModel,
  AppointmentRequestModel,
} from "../../../AppointmentRequestShowPage";
import { AppointmentStatus, getAppointmentStatus } from "../../Appointments";
import { CopyText } from "components/CopyText";
import {
  infoTextForAppointment,
  contactPhoneFor,
  alternatePhoneFor,
} from "./infoTextForAppointment";
import "./BigAppointmentDetails.css";

interface BigAppointmentDetailsProps {
  appointmentRequest: AppointmentRequestModel;
  appointment: AppointmentModel;
}

export const BigAppointmentDetails: FC<BigAppointmentDetailsProps> = (
  props
) => {
  const { appointmentRequest, appointment } = props;
  const status = getAppointmentStatus(appointment);
  return (
    <div
      className={cx("BigAppointmentDetails", {
        "BigAppointmentDetails--scheduled":
          status === AppointmentStatus.Scheduled,
        "BigAppointmentDetails--completed":
          status === AppointmentStatus.Completed,
        "BigAppointmentDetails--cancelled":
          status === AppointmentStatus.Cancelled ||
          status === AppointmentStatus.Missed,
      })}
    >
      <div className="BigAppointmentDetails__copy-container">
        <CopyText
          text={infoTextForAppointment(appointmentRequest, appointment)}
          label="Click to copy"
        />
      </div>
      <label className="block pt-1 pb-3 text-base font-semibold">
        Appointment Details:
      </label>
      <div style={{ marginLeft: "0.5rem" }}>
        <div>
          <span className="BigAppointmentDetails__icon-container BigAppointmentDetails__status-icon-container">
            <FAIcon icon="info-circle" />
          </span>
          <span className="BigAppointmentDetails__status">
            {getStatusText(getAppointmentStatus(appointment))}
          </span>
        </div>
        {appointment.requiresReschedule ? (
          <div className="BigAppointmentDetails__warning">
            <span className="BigAppointmentDetails__icon-container BigAppointmentDetails__status-icon-container">
              <FAIcon icon="exclamation-triangle" />
            </span>
            <span className="BigAppointmentDetails__warning__message">
              Reschedule Required
            </span>
          </div>
        ) : null}
        <div>
          <span className="BigAppointmentDetails__icon-container">
            <FAIcon icon={["far", "calendar-alt"]} />
          </span>
          <span>{appointment.startDateString}</span>
        </div>
        <div>
          <span className="BigAppointmentDetails__icon-container">
            <FAIcon icon={["far", "clock"]} />
          </span>
          {/* <span>{format(start, "h:mm a")}</span> */}
          <span>{appointment.startTimeString}</span>
        </div>
        <div>
          <span className="BigAppointmentDetails__icon-container">
            <FAIcon icon="user-md" />
          </span>
          <span>{appointment.receivingProvider.nameWithAppellation}</span>
        </div>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <label className="block pt-1 pb-3 text-base font-semibold">
          P2P Contact Info
        </label>
        <div style={{ marginLeft: "0.5rem" }}>
          <div>
            <span className="BigAppointmentDetails__icon-container">
              <FAIcon icon="user-md" />
            </span>
            <span>{appointmentRequest.requestingProviderName}</span>
          </div>
          <div>
            <span className="BigAppointmentDetails__icon-container">
              <FAIcon icon="user" />
            </span>
            <span>{appointmentRequest.nameOfContactPerson}</span>
          </div>
          <div>
            <span className="BigAppointmentDetails__icon-container">
              <FAIcon icon="phone" />
            </span>
            <span>{contactPhoneFor(appointmentRequest)}</span>
          </div>
          {appointmentRequest.alternateContactPhoneNumber ? (
            <div>
              <span className="BigAppointmentDetails__icon-container">
                <FAIcon icon="phone" />
              </span>
              <span>{alternatePhoneFor(appointmentRequest)} (alternate)</span>
            </div>
          ) : null}
          {appointmentRequest.contactEmail ? (
            <div>
              <span className="BigAppointmentDetails__icon-container">
                <FAIcon icon="envelope" />
              </span>
              <span>{appointmentRequest.contactEmail}</span>
            </div>
          ) : null}
          {appointmentRequest.contactInstructions ? (
            <div>
              <span className="BigAppointmentDetails__icon-container">
                <FAIcon icon="clipboard-list" />
              </span>
              <span>{appointmentRequest.contactInstructions}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function getStatusText(status: AppointmentStatus) {
  switch (status) {
    case AppointmentStatus.Completed: {
      return "Completed";
    }
    case AppointmentStatus.Cancelled: {
      return "Cancelled";
    }
    case AppointmentStatus.Missed: {
      return "Missed";
    }
    case AppointmentStatus.Scheduled: {
      return "Scheduled";
    }
    default: {
      return "";
    }
  }
}
