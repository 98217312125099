export function firstSecondThird(num: number) {
  const tens = Math.floor(num) % 10;
  switch (tens) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
}
