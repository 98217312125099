import React, { FC, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { ApolloError } from "apollo-client";
import { FilterPanel } from "./FilterPanel";
import { Filter } from "./FilterPanel/FilterPanel";
import { WeekNav } from "./WeekNav";
import { SelectSkillMessage } from "./SelectSkillMessage";

const PATHWAY_SKILL_IDS_QUERY = gql`
  query AppointmentRequestPathwaySkillIds($appointmentRequestId: UUID4!) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        pathwaySkillIds
      }
    }
  }
`;

interface PathwaySkillData {
  appointmentRequest: {
    id: string;
    caseProfiles: {
      id: string;
      pathwaySkillIds: string[];
    }[];
  };
}

export const PROVIDER_AVAILABILITIES_QUERY = gql`
  query GetProviderAvailabilities(
    $appointmentRequestId: UUID4!
    $timeWindow: TimeRangeInput
    $searchWeekOffset: Int
    $timePreference: TimePreferenceInput
    $filter: AvailableProviderSlotsFilter
    $slotLength: Int
    $slotBuffer: Int
  ) {
    providerAvailabilities(
      appointmentRequestId: $appointmentRequestId
      timeWindow: $timeWindow
      searchWeekOffset: $searchWeekOffset
      timePreference: $timePreference
      filter: $filter
      slotLength: $slotLength
      slotBuffer: $slotBuffer
    ) {
      cursor
      endOfList
      items {
        provider {
          id
          nameWithAppellation
          firstName
          lastName
          primarySpecialty
          priorityBySkill
          skills {
            id
            name
          }
          specialties {
            id
            name
          }
        }
        dates {
          date
          slots {
            timeRange {
              start
              startTimeString(format: "{h12}:{m} {am} {Zabbr}")
              finish
              finishTimeString(format: "{h12}:{m} {am} {Zabbr}")
            }
          }
        }
      }
    }
  }
`;

interface Data {
  providerAvailabilities: ProviderResults;
}

export type Provider = {
  id: string;
  nameWithAppellation: string;
  firstName: string;
  lastName: string;
  primarySpecialty?: string;
  skills: {
    id: string;
    name: string;
  }[];
  specialties: {
    id: string;
    name: string;
  }[];
  priorityBySkill: number;
};

export type Slot = {
  timeRange: {
    start: string;
    startTimeString: string;
    finish: string;
    finishTimeString: string;
  };
};

export type ProviderResult = {
  provider: Provider;
  dates: {
    date: string;
    slots: Slot[];
  }[];
};

export type ProviderResults = {
  cursor?: number;
  endOfList: boolean;
  items: ProviderResult[];
};

type InjectedProviderResultsProps = {
  loading: boolean;
  error: ApolloError | undefined;
  results: ProviderResults | null;
};

type ProviderResultsFetcherProps = {
  appointmentRequestId: string;
  children: (props: InjectedProviderResultsProps) => JSX.Element | null;
};

type InnerProviderResultsFetcherProps = {
  pathwaySkillIds: string[];
} & ProviderResultsFetcherProps;

export const ProviderResultsFetcher: FC<ProviderResultsFetcherProps> = props => {
  const { appointmentRequestId, children } = props;

  const { data, loading, error } = useQuery<PathwaySkillData>(
    PATHWAY_SKILL_IDS_QUERY,
    { variables: { appointmentRequestId } }
  );

  return loading ? (
    <p>Loading...</p>
  ) : error || (!loading && (!data || !data.appointmentRequest)) ? (
    <p>Failed to load.</p>
  ) : !!data ? (
    <InnerProviderResultsFetcher
      appointmentRequestId={appointmentRequestId}
      pathwaySkillIds={data.appointmentRequest.caseProfiles[0].pathwaySkillIds}
    >
      {children}
    </InnerProviderResultsFetcher>
  ) : (
    <p>Failed to load.</p>
  );
};

const InnerProviderResultsFetcher: FC<InnerProviderResultsFetcherProps> = props => {
  const { appointmentRequestId, pathwaySkillIds, children } = props;

  const [filter, setFilter] = useState<Filter>({ skillIds: [] });
  const [searchWeekOffset, setSearchWeekOffset] = useState(0);

  const { loading, data, error } = useQuery<Data>(
    PROVIDER_AVAILABILITIES_QUERY,
    {
      variables: { appointmentRequestId, filter, searchWeekOffset },
      fetchPolicy: "network-only"
    }
  );

  return (
    <div>
      <FilterPanel
        appointmentRequestId={appointmentRequestId}
        pathwaySkillIds={pathwaySkillIds}
        value={filter}
        onChange={setFilter}
      />

      <WeekNav value={searchWeekOffset} onChange={setSearchWeekOffset} />

      {filter.skillIds && filter.skillIds.length === 0 ? (
        <SelectSkillMessage />
      ) : (
        children({
          loading,
          error,
          results: data ? data.providerAvailabilities : null
        })
      )}
    </div>
  );
};
