import React from "react";
import styled from "styled-components/macro";

const DetailListItem = styled.div`
  &:first-child {
    margin-top: 0;
  }
`;

/**
 * DetailItem.
 */

interface DetailItemProps {
  label: string;
  labelWidth?: number;
  style?: React.CSSProperties;
}

export const DetailItem: React.FC<DetailItemProps> = (props) => {
  const { label, labelWidth = 96, children, style = {} } = props;

  return (
    <DetailListItem className="_DetailItem sm:mt-3 sm:flex items-baseline mt-4 leading-loose">
      <div
        className="sm:text-right text-xs font-semibold text-gray-700"
        style={{ minWidth: labelWidth, ...style }}
      >
        {label}
      </div>
      <div className="sm:mt-0 sm:ml-4 flex-1 mt-2 text-lg leading-tight text-gray-900">
        {children}
      </div>
    </DetailListItem>
  );
};

/**
 * DetailList.
 */

interface DetailListProps extends React.HTMLAttributes<HTMLDivElement> {}

export const DetailList: React.FC<DetailListProps> = (props) => {
  const { className, ...rest } = props;
  return <div className={`DetailList ${className || ""}`} {...rest} />;
};
