import React, { Component } from "react";
import "./App.css";

import { ApolloProvider, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { createHttpLink } from "apollo-link-http";
import { onError, ErrorHandler, ErrorResponse } from "apollo-link-error";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
import { setContext } from "apollo-link-context";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import tokenStore from "./lib/tokenStore";
import {
  AuthProvider,
  AuthPubSub,
  AuthConsumer,
} from "./components/AuthContext";
import { SocketProvider } from "./components/SocketContext";
// import { PrivateRoute } from "./components/PrivateRoute";
import { PrivateRoute } from "./components/AuthRoutes";
import { NetworkStatusNotifier } from "./components/NetworkStatusNotifier";
import { AuthLayout } from "./layouts/AuthLayout";
import { PortalLayout } from "./layouts/PortalLayout";
import { ConfirmAccountPage } from "pages/ConfirmAccountPage";
import { SAMLRedirectPage } from "pages/SAMLRedirectPage";

const authPubSub = new AuthPubSub();

// Now setup Apollo stuff:
// Need to run `mix extract_fragment_types priv/ovicare-web/src/fragmentTypes.json` whenever you change Fragments
// https://www.apollographql.com/docs/react/advanced/fragments.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

// Prep the cache (specificially, the client-side-only state) with initial values:
cache.writeData({
  data: {
    networkStatus: {
      __typename: "NetworkStatus",
      noticeActive: false,
    },
  },
});

const httpLink = createHttpLink({
  uri: `/graphql/api`,
});

const authLink = setContext((_, { headers }) => {
  const token = tokenStore.getStoredToken();
  return token && token.jwt
    ? { headers: { ...headers, authorization: `Bearer ${token.jwt}` } }
    : { headers };
});

const errorHandler: ErrorHandler = (error: ErrorResponse): void => {
  if (error.graphQLErrors) {
    error.graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    // Handle cases of the user not being authenticated.
    if (
      error.graphQLErrors.find(({ message }) => message === "unauthenticated")
    ) {
      authPubSub.publish("UNAUTHENTICATED");
    }

    if (error.graphQLErrors.find(({ message }) => message === "unauthorized")) {
      if (window.alert) {
        window.alert("You are not authorized to do that");
      }
    }
  }
  if (error.networkError) {
    cache.writeData({
      data: {
        networkStatus: { __typename: "NetworkStatus", noticeActive: true },
      },
    });
    console.log(`[Network error]: ${error.networkError}`);
  }
};

const errorLink = onError(errorHandler);

const link = authLink.concat(errorLink).concat(httpLink);

const client = new ApolloClient({
  link,
  cache,
});

const HomePage = () => <Redirect to="/auth/sign_in" />;

const NoMatchPage = () => <h1>No Match</h1>;

const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST
  ? process.env.REACT_APP_WEBSOCKET_HOST
  : "";

const websocketEndpoint = `${websocketHost}/socket`;

type InnerAppStateType = {
  auth: { token: Token | null | void };
};

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

interface LogoutData {
  logout: string;
}

class _InnerApp extends Component<RouteComponentProps, InnerAppStateType> {
  onSignOut = () => {
    client.clearStore().then(() => {
      this.props.history.push("/auth/sign_in");
    });
  };

  render() {
    return (
      <Mutation<LogoutData> mutation={LOGOUT}>
        {(logout) => (
          <AuthProvider bus={authPubSub} onSignOut={this.onSignOut}>
            <AuthConsumer>
              {({ signOut, token }) => (
                <div className="App">
                  <NetworkStatusNotifier />
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route
                      exact
                      path="/confirm_account"
                      component={ConfirmAccountPage}
                    />
                    <Route
                      exact
                      path="/saml_redirect"
                      component={SAMLRedirectPage}
                    />
                    <Route path="/auth/" component={AuthLayout} />
                    <PrivateRoute
                      path="/o/"
                      render={(props) => (
                        <SocketProvider
                          endpoint={websocketEndpoint}
                          token={token!.jwt}
                        >
                          <PortalLayout
                            {...props}
                            signOut={() => logout().then(signOut)}
                          />
                        </SocketProvider>
                      )}
                    />
                    <Route component={NoMatchPage} />
                  </Switch>
                </div>
              )}
            </AuthConsumer>
          </AuthProvider>
        )}
      </Mutation>
    );
  }
}

const InnerApp = withRouter(_InnerApp);

const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <InnerApp />
    </ApolloProvider>
  </Router>
);

export { App };
