import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface FormValues {
  email: string;
  password: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .required("Required"),
    password: Yup.string().required(),
  })
  .required();

interface SignInFormProps {
  onSignIn(jwt: string): any;
}

export const SignInForm: FC<SignInFormProps> = (props) => {
  const { onSignIn } = props;
  const [login] = useMutation<MutationData>(LOGIN);

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikHelpers;
      setStatus({ errors: null });

      try {
        const { data } = await login({ variables: values });
        if (data?.login.errors) {
          setStatus({ errors: data.login.errors });
        } else if (data?.login.session) {
          const { token } = data.login.session;
          return onSignIn(token);
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [login, onSignIn]
  );

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="md:px-6 rounded-xl px-2 py-6 bg-white border shadow-md shadow-xl"
        >
          <h4 className="my-4 text-xl font-semibold text-center">
            Sign in to your account
          </h4>

          <FormStatusErrors status={status} />

          <TextField
            label="Email"
            type="email"
            name="email"
            icon="envelope"
            placeholder="email@example.com"
          />

          <div className="mt-3">
            <TextField
              label="Password"
              type="password"
              placeholder="••••••••••"
              name="password"
              icon="lock"
            />
          </div>

          <div className="flex justify-center mt-6">
            <Button
              type="submit"
              kind="primary"
              color="gold"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Sign In
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
