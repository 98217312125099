import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

/**
 * NavTabs
 */

interface NavTabsProps {}

export const NavTabs: FC<NavTabsProps> = (props) => {
  const { children } = props;

  return <ul className="flex">{children}</ul>;
};

/**
 * NavTabLink
 */

type NavTabLinkProps = NavLinkProps;

export const NavTabLink: FC<NavTabLinkProps> = (props) => {
  return (
    <li className="mr-2">
      <NavLink
        {...props}
        className="hover:bg-blue-100 hover:border-gray-200 hover:shadow-inner inline-block px-3 px-6 py-1 font-semibold text-blue-500 border border-transparent rounded rounded-full"
        activeClassName="shadow-inner"
        activeStyle={{
          borderColor: "#fff",
          backgroundColor: "#bee3f8",
          color: "#2b6cb0",
        }}
      />
    </li>
  );
};
