import { FC, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { TimeRange, AppointmentSubset, AppointmentQueryFilter } from "../types";
import { CircleIcon } from "components/CircleIcon";
import { HorizontalBarChart } from "../charts/HorizontalBarChart";
import { NoResults } from "components/NoResults";
import { FAIcon } from "components/FAIcon";
import { iso8601Date } from "lib/dateFormatters";
// import "./ProviderSchedulingVolume.css";

const PROVIDER_SCHEDULING_VOLUME = gql`
  query ProviderSchedulingVolume(
    $timeRange: TimeRangeInput!
    $filter: AppointmentQueryFilter!
  ) {
    analyticsProviderSchedulingVolume(timeRange: $timeRange, filter: $filter) {
      data {
        provider {
          id
          domainId
          nameWithAppellation
        }
        totalCount
        scheduledCount
        cancelledCount
        missedCount
        completedCount
      }
    }
  }
`;

interface Data {
  analyticsProviderSchedulingVolume: {
    data: Datum[];
  };
}

interface Datum {
  provider: {
    id: string;
    domainId: string;
    nameWithAppellation: string;
  };
  totalCount: number;
  scheduledCount: number;
  cancelledCount: number;
  missedCount: number;
  completedCount: number;
}

function transform(data: Datum[]) {
  return data.map((d) => ({
    Total: d.totalCount,
    Scheduled: d.scheduledCount,
    Completed: d.completedCount,
    Cancelled: d.cancelledCount,
    Missed: d.missedCount,
    providerName: d.provider.nameWithAppellation,
  }));
}

const initialFilter: AppointmentQueryFilter = {
  subset: AppointmentSubset.All,
};

interface ProviderSchedulingVolumeProps {
  timeRange: TimeRange;
}

export const ProviderSchedulingVolume: FC<ProviderSchedulingVolumeProps> = (
  props
) => {
  const { timeRange } = props;
  const [filter] = useState<AppointmentQueryFilter>(initialFilter);
  const { data, loading, error } = useQuery<Data>(PROVIDER_SCHEDULING_VOLUME, {
    variables: { timeRange, filter },
  });

  const allowDownload =
    !loading &&
    !error &&
    data?.analyticsProviderSchedulingVolume.data.length !== 0;

  const csvData = useMemo(
    () => toCsvData(data?.analyticsProviderSchedulingVolume.data),
    [data]
  );

  const csvFilename = `provider_scheduling_volume_${iso8601Date(
    timeRange.start
  )}_${iso8601Date(timeRange.finish)}.csv`;

  return (
    <div className="ProviderSchedulingVolume" style={{ margin: "1.5rem" }}>
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-bar" />
            <h4 className="ml-3 text-xl font-semibold text-gray-800">
              Provider Scheduling Volume
            </h4>
          </div>

          {allowDownload ? (
            <CSVLink
              data={csvData}
              separator={";"}
              className="focus:outline-none hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md"
              filename={csvFilename}
            >
              Export CSV{" "}
              <span className="ml-2">
                <FAIcon icon="file-download" />
              </span>
            </CSVLink>
          ) : (
            <div />
          )}
        </div>
        <p className="p-6 text-sm text-left">
          <b>Note:</b> Data shown counts appointments related to P2P{" "}
          <i>
            <u>requests</u>
          </i>{" "}
          that were created during the selected date range, rather than
          appointments that{" "}
          <i>
            <u>occurred</u>
          </i>{" "}
          during the date range.
        </p>
        <div className="pt-2">
          {loading ? (
            <p>Loading...</p>
          ) : error || !data?.analyticsProviderSchedulingVolume ? (
            <div>
              <h1>Failed to Load</h1>
            </div>
          ) : data.analyticsProviderSchedulingVolume.data.length === 0 ? (
            <NoResults
              icon="chart-bar"
              text="No data for selected date range"
            />
          ) : (
            <div style={{ textAlign: "left", padding: "2rem" }}>
              <HorizontalBarChart
                data={transform(data.analyticsProviderSchedulingVolume.data)}
                bars={[
                  {
                    dataKey: "Scheduled",
                    fill: "#1f77b4",
                  },
                  {
                    dataKey: "Completed",
                    fill: "#2ca02c",
                  },
                  {
                    dataKey: "Cancelled",
                    fill: "#ff7f0e",
                  },
                  {
                    dataKey: "Missed",
                    fill: "#d62728",
                  },
                ]}
                yValKey="providerName"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function toCsvData(data?: Datum[]): Array<Array<string>> {
  if (!data) return [[]];

  const header = [
    "provider_name",
    "domain_id",
    "scheduled",
    "completed",
    "cancelled",
    "missed",
    "total",
  ];

  return [
    header,
    ...data.map((d) => [
      d.provider.nameWithAppellation,
      d.provider.domainId,
      d.scheduledCount.toString(),
      d.completedCount.toString(),
      d.cancelledCount.toString(),
      d.missedCount.toString(),
      d.totalCount.toString(),
    ]),
  ];
}
