import {
  AppointmentModel,
  AppointmentRequestModel,
} from "../../../AppointmentRequestShowPage";
import { translateLOR } from "lib/kentuckyWording";

export function infoTextForAppointment(
  apptReq: AppointmentRequestModel,
  appt: AppointmentModel
) {
  const str = `Case #: ${apptReq.caseProfiles
    .map((cp) => cp.caseReferenceNumber)
    .join(", ")}
Appt Date: ${appt.startDateString}
Appt Time: ${appt.startTimeString}
Reviewing Provider: ${appt.receivingProvider.nameWithAppellation}
Level of Review: ${translateLOR(
    apptReq.caseProfiles[0].levelOfReview.label,
    apptReq.caseProfiles[0].insurancePlanCode,
    apptReq.caseProfiles[0].memberState.name
  )}
Name of Provider on Case: ${apptReq.requestingProviderName}
Contact Person Location: ${apptReq.contactPersonLocation ?? "(not provided)"}
Contact Name: ${apptReq.nameOfContactPerson ?? "(not provided)"}
${otherP2pContactName(apptReq)}
Contact Phone: ${contactPhoneFor(apptReq)}
Alternate Phone: ${alternatePhoneFor(apptReq)}
Contact Email: ${contactEmailFor(apptReq)}
Contact Instructions: ${contactInstructionsFor(apptReq)}
${callNotes(apptReq)}`
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)
    .join("\n");

  return str;
}

export function contactPhoneFor(apptReq: AppointmentRequestModel) {
  if (apptReq.contactPhoneNumberExtension) {
    return `${apptReq.contactPhoneNumber.formatted} ext. ${apptReq.contactPhoneNumberExtension}`;
  } else {
    return apptReq.contactPhoneNumber.formatted;
  }
}

export function alternatePhoneFor(apptReq: AppointmentRequestModel) {
  if (apptReq.alternateContactPhoneNumber) {
    if (apptReq.alternateContactPhoneNumberExtension) {
      return `${apptReq.alternateContactPhoneNumber.formatted} ext. ${apptReq.alternateContactPhoneNumberExtension}`;
    } else {
      return apptReq.alternateContactPhoneNumber.formatted;
    }
  } else {
    return `(not provided)`;
  }
}

function contactEmailFor(apptReq: AppointmentRequestModel) {
  if (apptReq.contactEmail) {
    return apptReq.contactEmail;
  } else {
    return `(not provided)`;
  }
}

function contactInstructionsFor(apptReq: AppointmentRequestModel) {
  if (apptReq.contactInstructions) {
    return apptReq.contactInstructions;
  } else {
    return `(not provided)`;
  }
}

function otherP2pContactName(apptReq: AppointmentRequestModel) {
  if (apptReq.otherP2pContactName) {
    return `Other P2P Contact Name: ${apptReq.otherP2pContactName}`;
  } else {
    return "";
  }
}

function callNotes(apptReq: AppointmentRequestModel) {
  if (apptReq.callNotes && apptReq.callNotes.length > 0) {
    const notes = apptReq.callNotes.map((note) => ` - ${note}`).join("\n");
    return `Call Notes: \n${notes}`;
  } else {
    return "";
  }
}
