import { FC, useCallback, useState } from "react";
import { lightFormat } from "date-fns";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { TimeRange } from "../types";
import { LineChart } from "../charts/LineChart";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon } from "components/FAIcon";
import { WebformSubmissionVolumeExportModal } from "./WebformSubmissionVolumeExportModal";
import { hMmA, mmDdYy } from "lib/dateFormatters";
import { Button } from "components/Button";

const WEBFORM_SUBMISSION_VOLUME = gql`
  query WebformSubmissionVolume($dateRange: InclusiveDateRangeInput!) {
    analyticsWebformSubmissionVolume(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      total
      data {
        datetime
        value
      }
    }
  }
`;

interface Data {
  analyticsWebformSubmissionVolume: {
    timeRange: {
      start: string;
      finish: string;
    };
    total: number;
    data: {
      datetime: string;
      value: number;
    }[];
  };
}

function transform(data: { datetime: string; value: number }[]) {
  const p = data.map((d) => ({
    ...d,
    datetime: `${mmDdYy(d.datetime)} ${hMmA(d.datetime)}`,
  }));
  return p;
}

interface WebformSubmissionVolumeProps {
  timeRange: TimeRange;
}

type ActiveModal = "EXPORT_WEBFORM_SUBMISSION_VOLUME";

export const WebformSubmissionVolume: FC<WebformSubmissionVolumeProps> = (
  props
) => {
  const { timeRange } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const dateRange = {
    start: lightFormat(timeRange.start, "MM/dd/yyyy"),
    finish: lightFormat(timeRange.finish, "MM/dd/yyyy"),
  };

  const { data, loading, error } = useQuery<Data>(WEBFORM_SUBMISSION_VOLUME, {
    variables: { dateRange },
  });

  return (
    <>
      <WebformSubmissionVolumeExportModal
        isOpen={activeModal === "EXPORT_WEBFORM_SUBMISSION_VOLUME"}
        onClose={closeModal}
        onSuccess={console.log}
        initialValues={{
          windowStart: dateRange.start,
          windowFinish: dateRange.finish,
        }}
      />
      <div className="_WebformSubmissionVolume" style={{ margin: "1.5rem" }}>
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CircleIcon icon="chart-line" />
              <h4 className="ml-3 text-xl font-semibold text-gray-800">
                Webform Submission Volume
              </h4>
            </div>
            <Button
              type="button"
              kind="tertiary"
              className="border"
              onClick={() => setActiveModal("EXPORT_WEBFORM_SUBMISSION_VOLUME")}
            >
              Export CSV{" "}
              <span className="ml-2">
                <FAIcon icon="file-download" />
              </span>
            </Button>
          </div>
          <div className="section pt-8">
            {loading ? (
              <p>Loading...</p>
            ) : error || !data?.analyticsWebformSubmissionVolume ? (
              <div>
                <h1>Failed to Load</h1>
              </div>
            ) : (
              <>
                <div>
                  <p>
                    Total during range:{" "}
                    {data.analyticsWebformSubmissionVolume.total}
                  </p>
                </div>
                <div className="p-8 text-left">
                  <LineChart
                    data={transform(data.analyticsWebformSubmissionVolume.data)}
                    xValKey="datetime"
                    yValKey="value"
                    yValLabel="P2P Requests"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
