import { FC } from "react";
import { useMutation, useQuery } from "react-apollo";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import { HorizontalTextField } from "components/formik/TextField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { useToast } from "layouts/PortalLayout/Toast";
import { validPassword } from "lib/validPassword";
import { HorizontalField } from "components/formik/FieldStructure";
import { Button } from "components/Button";

const CURRENT_USER_ACCOUNT_DATA_QUERY = gql`
  query MyUserEmailQuery {
    me {
      id
      email
      isSsoUser
      confirmedAt
    }
  }
`;

interface CurrentUserData {
  me: {
    id: string;
    email: string;
    isSsoUser: boolean;
    confirmedAt?: string;
  };
}

const UPDATE_CURRENT_USER_EMAIL = gql`
  mutation UpdateCurrentUserEmail($input: UpdateEmailInput!) {
    updateCurrentUserEmail(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdateEmailData {
  updateCurrentUserEmail: {
    errors?: InputError[];
  };
}

const UPDATE_CURRENT_USER_PASSWORD = gql`
  mutation UpdateCurrentUserPassword($input: UpdatePasswordInput!) {
    updateCurrentUserPassword(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdatePasswordData {
  updateCurrentUserPassword: {
    errors?: InputError[];
  };
}

/**
 * InfoForSSOUser.
 */

interface InfoForSSOUserProps {
  email: string;
}

const InfoForSSOUser: FC<InfoForSSOUserProps> = (props) => {
  const { email } = props;
  return (
    <div className="p-6">
      <h5 className="py-2 text-lg font-semibold">
        Your account information is managed by a SSO (Single Sign-On) Identity
        Provider.
      </h5>
      <div className="AppointmentRequestShowPage__section__content">
        <label className="de-emph-label">Account Email</label>
        <p className="emph" style={{ whiteSpace: "nowrap" }}>
          {email}
        </p>
      </div>
    </div>
  );
};

/**
 * AccountForm.
 */

interface AccountFormProps {}

export const AccountForm: FC<AccountFormProps> = () => {
  const toast = useToast();
  const { data, loading, error } = useQuery<CurrentUserData>(
    CURRENT_USER_ACCOUNT_DATA_QUERY
  );
  const [updateEmail] = useMutation<UpdateEmailData>(UPDATE_CURRENT_USER_EMAIL);
  const [updatePassword] = useMutation<UpdatePasswordData>(
    UPDATE_CURRENT_USER_PASSWORD
  );

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Account" />
      <GradientHeader
        title="Account Information"
        subtitle="Update your account information."
      />
      <div className="AccountForm">
        {loading ? (
          <h1>Loading...</h1>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : data.me.isSsoUser ? (
          <InfoForSSOUser email={data.me.email} />
        ) : (
          <>
            <Formik
              initialValues={{
                email: data.me.email,
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("Required"),
                password: Yup.string().required(
                  "Your password is required to update your email."
                ),
              })}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });
                updateEmail({
                  variables: { input: values },
                  refetchQueries: [{ query: CURRENT_USER_ACCOUNT_DATA_QUERY }],
                }).then(
                  (resp) => {
                    if (resp?.data?.updateCurrentUserEmail.errors) {
                      setStatus({
                        errors: resp.data.updateCurrentUserEmail.errors,
                      });
                    } else {
                      // Success
                      toast.success("Email updated!");
                    }
                    setSubmitting(false);
                  },
                  (rej) => setSubmitting(false)
                );
                console.log("Hello!", values);
              }}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <div className="p-6">
                  <h3 className="py-2 text-xl font-semibold">Update Email</h3>
                  <form onSubmit={handleSubmit}>
                    <FormStatusErrors status={status} />

                    <div className="mt-3">
                      <HorizontalTextField
                        type="email"
                        id="updateEmail-email"
                        name="email"
                        label="Email"
                      />
                    </div>

                    <div className="mt-3">
                      <HorizontalTextField
                        type="password"
                        id="updateEmail-password"
                        name="password"
                        label="Password"
                      />
                    </div>

                    <div className="mt-3">
                      <HorizontalField label="">
                        <Button
                          type="submit"
                          kind="primary"
                          color="gold"
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Update Email
                        </Button>
                      </HorizontalField>
                    </div>
                  </form>
                </div>
              )}
            </Formik>

            <Formik
              initialValues={{
                password: "",
                passwordConfirmation: "",
                currentPassword: "",
              }}
              validationSchema={Yup.object().shape({
                password: validPassword,
                passwordConfirmation: validPassword,
                currentPassword: Yup.string().required(
                  "Your current password is required to update your password."
                ),
              })}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });
                if (values.password !== values.passwordConfirmation) {
                  setStatus({
                    errors: [
                      {
                        message:
                          "New Password and New Password Confirmation must match",
                      },
                    ],
                  });
                  setSubmitting(false);
                } else {
                  updatePassword({
                    variables: { input: values },
                  }).then(
                    (resp) => {
                      if (resp?.data?.updateCurrentUserPassword.errors) {
                        setStatus({
                          errors: resp.data.updateCurrentUserPassword.errors,
                        });
                      } else {
                        // Success
                        toast.success("Password updated!");
                      }
                      setSubmitting(false);
                    },
                    (rej) => setSubmitting(false)
                  );
                  console.log("Hello!", values);
                }
              }}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <div className="p-6">
                  <h3 className="py-2 text-xl font-semibold">
                    Update Password
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <FormStatusErrors status={status} />

                    <div className="mt-3">
                      <HorizontalTextField
                        type="password"
                        id="updatePassword-password"
                        name="password"
                        label="New Password"
                      />
                    </div>

                    <div className="mt-3">
                      <HorizontalTextField
                        type="password"
                        id="updatePassword-passwordConfirmation"
                        name="passwordConfirmation"
                        label="Confirm New Password"
                      />
                    </div>

                    <div className="mt-3">
                      <HorizontalTextField
                        type="password"
                        id="updatePassword-currentPassword"
                        name="currentPassword"
                        label="Current Password"
                      />
                    </div>

                    <div className="mt-3">
                      <HorizontalField label="">
                        <Button
                          type="submit"
                          kind="primary"
                          color="gold"
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Update Password
                        </Button>
                      </HorizontalField>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};
