import React, { FC } from "react";
import { Modal, ModalHeader } from 'components/Modal';

interface EditTagModalProps {
  id: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const EditTagModal: FC<EditTagModalProps> = props => {
  const { id, isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="EditTagModal">
      <ModalHeader icon="tags" title="Edit Tag" onClose={onClose} />
      <p>EditTagModal: {id}</p>
    </Modal>
  );
};
