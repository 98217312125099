import React, { FC } from "react";
import { FAIcon } from "components/FAIcon";
import "./SelectSkillMessage.css";

interface SelectSkillMessageProps { }

export const SelectSkillMessage: FC<SelectSkillMessageProps> = () => {
  return (
    <div className="SelectSkillMessage">
      <div className="SelectSkillMessage__icon-container">
        <FAIcon icon="arrow-up" />
      </div>
      <div className="SelectSkillMessage__message-container">
        <p>Select a Skill above and click "Search" to search for providers</p>
      </div>
    </div>
  );
};
