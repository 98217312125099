import { FC } from "react";
import {
  Calendar as ReactBigCalendar,
  CalendarProps as ReactBigCalendarProps,
  dateFnsLocalizer,
} from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

const min = new Date(2021, 12, 0, 5, 0, 0);
const max = new Date(2021, 12, 0, 20, 0, 0);

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface CalendarProps {
  date: Date;
  onNavigate(newDate: Date): void;
  onSelectEvent: ReactBigCalendarProps["onSelectEvent"];
  events: {
    start: Date;
    finish: Date;
  }[];
}

export const Calendar: FC<CalendarProps> = (props) => {
  const { date, onNavigate, events, onSelectEvent } = props;

  return (
    <div className="Calendar">
      <ReactBigCalendar
        date={date}
        onNavigate={onNavigate}
        localizer={localizer}
        defaultView="week"
        events={events}
        startAccessor="start"
        endAccessor="finish"
        style={{ height: "80vh" }}
        eventPropGetter={getEventProps}
        onSelectEvent={onSelectEvent as any}
        min={min}
        max={max}
      />
    </div>
  );
};

const systemColorsAvailable: Record<string, string> = {
  IEX: "rgb(8, 124, 55)",
  EWS: "rgb(33, 174, 49)",
  Preferral: "rgb(38, 27, 195)",
};

const systemColorsUnavailable: Record<string, string> = {
  IEX: "rgb(73, 73, 73)",
  EWS: "rgb(174, 33, 42)",
  Preferral: "rgb(38, 27, 195)",
};

function getEventProps(event: any): { className?: string; style?: any } {
  console.log(event);
  if (event.available) {
    return {
      style: {
        width: "calc(100% + 10px)",
        border: "none",
        borderRadius: 0,
        opacity: "70%",
        backgroundColor:
          systemColorsAvailable[event.system] || "rgb(101, 59, 110)",
      },
    };
  }
  return {
    style: {
      opacity: "70%",
      backgroundColor:
        systemColorsUnavailable[event.system] || "rgb(101, 59, 110)",
    },
  };
}
