import { FC } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Spinner } from "components/Spinner";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { GradientHeader } from "pages/SettingsPage/GradientHeader";
import { Table, TH, TD, TableContainer } from "components/Table";

const LIST_SPECIALTIES = gql`
  query ListSpecialties {
    specialties {
      id
      name
      providerCount
      skillPrioritizationCount
    }
  }
`;

interface Data {
  specialties: {
    id: string;
    name: string;
    providerCount: number;
    skillPrioritizationCount: number;
  }[];
}

interface Variables {}

interface SpecialtiesProps {}

export const Specialties: FC<SpecialtiesProps> = (props) => {
  const { data, loading, error } = useQuery<Data, Variables>(LIST_SPECIALTIES);

  return (
    <div className="_Specialties bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Specialties" />
      <GradientHeader
        title="Specialties"
        subtitle="Manage the set of provider specialties"
      />
      <div className="p-4">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.specialties ? (
          <>
            <p>Failed to load</p>
          </>
        ) : (
          <div>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TH>Name</TH>
                    <TH>Skill Count</TH>
                    <TH>Provider Count</TH>
                    <TH />
                  </tr>
                </thead>
                <tbody>
                  {data.specialties.map((specialty) => (
                    <tr key={specialty.id}>
                      <TD>{specialty.name}</TD>
                      <TD>{specialty.skillPrioritizationCount}</TD>
                      <TD>{specialty.providerCount}</TD>
                      <TD> </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
