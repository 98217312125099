import { FC } from "react";
import { FAIcon } from "components/FAIcon";
import { mmDdYyyy, ddd } from "lib/dateFormatters";

interface AppointmentInfoProps {
  startTimeString: string;
  startTime: string;
  providerName: string;
}

export const AppointmentInfo: FC<AppointmentInfoProps> = (props) => {
  const { startTimeString, startTime, providerName } = props;
  const dayString = `${ddd(startTime)} ${mmDdYyyy(startTime)}`;

  return (
    <div className="_AppointmentInfo flex flex-col items-baseline">
      <p>
        <span className="icon-container">
          <FAIcon icon={["far", "calendar-alt"]} />
        </span>
        {dayString}
      </p>
      <p>
        <span className="icon-container">
          <FAIcon icon={["far", "clock"]} />
        </span>
        {startTimeString}
      </p>
      <p>
        <span className="icon-container">
          <FAIcon icon="user-md" />
        </span>
        {providerName}
      </p>
    </div>
  );
};
