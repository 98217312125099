import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";

interface NoResultsProps {
  icon: IconProp;
  text: string;
}

export const NoResults: FC<NoResultsProps> = (props) => {
  const { icon, text } = props;

  return (
    <div className="px-4 py-8 text-center">
      <div
        className="bg-gray-200 inline-block leading-none mx-auto p-12 p-5 rounded rounded-full text-6xl text-center text-gray-600 text-xl"
        style={{
          display: "inline-block",
          padding: "3rem",
        }}
      >
        <FAIcon icon={icon} />
      </div>
      <p
        className="mb-12 mt-8 text-gray-800 text-xl"
        style={{
          marginTop: "2rem",
        }}
      >
        {text}
      </p>
    </div>
  );
};
