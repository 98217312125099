import React, { FC } from "react";
import "./GradientHeader.css";

interface GradientHeaderProps {
  title: string;
  subtitle: string;
}

export const GradientHeader: FC<GradientHeaderProps> = props => {
  const { title, subtitle } = props;
  return (
    <div className="GradientHeader-primary">
      <h1>{title}</h1>
      <h6>{subtitle}</h6>
    </div>
  );
};
