import React from "react";
import { FormikProvider, FormikValues, useFormik } from "formik";
import { shallowEqual } from "lib/shallowEqual";
import { FAIcon } from "../FAIcon";

interface FilterFormProps<T = never> {
  defaultValue: T;
  value: T;
  onChange(newValue: T): void;
  children: React.ReactNode | React.ReactNodeArray;
  resettable?: boolean;
}

export function FilterForm<T extends FormikValues>(props: FilterFormProps<T>) {
  const { defaultValue, value, onChange, children, resettable = true } = props;

  const formikBag = useFormik<T>({
    initialValues: value,
    onSubmit: onChange,
  });

  const { handleSubmit, resetForm, submitForm } = formikBag;

  const isResettable = resettable && !shallowEqual(defaultValue, value);

  const resetFilter = React.useCallback(() => {
    resetForm(defaultValue);
    submitForm();
  }, [resetForm, submitForm, defaultValue]);

  return (
    <div className="_FilterForm text-left">
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>{children}</form>
        {isResettable ? (
          <div className="p-1">
            <button
              type="button"
              className="text-sm text-red-500 border-b border-red-400 border-dashed"
              onClick={resetFilter}
            >
              <span className="mr-2">
                <FAIcon icon="times" />
              </span>
              Clear filter
            </button>
          </div>
        ) : null}
      </FormikProvider>
    </div>
  );
}
