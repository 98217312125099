import { FC, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import qs from "query-string";
import { ConfirmationForm } from "./ConfirmationForm";
import { coalesceString } from "lib/coalesce";
import { CircleIcon } from "components/CircleIcon";
import { RefNum } from "components/RefNum";
import { translateLOR } from "lib/kentuckyWording";
import { DetailItem, DetailList } from "components/DetailList";
import { AppointmentInfo } from "components/AppointmentInfo";
import { mmDdYyyy } from "lib/dateFormatters";

const CONFIRMATION_PAGE_QUERY = gql`
  query getConfirmationPageData(
    $appointmentRequestId: UUID4!
    $providerId: UUID4!
  ) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        episodeId
        memberFirstName
        memberLastName
        memberDob
        memberMembershipNumber
        caseReferenceNumber
        externalSystemName
        insurancePlanCode
        icd10Code
        levelOfReview {
          value
          label
        }
        healthPlan {
          id
          name
        }
        modality {
          id
          name
        }
        memberState {
          id
          name
        }
      }
    }
    provider(id: $providerId) {
      id
      firstName
      lastName
      nameWithAppellation
      skills {
        id
        name
      }
    }
  }
`;

interface Data {
  appointmentRequest: {
    id: string;
    caseProfiles: {
      id: string;
      episodeId?: string;
      memberFirstName: string;
      memberLastName: string;
      memberDob: string;
      memberMembershipNumber: string;
      caseReferenceNumber: string;
      externalSystemName: string;
      insurancePlanCode?: string;
      icd10Code: string | void;
      levelOfReview: {
        value: string;
        label: string;
      };
      healthPlan: {
        id: string;
        name: string;
      };
      modality: {
        id: string;
        name: string;
      };
      memberState: {
        id: string;
        name: string;
      };
    }[];
  };
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    nameWithAppellation: string;
    skills: {
      id: string;
      name: string;
    }[];
  };
}

interface RouteParams {
  step: string;
}

export const AppointmentRequestConfirmationPage: FC = () => {
  const location = useLocation();
  const match = useRouteMatch<RouteParams>();
  const {
    startTime,
    startTimeString,
    finishTime,
    providerId,
    appointmentRequestId,
  } = qs.parse(location.search);

  const [loadData, { loading, error, data }] = useLazyQuery<Data>(
    CONFIRMATION_PAGE_QUERY
  );

  useEffect(() => {
    if (match.params.step === "4") {
      loadData({ variables: { appointmentRequestId, providerId } });
    }
  }, [match.params.step, appointmentRequestId, providerId, loadData]);

  if (match.params.step !== "4") {
    return <div />;
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error || !(data && data.appointmentRequest && data.provider) ? (
        <div style={{ padding: "1.5rem" }}>
          <h1>Failed to Load</h1>
        </div>
      ) : (
        <div className="gap-x-4 flex">
          <div className="md:block md:w-5/12 hidden">
            <div className="px-6 pt-6 pb-12 text-left bg-white rounded-lg shadow-lg">
              <h4 className="text-2xl font-semibold text-gray-800">
                Case Info
              </h4>

              {data.appointmentRequest.caseProfiles.map((caseProfile, idx) => (
                <div
                  key={caseProfile.id}
                  className="p-4 pb-8 mt-4 border border-gray-200 rounded-lg shadow-md"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-700">Case #{idx + 1}</p>
                    <RefNum refNum={caseProfile.caseReferenceNumber} />
                  </div>

                  <DetailList className="mt-5">
                    {caseProfile.episodeId && (
                      <DetailItem labelWidth={120} label="Episode ID">
                        {caseProfile.episodeId}
                      </DetailItem>
                    )}

                    <DetailItem labelWidth={120} label="Member Name">
                      <p className="capitalize">
                        {caseProfile.memberFirstName}{" "}
                        {caseProfile.memberLastName}
                      </p>
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member DOB">
                      {mmDdYyyy(caseProfile.memberDob)}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Level of Review">
                      {translateLOR(
                        caseProfile.levelOfReview.label,
                        caseProfile.insurancePlanCode,
                        caseProfile.memberState.name
                      )}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="System Name">
                      {caseProfile.externalSystemName}
                    </DetailItem>
                    {caseProfile.icd10Code ? (
                      <DetailItem labelWidth={120} label="ICD10 Code">
                        {caseProfile.icd10Code}
                      </DetailItem>
                    ) : null}
                    <DetailItem labelWidth={120} label="Health Plan Name">
                      {caseProfile.healthPlan.name}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member ID">
                      {caseProfile.memberMembershipNumber}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Case Type/Modality">
                      {caseProfile.modality.name}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member State">
                      {caseProfile.memberState.name}
                    </DetailItem>
                  </DetailList>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-7/12">
            <div className="p-4 bg-white rounded-lg shadow-lg">
              <div className="gap-x-3 flex items-center">
                <CircleIcon icon="phone" />
                <h4 className="text-2xl font-semibold text-gray-800">
                  P2P Contact Details
                </h4>
              </div>

              <div className="px-3 py-4 text-left">
                <div className="px-6 pt-2 pb-4">
                  <label className="block pb-2 font-bold text-gray-800">
                    Appointment Details
                  </label>
                  <AppointmentInfo
                    startTime={coalesceString(startTime)}
                    startTimeString={coalesceString(startTimeString)}
                    providerName={data.provider.nameWithAppellation}
                  />
                </div>

                <ConfirmationForm
                  appointmentRequestId={coalesceString(appointmentRequestId)}
                  startTime={coalesceString(startTime)}
                  finishTime={coalesceString(finishTime)}
                  providerId={coalesceString(providerId)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
