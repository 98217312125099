import React from "react";
import ReactModal from "react-modal";
import cx from "classnames";

type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => any;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
  style?: React.CSSProperties;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  className = "",
  overlayClassName,
  children,
  closeTimeoutMS = 150,
  style,
}) => {
  const widthCn = className.includes("max-w-") ? null : "max-w-lg";
  const cn = ["Modal w-full px-2", widthCn, className]
    .filter(Boolean)
    .join(" ");

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cn}
      overlayClassName={cx("Overlay", overlayClassName)}
      closeTimeoutMS={closeTimeoutMS}
      style={{ content: style }}
    >
      <div className="bg-white rounded-lg shadow-md">{children}</div>
    </ReactModal>
  );
};

export { Modal };
