import { useEffect, useState, useRef, useCallback } from "react";
import { Presence, Channel } from "phoenix";
import { useSocket } from "../components/SocketContext";

function defaultTransform<PresenceData = any>(presence: PresenceData[]) {
  return presence;
}

export function useChannelPresence<
  PresenceData = any,
  TransformedPresenceData = any
>(
  topic: string,
  transform?: (presences: PresenceData[]) => TransformedPresenceData
): { presenceState: TransformedPresenceData; channel: Channel | null } {
  const { socket } = useSocket();
  const [presenceState, setPresenceState] = useState<any>(null);
  const channelRef = useRef<Channel | null>(null);

  const transformFn = transform || defaultTransform;

  const joinChannel = useCallback(() => {
    if (!socket) {
      console.warn("Socket is not available");
      return;
    }

    const newChannel = socket.channel(topic);

    newChannel
      .join()
      .receive("ok", () => console.log(`Joined channel: ${topic}`))
      .receive("error", (resp: any) =>
        console.error(`Unable to join channel: ${topic}`, resp)
      );

    const presence = new Presence(newChannel);

    presence.onSync(() => {
      setPresenceState(transformFn(presence.list()));
    });

    channelRef.current = newChannel;

    return () => {
      newChannel.leave();
      channelRef.current = null;
    };
  }, [socket, topic, transformFn]);

  useEffect(() => {
    const cleanup = joinChannel();
    return cleanup;
  }, [joinChannel]);

  return { presenceState, channel: channelRef.current };
}
