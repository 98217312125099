import { FC, HTMLAttributes, ReactNode } from "react";
import { useField, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";

interface SingleCheckboxInputProps {
  id?: string;
  name: string;
  checkboxLabel?: ReactNode;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
}

export const SingleCheckboxInput: FC<SingleCheckboxInputProps> = (props) => {
  const {
    id,
    name,
    disabled = false,
    inputProps = {},
    checkboxLabel = null,
  } = props;

  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  function handleChange() {
    setValue(!value);
  }

  return (
    <>
      <div className="flex items-start text-sm">
        <div className="flex items-center">
          &#8203;
          <input
            id={id || name}
            name={name}
            type="checkbox"
            checked={value}
            onChange={handleChange}
            disabled={disabled}
            className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
            {...inputProps}
          />
        </div>
        {checkboxLabel && (
          <label
            htmlFor={id || name}
            className="ml-3 font-medium text-gray-800"
          >
            {checkboxLabel}
          </label>
        )}
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

interface SingleCheckboxFieldProps extends SingleCheckboxInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const SingleCheckboxField: FC<SingleCheckboxFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SingleCheckboxInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalSingleCheckboxField: FC<SingleCheckboxFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SingleCheckboxInput {...rest} />
    </HorizontalField>
  );
};
