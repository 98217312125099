import React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import "./EmailConfirmationBanner.css";
import { Toaster } from "../Toast";

const FETCH_CONFIRMATION = gql`
  query CurrentUserConfirmedAt {
    me {
      id
      confirmedAt
    }
  }
`;

interface Data {
  me: {
    id: string;
    confirmedAt?: string;
  };
}

const REQUEST_TOKEN = gql`
  mutation RequestConfirmationEmail {
    requestAccountConfirmation {
      sent
      reason
    }
  }
`;

interface MutationData {
  requestAccountConfirmation: {
    sent: boolean;
    reason?: string;
  };
}

type EmailConfirmationBannerProps = {};

const EmailConfirmationBanner: React.SFC<EmailConfirmationBannerProps> = () => {
  return (
    <Query<Data> query={FETCH_CONFIRMATION}>
      {({ data }) =>
        data && data.me && !data.me.confirmedAt ? (
          <div className="EmailConfirmationBanner">
            <p>Please check your email inbox for a verification email.</p>
            <p>
              Didn't receive it?{" "}
              <Toaster>
                {toast => (
                  <Mutation<MutationData> mutation={REQUEST_TOKEN}>
                    {requestNewToken => (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          requestNewToken({
                            refetchQueries: [{ query: FETCH_CONFIRMATION }]
                          }).then(res => {
                            if (res && res.data) {
                              if (res.data.requestAccountConfirmation.sent) {
                                toast.info(
                                  "A new account verification email has been sent. Please check your email."
                                );
                              } else {
                                toast.info(
                                  "Your account has already been verified!"
                                );
                              }
                            }
                          });
                        }}
                      >
                        Click here to re-send.
                      </span>
                    )}
                  </Mutation>
                )}
              </Toaster>
            </p>
          </div>
        ) : null
      }
    </Query>
  );
};

export { EmailConfirmationBanner };
