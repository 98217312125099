import { FC, useState } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { SelectedAppointment } from "../RescheduleAppointmentModal";
import {
  AppointmentModel,
  APPOINTMENT_REQUEST_SHOW_QUERY,
} from "../../../AppointmentRequestShowPage";
import { useToast } from "layouts/PortalLayout/Toast";
import { NoLongerAvailable } from "./NoLongerAvailable";
import { isScheduleConflict } from "pages/AppointmentRequestConfirmationPage/ConfirmationForm";
import { Button } from "components/Button";
import {
  useAppointmentRequestContact,
  validationSchema as contactValidationSchema,
  toMutationVariables as contactValuesToMutationVariables,
  ContactDetailsFields,
} from "./ContactDetailsFields";
import { Spinner } from "components/Spinner";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const RESCHEDULE_APPOINTMENT = gql`
  mutation RescheduleAppointment(
    $id: UUID4!
    $newAppointment: AppointmentInput!
    $appointmentRequest: ContactDetailsInput!
  ) {
    rescheduleAppointment(
      id: $id
      newAppointment: $newAppointment
      appointmentRequest: $appointmentRequest
    ) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface Data {
  rescheduleAppointment: {
    errors: Array<{ key: string; message: string }>;
    appointment: {
      id: string;
    };
  };
}

interface JustRescheduleFormProps {
  oldAppointment: AppointmentModel;
  newAppointment: SelectedAppointment;
  forgetAppointmentSelection(): void;
  onSuccess(): void;
}

export const JustRescheduleForm: FC<JustRescheduleFormProps> = (props) => {
  const {
    forgetAppointmentSelection,
    newAppointment,
    oldAppointment,
    onSuccess,
  } = props;
  const [noLongerAvailable, setNoLongerAvailable] = useState(false);

  const toast = useToast();

  const {
    loading,
    error,
    data: initialContactValues,
  } = useAppointmentRequestContact(oldAppointment.appointmentRequestId);

  const [rescheduleAppointment] = useMutation<Data>(RESCHEDULE_APPOINTMENT);

  return (
    <div>
      {noLongerAvailable ? (
        <NoLongerAvailable onClick={forgetAppointmentSelection} />
      ) : loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !initialContactValues ? (
        <p>Failed to load.</p>
      ) : (
        <Formik
          initialValues={{ appointmentRequest: initialContactValues }}
          validationSchema={Yup.object().shape({
            appointmentRequest: contactValidationSchema,
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            rescheduleAppointment({
              variables: {
                id: oldAppointment.id,
                newAppointment: {
                  timeRange: {
                    start: newAppointment.slot.timeRange.start,
                    finish: newAppointment.slot.timeRange.finish,
                  },
                  receivingProviderId: newAppointment.provider.id,
                },
                appointmentRequest: contactValuesToMutationVariables(
                  values.appointmentRequest
                ),
              },
              refetchQueries: [
                {
                  query: APPOINTMENT_REQUEST_SHOW_QUERY,
                  variables: {
                    id: oldAppointment.appointmentRequestId,
                  },
                },
              ],
            }).then((resp) => {
              if (resp?.data?.rescheduleAppointment.errors) {
                if (
                  isScheduleConflict(resp.data.rescheduleAppointment.errors)
                ) {
                  setNoLongerAvailable(true);
                }
                setStatus({ errors: resp.data.rescheduleAppointment.errors });
              } else if (resp?.data?.rescheduleAppointment.appointment) {
                toast.success("Appointment Rescheduled");
                onSuccess();
              }
              setSubmitting(false);
            });
          }}
        >
          {({ handleSubmit, isSubmitting, status }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="mt-3">
                <ContactDetailsFields field="appointmentRequest" />
              </div>

              <div className="mx-auto mt-6 text-right">
                <Button
                  type="submit"
                  kind="primary"
                  color="gold"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Reschedule Appointment
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
