import React, { FC, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { HorizontalSelectField } from "components/formik/SelectField";
import { useToast } from "layouts/PortalLayout/Toast";
import { HorizontalNumberField } from "components/formik/NumberField";
import { FAIcon } from "components/FAIcon";
import { NoResults } from "components/NoResults";
import { Tooltip } from "components/Tooltip";
import { Button } from "components/Button";

const SKILL_SPECIALTY_PRIORITIZATIONS = gql`
  query SkillSpecialtyPrioritizations($skillId: UUID4!) {
    skill(id: $skillId) {
      id
      name
      externalSystemName
      externalSkillName
      specialtyPrioritizationCount
      skillSpecialtyPrioritizations {
        id
        priority
        specialty {
          id
          name
          providerCount
        }
      }
    }
    specialties {
      id
      name
      providerCount
    }
  }
`;

interface Data {
  skill: {
    id: string;
    name: string;
    externalSystemName: string;
    externalSkillname: string;
    skillSpecialtyPrioritizations: {
      id: string;
      priority: number;
      specialty: {
        id: string;
        name: string;
        providerCount: number;
      };
    }[];
  };
  specialties: SpecialtyModel[];
}

interface Variables {
  skillId: string;
}

interface SpecialtyModel {
  id: string;
  name: string;
  providerCount: number;
}

const ADD_SKILL_SPECIALTY_PRIORITIZATION = gql`
  mutation AddSkillSpecialtyPrioritization(
    $input: SkillSpecialtyPrioritizationInput!
  ) {
    createSkillSpecialtyPrioritization(input: $input) {
      errors {
        key
        message
      }
      skillSpecialtyPrioritization {
        id
      }
    }
  }
`;

interface CreateData {
  createSkillSpecialtyPrioritization: {
    errors?: InputError[];
    skillSpecialtyPrioritization?: {
      id: string;
    };
  };
}

interface CreateVariables {
  input: {
    skillId: string;
    specialtyId: string;
    priority: number;
  };
}

const DELETE_SKILL_SPECIALTY_PRIORITIZATION = gql`
  mutation DeleteSkillSpecialtyPrioritization(
    $skillId: UUID4!
    $specialtyId: UUID4!
  ) {
    deleteSkillSpecialtyPrioritization(
      skillId: $skillId
      specialtyId: $specialtyId
    ) {
      errors {
        key
        message
      }
      success
    }
  }
`;

interface DeleteData {
  deleteSkillSpecialtyPrioritization: {
    errors?: InputError[];
    success?: boolean;
  };
}

interface DeleteVariables {
  skillId: string;
  specialtyId: string;
}

interface FormValues {
  specialtyId: string;
  priority: number;
}

interface SkillSpecialtyPrioritiesProps {
  skillId: string;
}

export const SkillSpecialtyPriorities: FC<SkillSpecialtyPrioritiesProps> = (
  props
) => {
  const { skillId } = props;

  const { loading, data } = useQuery<Data, Variables>(
    SKILL_SPECIALTY_PRIORITIZATIONS,
    { variables: { skillId } }
  );

  const [addPriority] = useMutation<CreateData, CreateVariables>(
    ADD_SKILL_SPECIALTY_PRIORITIZATION
  );
  const [deletePriority] = useMutation<DeleteData, DeleteVariables>(
    DELETE_SKILL_SPECIALTY_PRIORITIZATION
  );
  const toast = useToast();

  const doDeletePriority = useCallback(
    (specialtyId: string) => {
      return deletePriority({
        variables: { skillId, specialtyId },
        refetchQueries: [
          { query: SKILL_SPECIALTY_PRIORITIZATIONS, variables: { skillId } },
        ],
      }).then((resp) => {
        if (resp.data?.deleteSkillSpecialtyPrioritization.errors) {
          window.alert(
            resp.data.deleteSkillSpecialtyPrioritization.errors
              .map((e) => e.message)
              .join(", ")
          );
        } else if (resp.data?.deleteSkillSpecialtyPrioritization.success) {
          // it worked.
          toast.success("Specialty priority removed.");
        }
      });
    },
    [skillId, deletePriority, toast]
  );

  return (
    <div className="SkillSpecialtyPriorities p-4">
      <div className="_skill-info pb-8">
        <h2 className="text-xl font-semibold">Skill: {data?.skill.name}</h2>
        <p>{data?.skill.externalSystemName}</p>
        <p className="text-xs italic text-gray-200">ID: {data?.skill.id}</p>
      </div>
      <div className="flex">
        <div className="_priority-list flex-1 -mx-2">
          <div className="mx-2">
            <h3 className="mb-4 text-lg font-bold text-gray-900">
              Specialty Priorities
            </h3>
            {!loading &&
            data?.skill.skillSpecialtyPrioritizations.length === 0 ? (
              <NoResults icon="stethoscope" text="No Specialty Priorities" />
            ) : null}
            <ul>
              {data?.skill.skillSpecialtyPrioritizations.map((sp) => (
                <li key={sp.id}>
                  <div className="flex items-center justify-between p-2 mb-2 bg-gray-100 border border-gray-200 rounded-lg shadow">
                    <div className="flex items-center">
                      <div className="text-center">
                        <p className="text-xs">Priority</p>
                        <p className="text-3xl">{sp.priority}</p>
                      </div>
                      <div className="ml-4">
                        <p className="text-xl font-bold">
                          <span className="mr-2 text-gray-600">
                            <FAIcon icon="stethoscope" />
                          </span>
                          {sp.specialty.name}
                        </p>
                        <p className="text-sm">
                          {sp.specialty.providerCount} Providers{" "}
                          {sp.specialty.providerCount === 0 ? (
                            <span className="ml-2 text-yellow-600">
                              <Tooltip
                                tip={
                                  <div style={{ width: 100 }}>
                                    Requests can't be scheduled unless there are
                                    providers with this specialty.
                                  </div>
                                }
                              >
                                <FAIcon icon="exclamation-triangle" />
                              </Tooltip>
                            </span>
                          ) : null}
                        </p>
                      </div>
                    </div>
                    <Button
                      type="button"
                      kind="tertiary"
                      color="red"
                      size="sm"
                      className="border"
                      onClick={() => doDeletePriority(sp.specialty.id)}
                    >
                      <FAIcon icon="trash-alt" />
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="_add-priority-form flex-1 mx-2">
          <div className="p-4 bg-gray-100 border border-gray-200 rounded-lg shadow-2xl">
            <h3 className="mb-4 text-lg font-bold text-gray-900">
              Add Specialty Priority
            </h3>
            <Formik<FormValues>
              initialValues={{
                specialtyId: "",
                priority: 1,
              }}
              validationSchema={Yup.object().shape({
                specialtyId: Yup.string().required("Required"),
                priority: Yup.number().required("Required"),
              })}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });
                const input = {
                  ...values,
                  skillId,
                };
                addPriority({
                  variables: { input },
                  refetchQueries: [
                    {
                      query: SKILL_SPECIALTY_PRIORITIZATIONS,
                      variables: { skillId },
                    },
                  ],
                }).then((resp) => {
                  if (resp.data?.createSkillSpecialtyPrioritization.errors) {
                    setStatus({
                      errors:
                        resp.data.createSkillSpecialtyPrioritization.errors,
                    });
                  } else if (
                    resp.data?.createSkillSpecialtyPrioritization
                      .skillSpecialtyPrioritization
                  ) {
                    // it worked.
                    toast.success("Specialty Priority Added!");
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <HorizontalSelectField
                    name="specialtyId"
                    label="Specialty"
                    options={
                      data?.specialties.map((s) => ({
                        value: s.id,
                        label: `${s.name} (${s.providerCount} Providers)`,
                      })) || []
                    }
                    isLoading={loading}
                  />
                  <div className="mt-3">
                    <HorizontalNumberField name="priority" label="Priority" />
                  </div>
                  <div className="mt-6 text-center">
                    <Button
                      type="submit"
                      kind="primary"
                      color="gold"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
