import { FC } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Modal, ModalHeader } from "components/Modal";
import { useToast } from "layouts/PortalLayout/Toast";
import { CASE_PROGRAMS } from "../CasePrograms";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

const CREATE_CASE_PROGRAM = gql`
  mutation CreateCaseProgram($input: CaseProgramInput!) {
    createCaseProgram(input: $input) {
      errors {
        key
        message
      }
      caseProgram {
        id
        name
        modalities {
          id
          name
        }
        caseProgramSkillPrioritizations {
          id
        }
      }
    }
  }
`;

interface MutationData {
  createCaseProgram: {
    errors?: InputError[];
    caseProgram?: {
      id: string;
      name: string;
      modalities: {
        id: string;
        name: string;
      }[];
      caseProgramSkillPrioritizations: {
        id: string;
      }[];
    };
  };
}

/**
 * NewCaseProgramModal.
 */

interface NewCaseProgramModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const NewCaseProgramModal: FC<NewCaseProgramModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const toast = useToast();

  const [createCaseProgram] = useMutation<MutationData>(CREATE_CASE_PROGRAM);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="plus" title="Create Case Program" onClose={onClose} />
      <div className="p-4">
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            createCaseProgram({
              variables: { input: values },
              refetchQueries: [{ query: CASE_PROGRAMS }],
            }).then((resp) => {
              if (resp?.data?.createCaseProgram.errors) {
                setStatus({
                  errors: resp.data.createCaseProgram.errors,
                });
              } else {
                toast.success(`Added Case Program - ${values.name}`);
                onClose();
              }
              setSubmitting(false);
            });
          }}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField name="name" label="Name" />

              <div className="mt-6 text-center">
                <Button
                  type="submit"
                  kind="primary"
                  color="gold"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Create Case Program
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
