import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddProviderForm } from "./AddProviderForm";

interface AddProviderModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AddProviderModal: FC<AddProviderModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="_AddProviderModal"
    >
      <ModalHeader icon="user-md" title="Add Provider" onClose={onClose} />

      <div className="p-4">
        <AddProviderForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
