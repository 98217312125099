import React from "react";
import "./Tooltip.css";

type TooltipProps = {
  tip: string | JSX.Element;
  children: JSX.Element;
  color?: AppColor;
  onMouseLeave?: () => void;
  style?: React.CSSProperties;
};

const Tooltip: React.FC<TooltipProps> = ({
  tip,
  children,
  onMouseLeave,
  color = "primary",
  style
}) => {
  return (
    <span className="Tooltip" onMouseLeave={onMouseLeave}>
      {children}
      <span
        className={`Tooltip__tip-container Tooltip__tip-container--${color}`}
        style={style}
      >
        <span className="Tooltip__tip-inner">
          <span>{tip}</span>
          <span className="Tooltip__tip__back-triangle" />
          <span className="Tooltip__tip__front-triangle" />
        </span>
      </span>
    </span>
  );
};

export { Tooltip };
