type AuthEvent = "UNAUTHENTICATED";

export enum AuthEvents {
  Unauthenticated = "UNAUTHENTICATED"
}

interface Handler {
  (topic: AuthEvent): void;
}

interface Subscriber {
  token: string;
  func: Handler;
}

interface Topics {
  [x: string]: {
    syncSubscribers: Subscriber[];
    asyncSubscribers: Subscriber[];
  };
}

/**
 * PubSub to publish and subscribe to authentication related events.
 * Specifically, this was designed to be a react-agnostic way for
 * an ApolloClient link to publish an event when the server sends
 * a response indicating the user is not authenticated, such as the
 * case when a token expires. A react component, such as AuthContext,
 * can then respond to the event by forgetting the saved token, which
 * then application components like a PrivateRoute can then react to
 * by redirecting to the sign in page.
 *
 * Usage:
```
// Create a pubsub instance (e.g. in app bootstrap file):
const pubsub = new AuthPubSub();

// Subscribe to an event, with synchronous handler execution:
const sub = pubsub.syncSubscribe("UNAUTHENTICATED", () => console.log("User is unauth'd"));

// Subscribe to an event, with async handler execution:
pubsub.asyncSubscribe("UNAUTHENTICATED", () => console.log("User is unauth'd"));

// Unsubscribe:
pubsub.unsubscribe(sub);

// Publish event (e.g. in an apollo error link):
pubsub.publish("UNAUTHENTICATED");
```
 */
class AuthPubSub {
  topics: Topics = {};
  subUid = -1;

  syncSubscribe = (topic: AuthEvent, func: Handler): string => {
    this.ensureTopicSet(topic);
    const token = (++this.subUid).toString();
    this.topics[topic].syncSubscribers.push({
      token,
      func
    });
    return token;
  };

  asyncSubscribe = (topic: AuthEvent, func: Handler): string => {
    this.ensureTopicSet(topic);
    const token = (++this.subUid).toString();
    this.topics[topic].asyncSubscribers.push({
      token,
      func
    });
    return token;
  };

  publish = (topic: AuthEvent) => {
    if (!this.topics[topic]) {
      return false;
    }
    this.topics[topic].syncSubscribers.forEach(subscriber => {
      subscriber.func(topic);
    });
    setTimeout(() => {
      this.topics[topic].asyncSubscribers.forEach(subscriber => {
        subscriber.func(topic);
      });
    }, 0);
    return true;
  };

  unsubscribe = (token: string): boolean => {
    for (let topic in this.topics) {
      if (this.topics[topic]) {
        // Check the syncSubscribers
        this.topics[topic].syncSubscribers = this.topics[
          topic
        ].syncSubscribers.filter(subscriber => subscriber.token !== token);
        // Check the asyncSubscribers
        this.topics[topic].asyncSubscribers = this.topics[
          topic
        ].syncSubscribers.filter(subscriber => subscriber.token !== token);
      }
    }
    return false;
  };

  private ensureTopicSet = (topic: AuthEvent) => {
    if (!this.topics[topic]) {
      this.topics[topic] = {
        syncSubscribers: [],
        asyncSubscribers: []
      };
    }
  };
}

export { AuthPubSub };
