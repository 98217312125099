import { FC } from "react";
import { FAIcon } from "components/FAIcon";
import { Tooltip } from "components/Tooltip";
import { CommentEvent } from "./CommentEvent";
import { hMmA, mmDdYy } from "lib/dateFormatters";

type EventUser = {
  __typename: "User";
  id: string;
  firstName: string;
  lastName: string;
  organization: {
    id: string;
    name: string;
  };
};

type EventAgent = {
  __typename: "Agent";
  id: string;
  firstName: string;
  lastName?: string;
  email?: string;
};

enum EventType {
  Scheduled = "SCHEDULED",
  AppointmentCancelled = "APPOINTMENT_CANCELLED",
  AppointmentMissed = "APPOINTMENT_MISSED",
  Comment = "COMMENT",
  AppointmentCompletionToggled = "APPOINTMENT_COMPLETION_TOGGLED",
}

interface BaseAppointmentRequestEvent {
  id: string;
  type: EventType;
  rollupId: string;
  insertedAt: string;
  user?: EventUser;
  agent?: EventAgent;
}

interface ScheduledEvent extends BaseAppointmentRequestEvent {
  type: EventType.Scheduled;
  data: {
    eventType: EventType.Scheduled;
    scheduledWith: string;
    appointmentTime: string;
  };
}

interface AppointmentCancelledEvent extends BaseAppointmentRequestEvent {
  type: EventType.AppointmentCancelled;
  data: {
    eventType: EventType.AppointmentCancelled;
    wasScheduledWith: string;
    cancellationReason: string;
    requiresReschedule: boolean;
    appointmentId: string;
  };
}

interface AppointmentMissedEvent extends BaseAppointmentRequestEvent {
  type: EventType.AppointmentMissed;
  data: {
    eventType: EventType.AppointmentMissed;
    wasScheduledWith: string;
    missedReason: string;
    requiresReschedule: boolean;
    appointmentId: string;
  };
}

export interface CommentEventModel extends BaseAppointmentRequestEvent {
  type: EventType.Comment;
  data: {
    eventType: EventType.Comment;
    comment: string;
  };
}

interface AppointmentCompletionToggledEvent
  extends BaseAppointmentRequestEvent {
  type: EventType.AppointmentCompletionToggled;
  data: {
    eventType: EventType.AppointmentCompletionToggled;
    completed: boolean;
  };
}

export type AppointmentRequestEventType =
  | ScheduledEvent
  | AppointmentCancelledEvent
  | AppointmentMissedEvent
  | CommentEventModel
  | AppointmentCompletionToggledEvent;

type AppointmentRequestEventProps = {
  event: AppointmentRequestEventType;
};

export const AppointmentRequestEvent: FC<AppointmentRequestEventProps> = (
  props
) => {
  const { event } = props;

  switch (event.type) {
    case EventType.Scheduled: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="cols">
            <div
              className="col is-vcentered"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {formatTimestamp(event.insertedAt)}
              </span>
            </div>
            <div className="col is-vcentered" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-check"]} />{" "}
                {event.user ? (
                  <Tooltip tip={event.user.organization.name}>
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <Tooltip tip="External User">
                    <strong>
                      {event.agent?.firstName} {event.agent?.lastName}
                    </strong>
                  </Tooltip>
                )}{" "}
                scheduled this request with{" "}
                <strong>{event.data.scheduledWith}</strong>.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.AppointmentCancelled: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="cols">
            <div
              className="col is-vcentered"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {formatTimestamp(event.insertedAt)}
              </span>
            </div>
            <div className="col is-vcentered" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-times"]} />{" "}
                {event.user ? (
                  <Tooltip tip={event.user.organization.name}>
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <Tooltip tip="External User">
                    <strong>
                      {event.agent?.firstName} {event.agent?.lastName}
                    </strong>
                  </Tooltip>
                )}{" "}
                cancelled an appointment with{" "}
                <strong>{event.data.wasScheduledWith}</strong>.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.AppointmentMissed: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="cols">
            <div
              className="col is-vcentered"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {formatTimestamp(event.insertedAt)}
              </span>
            </div>
            <div className="col is-vcentered" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-times"]} />{" "}
                {event.user ? (
                  <Tooltip tip={event.user.organization.name}>
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <Tooltip tip="External User">
                    <strong>
                      {event.agent?.firstName} {event.agent?.lastName}
                    </strong>
                  </Tooltip>
                )}{" "}
                marked an appointment with{" "}
                <strong>{event.data.wasScheduledWith}</strong> as missed.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.Comment: {
      return (
        <div style={{ margin: "0.75rem 0" }}>
          <CommentEvent event={event} />
        </div>
      );
    }
    case EventType.AppointmentCompletionToggled: {
      if (event.data.completed) {
        return (
          <div
            className="AppointmentRequestEvent"
            style={{ margin: "0.75rem 0" }}
          >
            <div className="cols">
              <div
                className="col is-vcentered"
                style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
              >
                <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                  {formatTimestamp(event.insertedAt)}
                </span>
              </div>
              <div
                className="col is-vcentered"
                style={{ fontSize: "0.875rem" }}
              >
                <p>
                  <FAIcon icon={["far", "calendar-check"]} />{" "}
                  {event.user ? (
                    <Tooltip tip={event.user.organization.name}>
                      <strong>
                        {event.user.firstName} {event.user.lastName}
                      </strong>
                    </Tooltip>
                  ) : (
                    <Tooltip tip="External User">
                      <strong>
                        {event.agent?.firstName} {event.agent?.lastName}
                      </strong>
                    </Tooltip>
                  )}{" "}
                  completed an appointment.
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="AppointmentRequestEvent"
            style={{ margin: "0.75rem 0" }}
          >
            <div className="cols">
              <div
                className="col is-vcentered"
                style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
              >
                <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                  {formatTimestamp(event.insertedAt)}
                </span>
              </div>
              <div
                className="col is-vcentered"
                style={{ fontSize: "0.875rem" }}
              >
                <p>
                  <FAIcon icon="calendar-alt" />{" "}
                  {event.user ? (
                    <Tooltip tip={event.user.organization.name}>
                      <strong>
                        {event.user.firstName} {event.user.lastName}
                      </strong>
                    </Tooltip>
                  ) : (
                    <Tooltip tip="External User">
                      <strong>
                        {event.agent?.firstName} {event.agent?.lastName}
                      </strong>
                    </Tooltip>
                  )}{" "}
                  marked an appointment as not completed.
                </p>
              </div>
            </div>
          </div>
        );
      }
    }
    default:
      return null;
  }
};

function formatTimestamp(timestamp: string): string {
  return `${mmDdYy(timestamp)} - ${hMmA(timestamp)}`;
}
