import React, {
  FC,
  InputHTMLAttributes,
  ChangeEvent,
  HTMLAttributes,
  CSSProperties,
} from "react";
import { ErrorMessage, useField } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";

/**
 * InputTextArea.
 */

interface InputTextAreaProps {
  value?: string;
  inputProps?: InputHTMLAttributes<HTMLTextAreaElement>;
  className?: string;
  onChange?(newValue: string): void;
  onBlur?(e: any): void;
  style?: CSSProperties;
  minHeight?: number;
}

export const InputTextArea: FC<InputTextAreaProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    inputProps = {},
    className = "",
    style = {},
    minHeight = 0,
  } = props;

  function handleChange(evt: ChangeEvent<HTMLTextAreaElement>) {
    if (onChange) onChange(evt.target.value);
  }

  /**
   * NB: `className` can be overridden by `inputProps`.
   */

  return (
    <div className="control" style={style}>
      <textarea
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${className}`}
        style={{ minHeight }}
        {...inputProps}
      />
    </div>
  );
};

/**
 * TextAreaInput.
 */

interface TextAreaInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  inputProps?: HTMLAttributes<HTMLTextAreaElement>;
  minHeight?: number;
}

export const TextAreaInput: FC<TextAreaInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    autoFocus = false,
    className = "",
    inputProps = {},
    minHeight,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputTextArea
        inputProps={{
          id: id || name,
          placeholder,
          name,
          autoFocus,
          ...inputProps,
        }}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        minHeight={minHeight}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * TextAreaField.
 */

interface TextAreaFieldProps extends TextAreaInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const TextAreaField: FC<TextAreaFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <TextAreaInput {...rest} placeholder={rest.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalTextAreaField: FC<TextAreaFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <TextAreaInput {...rest} placeholder={rest.placeholder || label} />
    </HorizontalField>
  );
};
