import { FC, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { TimeRange, AppointmentSubset } from "../types";
import { CircleIcon } from "components/CircleIcon";
import { HorizontalBarChart } from "../charts/HorizontalBarChart";
import { NoResults } from "components/NoResults";
import { FAIcon } from "components/FAIcon";
import { iso8601Date } from "lib/dateFormatters";
// import "./InternalUserProduction.css";

const INTERNAL_USER_PRODUCTION = gql`
  query InternalUserProduction(
    $timeRange: TimeRangeInput!
    $filter: AppointmentQueryFilter!
  ) {
    analyticsInternalUserProduction(timeRange: $timeRange, filter: $filter) {
      data {
        user {
          id
          firstName
          lastName
          email
          isClinical
        }
        totalCount
        scheduledCount
        cancelledCount
        missedCount
        completedCount
      }
    }
  }
`;

interface Data {
  analyticsInternalUserProduction: {
    data: Datum[];
  };
}

interface Datum {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isClinical: boolean;
  };
  totalCount: number;
  scheduledCount: number;
  cancelledCount: number;
  missedCount: number;
  completedCount: number;
}

function transform(data: Datum[]) {
  return data.map((d) => ({
    "Total Activity": d.totalCount,
    Scheduled: d.scheduledCount,
    Cancelled: d.cancelledCount,
    Missed: d.missedCount,
    Completed: d.completedCount,
    userName: `${d.user.firstName} ${d.user.lastName}`,
  }));
}

interface InternalUserProductionProps {
  timeRange: TimeRange;
}

export const InternalUserProduction: FC<InternalUserProductionProps> = (
  props
) => {
  const { timeRange } = props;
  /* eslint-disable-next-line */
  const [filter, _setFilter] = useState({
    subset: AppointmentSubset.All,
  });

  const { data, loading, error } = useQuery<Data>(INTERNAL_USER_PRODUCTION, {
    variables: { timeRange, filter },
  });

  const allowDownload =
    !loading &&
    !error &&
    data?.analyticsInternalUserProduction.data.length !== 0;

  const csvData = useMemo(
    () => toCsvData(data?.analyticsInternalUserProduction.data),
    [data]
  );

  const csvFilename = `internal_user_production_${iso8601Date(
    timeRange.start
  )}_${iso8601Date(timeRange.finish)}.csv`;

  return (
    <div className="InternalUserProduction" style={{ padding: "1.5rem" }}>
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-bar" />
            <h4 className="ml-3 text-xl font-semibold text-gray-800">
              Internal User Production
            </h4>
          </div>

          {allowDownload ? (
            <CSVLink
              data={csvData}
              className="focus:outline-none hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md"
              filename={csvFilename}
            >
              Export CSV{" "}
              <span className="ml-2">
                <FAIcon icon="file-download" />
              </span>
            </CSVLink>
          ) : (
            <div />
          )}
        </div>
        <p className="p-6 text-sm text-left">
          <b>Note:</b> Data shown counts distinct{" "}
          <i>
            <u>events/interactions</u>
          </i>{" "}
          that occurred during the selected date range, rather than{" "}
          <i>
            <u>appointments or P2P requests</u>
          </i>{" "}
          that occurred during the date range.
        </p>
        <div className="pt-2">
          {loading ? (
            <p>Loading...</p>
          ) : error || !data?.analyticsInternalUserProduction ? (
            <div>
              <h1>Failed to Load</h1>
            </div>
          ) : data.analyticsInternalUserProduction.data.length === 0 ? (
            <NoResults
              icon="chart-bar"
              text="No data for selected date range"
            />
          ) : (
            <div style={{ textAlign: "left", padding: "2rem" }}>
              <HorizontalBarChart
                data={transform(data.analyticsInternalUserProduction.data)}
                bars={[
                  {
                    dataKey: "Scheduled",
                    fill: "#1f77b4",
                  },
                  {
                    dataKey: "Completed",
                    fill: "#2ca02c",
                  },
                  {
                    dataKey: "Cancelled",
                    fill: "#ff7f0e",
                  },
                  {
                    dataKey: "Missed",
                    fill: "#d62728",
                  },
                ]}
                yValKey="userName"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function toCsvData(data?: Datum[]): Array<Array<string>> {
  if (!data) return [[]];

  const header = [
    "first_name",
    "last_name",
    "email",
    "clinical_group",
    "scheduled",
    "completed",
    "cancelled",
    "missed",
    "total",
  ];

  return [
    header,
    ...data.map((d) => [
      d.user.firstName,
      d.user.lastName,
      d.user.email,
      d.user.isClinical ? "Clinical" : "Non-Clinical",
      d.scheduledCount.toString(),
      d.completedCount.toString(),
      d.cancelledCount.toString(),
      d.missedCount.toString(),
      d.totalCount.toString(),
    ]),
  ];
}
