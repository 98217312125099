import React from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PreferredDays.css";

export type Day = "Mon" | "Tues" | "Wed" | "Thurs" | "Fri";

export const defaultDays: Day[] = ["Mon", "Tues", "Wed", "Thurs", "Fri"];

type PreferredDaysProps = {
  id?: string;
  days: Day[];
  selectedDays: Day[];
  onChange?: (selectedDays: Day[]) => void;
  showInstructions?: boolean;
};

class PreferredDays extends React.Component<PreferredDaysProps> {
  static defaultProps = {
    days: defaultDays,
  };

  handleChange = (day: Day) => {
    const { onChange, selectedDays } = this.props;
    if (onChange) {
      const values = selectedDays.includes(day)
        ? selectedDays.filter((d: string) => d !== day)
        : selectedDays.concat(day);
      onChange(values);
    }
  };
  render() {
    const { id, days, selectedDays, showInstructions = false } = this.props;
    return (
      <div id={id} className="PreferredDays">
        <div
          className="PreferredDays__columns"
          style={{ position: "relative" }}
        >
          {days.map((day) => (
            <div
              className="PreferredDays__column text-center"
              key={day}
              onClick={() => this.handleChange(day)}
            >
              <div className="PreferredDays__cell PreferredDays__day_header">
                {day}
              </div>
              <div
                className={cx("PreferredDays__cell PreferredDays__day_body", {
                  PreferredDays__selected: selectedDays.includes(day),
                  PreferredDays__unselected: !selectedDays.includes(day),
                })}
              >
                <FontAwesomeIcon
                  icon={selectedDays.includes(day) ? "check" : "times"}
                />
              </div>
            </div>
          ))}
          {showInstructions ? (
            <div className="PreferredDays__instructions_container">
              <div className="PreferredDays__instructions">
                <span style={{ marginRight: "0.75em" }}>
                  <FontAwesomeIcon icon="arrow-left" />
                </span>
                Click to edit
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export { PreferredDays };
