import { FC, useState, useCallback } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { FAIcon } from "components/FAIcon";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { firstSecondThird } from "lib/firstSecondThird";
import { CircleIcon } from "components/CircleIcon";
import { NewCaseProgramModal } from "./NewCaseProgramModal";
import { EditCaseProgramModal } from "./EditCaseProgramModal";
import "./CasePrograms.css";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";

export const CASE_PROGRAMS = gql`
  {
    casePrograms {
      id
      name
      modalities {
        id
        name
      }
      caseProgramSkillPrioritizations {
        id
        priority
        skill {
          id
          name
          externalSystemName
        }
      }
    }
  }
`;

interface Data {
  casePrograms: CaseProgram[];
}

interface CaseProgram {
  id: string;
  name: string;
  modalities: {
    id: string;
    name: string;
  }[];
  caseProgramSkillPrioritizations: {
    id: string;
    priority: number;
    skill: {
      id: string;
      name: string;
      externalSystemName: string;
    };
  }[];
}

interface CaseProgramsProps {}

type ActiveModal = { type: "NEW" } | { type: "EDIT"; caseProgramId: string };

export const CasePrograms: FC<CaseProgramsProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);
  const openNew = useCallback(() => setActiveModal({ type: "NEW" }), []);
  const openEdit = useCallback(
    (caseProgramId: string) => setActiveModal({ type: "EDIT", caseProgramId }),
    []
  );

  const { loading, data, error } = useQuery<Data>(CASE_PROGRAMS);

  return (
    <>
      <NavTitle title="Settings » Case Programs" />

      <NewCaseProgramModal
        isOpen={activeModal?.type === "NEW"}
        onClose={closeModal}
      />

      {activeModal?.type === "EDIT" ? (
        <EditCaseProgramModal
          isOpen
          id={activeModal.caseProgramId}
          onClose={closeModal}
        />
      ) : null}

      <div className="bg-white rounded-lg shadow-lg">
        <GradientHeader
          title="Case Programs"
          subtitle="Manage your organization's case programs"
        />
        <div className="p-4">
          <div className="flex justify-end pb-4">
            <Button type="button" kind="primary" color="gold" onClick={openNew}>
              <span className="mr-2">
                <FAIcon icon="plus-circle" />
              </span>
              Add Case Program
            </Button>
          </div>
          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data ? (
            <div style={{ padding: "1.5rem" }}>
              <h1>Failed to Load</h1>
            </div>
          ) : data.casePrograms.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "2rem",
                color: "#ccc",
                padding: "2rem",
              }}
            >
              <h1>No Case Programs Created Yet.</h1>
            </div>
          ) : (
            <div className="CasePrograms__grid">
              {data.casePrograms.map((caseProgram: any) => (
                <div
                  className="CasePrograms__card p-4 mb-4 bg-white rounded-lg shadow-lg"
                  key={caseProgram.id}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <CircleIcon icon="stethoscope" />
                      <p className="ml-3 text-xl font-semibold">
                        {caseProgram.name}
                      </p>
                    </div>
                    <Button
                      type="button"
                      kind="tertiary"
                      className="border rounded-full"
                      onClick={() => openEdit(caseProgram.id)}
                    >
                      <FAIcon icon="pencil-alt" />
                    </Button>
                  </div>
                  <div className="gap-x-3 grid grid-cols-3 pt-4">
                    <div>
                      <label className="block pt-1 pb-3 text-base font-semibold">
                        Modalities
                      </label>
                      <ul style={{ fontSize: "0.875rem" }}>
                        {caseProgram.modalities.map((modality: any) => (
                          <li key={modality.id}>{modality.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-span-2">
                      <label className="block pt-1 pb-3 text-base font-semibold">
                        Skill Prioritizations
                      </label>
                      <table
                        className="is-fullwidth table"
                        style={{ fontSize: "0.875rem" }}
                      >
                        <tbody>
                          {caseProgram.caseProgramSkillPrioritizations
                            .concat()
                            .sort((a: any, b: any) =>
                              a.priority < b.priority ? -1 : 1
                            )
                            .map((cpsp: any) => (
                              <tr key={cpsp.id} className="border-t">
                                <td className="py-1 pr-2">
                                  {cpsp.skill.name} (
                                  {cpsp.skill.externalSystemName})
                                </td>
                                <td>{firstSecondThird(cpsp.priority)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
