import { FC } from "react";
import { CopyText } from "../CopyText";

interface RefNumProps {
  className?: string;
  label?: string;
  refNum: string;
}

export const RefNum: FC<RefNumProps> = (props) => {
  const { className, label = "ref#", refNum } = props;

  const cn = [`_RefNum text-lg font-mono text-gray-400`, className]
    .filter(Boolean)
    .join(" ");

  return (
    <h5 className={cn}>
      {label}: <CopyText text={refNum} />
    </h5>
  );
};
