import dateFnsFormat from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import getDay from "date-fns/getDay";

const shortDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const longDayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function iso8601Date(dateOrIsoString: Date | string): string {
  return format(dateOrIsoString, "yyyy-MM-dd");
}

export function mmDd(dateOrIsoString: Date | string): string {
  return format(dateOrIsoString, "M/d");
}

export function mmDdYyyy(dateOrIsoString: Date | string): string {
  return format(dateOrIsoString, "M/d/yyyy");
}

export function mmDdYy(dateOrIsoString: Date | string): string {
  return format(dateOrIsoString, "M/d/yy");
}

export function hMmA(dateOrIsoString: Date | string): string {
  return format(dateOrIsoString, "h:mm a");
}

export function ddd(dateOrIsoString: Date | string): string {
  const date = toDate(dateOrIsoString);
  return shortDayNames[getDay(date)];
}

export function dddd(dateOrIsoString: Date | string): string {
  const date = toDate(dateOrIsoString);
  return longDayNames[getDay(date)];
}

export function distanceInWords(dateOrIsoString: Date | string): string {
  const now = new Date();
  const then = toDate(dateOrIsoString);
  return formatDistance(then, now);
}

export function format(
  dateOrIsoString: Date | string,
  template: string
): string {
  const date = toDate(dateOrIsoString);
  return dateFnsFormat(date, template);
}

function toDate(dateOrIsoString: Date | string): Date {
  return typeof dateOrIsoString === "string"
    ? parseISO(dateOrIsoString)
    : dateOrIsoString;
}
