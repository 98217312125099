import React from "react";

const DropdownTrigger: React.StatelessComponent<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => (
  <div style={{ position: "relative", zIndex: 2 }}>{children}</div>
);

DropdownTrigger.displayName = "DropdownTrigger";

export { DropdownTrigger };
