import { FC } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Modal, ModalHeader } from "components/Modal";
import { useToast } from "layouts/PortalLayout/Toast";
import { HEALTH_PLANS } from "../HealthPlans";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalToggleSwitchField } from "components/formik/ToggleSwitchField";
import { HorizontalSelectField } from "components/formik/SelectField";
import { Button } from "components/Button";

const EXTERNAL_SYSTEM_NAMES = gql`
  {
    externalSystemNames
  }
`;

interface Data {
  externalSystemNames: string[];
}

const CREATE_HEALTH_PLAN = gql`
  mutation CreateHealthPlan($input: HealthPlanInput!) {
    createHealthPlan(input: $input) {
      errors {
        key
        message
      }
      healthPlan {
        id
        name
        evicoreId
        externalSystemName
      }
    }
  }
`;

interface MutationData {
  createHealthPlan: {
    errors?: InputError[];
    healthPlan?: {
      id: string;
      name: string;
    };
  };
}

interface NewHealthPlanModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const NewHealthPlanModal: FC<NewHealthPlanModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const toast = useToast();
  const { data, loading, error } = useQuery<Data>(EXTERNAL_SYSTEM_NAMES);
  const [createHealthPlan] = useMutation<MutationData>(CREATE_HEALTH_PLAN);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        title="New Health Plan"
        icon="stethoscope"
        onClose={onClose}
      />
      <div className="p-4">
        {loading ? (
          <p>Loading...</p>
        ) : error || !(data && data.externalSystemNames) ? (
          <p>Failed to load</p>
        ) : (
          <Formik
            initialValues={{
              name: "",
              evicoreId: "",
              externalSystemName: "",
              active: true,
              appealP2pCompletionEmailEnabled: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              evicoreId: Yup.string().required("Required"),
              externalSystemName: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              createHealthPlan({
                variables: { input: values },
                refetchQueries: [{ query: HEALTH_PLANS }],
              }).then((resp) => {
                if (resp?.data?.createHealthPlan.errors) {
                  setStatus({
                    errors: resp.data.createHealthPlan.errors,
                  });
                } else {
                  toast.success("Created health plan!");
                  onClose();
                }
                setSubmitting(false);
              });
            }}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <HorizontalTextField name="name" label="Name" />
                <div className="mt-3">
                  <HorizontalTextField name="evicoreId" label="eviCore ID" />
                </div>
                <div className="mt-3">
                  <HorizontalSelectField
                    name="externalSystemName"
                    label="System Name"
                    options={data.externalSystemNames.map((name) => ({
                      value: name,
                      label: name,
                    }))}
                  />
                </div>
                <div className="mt-3">
                  <HorizontalToggleSwitchField
                    name="active"
                    label="Active or Inactive"
                    toggleSwitchProps={{
                      showLabel: true,
                      checkedLabel: "Active",
                      uncheckedLabel: "Inactive",
                    }}
                  />
                </div>
                <div className="mt-3">
                  <HorizontalToggleSwitchField
                    name="appealP2pCompletionEmailEnabled"
                    label="Send email upon Appeal P2P Completion"
                    toggleSwitchProps={{
                      showLabel: true,
                      checkedLabel: "Enabled",
                      uncheckedLabel: "Disabled",
                    }}
                  />
                </div>

                <div className="mt-6 text-center">
                  <Button
                    type="submit"
                    kind="primary"
                    color="gold"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Create Health Plan
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
