import { FC, useState, useCallback } from "react";
import cx from "classnames";
import { isAfter, parseISO } from "date-fns";
import { FAIcon } from "components/FAIcon";
import {
  AppointmentModel,
  AppointmentRequestModel,
} from "../AppointmentRequestShowPage";
import { ViewAppointmentModal } from "./ViewAppointmentModal";
import { CancelAppointmentModal } from "./CancelAppointmentModal";
import { MissAppointmentModal } from "./MissAppointmentModal";
import { RescheduleAppointmentModal } from "./RescheduleAppointmentModal";
import "./Appointments.css";

export enum AppointmentStatus {
  Unscheduled = "UNSCHEDULED",
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Missed = "MISSED",
  Cancelled = "CANCELLED",
  RequiresReschedule = "REQUIRES_RESCHEDULE",
}

export function getAppointmentStatus(appointment: AppointmentModel) {
  if (appointment.cancelled) {
    return AppointmentStatus.Cancelled;
  } else if (appointment.missed) {
    return AppointmentStatus.Missed;
  } else if (appointment.completed) {
    return AppointmentStatus.Completed;
  } else {
    return AppointmentStatus.Scheduled;
  }
}

function appointmentStatusLabel(appointment: AppointmentModel) {
  if (appointment.cancelled) {
    return "Cancelled";
  } else if (appointment.missed) {
    return "Missed";
  } else if (appointment.completed) {
    return "Completed";
  } else {
    return "Scheduled";
  }
}

/**
 * AppointmentStatusIcon.
 */

interface AppointmentStatusIconProps {
  appointment: AppointmentModel;
}

const AppointmentStatusIcon: FC<AppointmentStatusIconProps> = (props) => {
  const { appointment } = props;
  if (appointment.cancelled || appointment.missed) {
    return <FAIcon icon={["far", "calendar-times"]} />;
  } else if (appointment.completed) {
    return <FAIcon icon={["far", "calendar-check"]} />;
  } else {
    return <FAIcon icon={["far", "calendar-alt"]} />;
  }
};

/**
 * Appointment.
 */

interface AppointmentProps {
  appointment: AppointmentModel;
  onClick(): void;
}

const Appointment: FC<AppointmentProps> = (props) => {
  const { appointment, onClick } = props;

  return (
    <div
      className={cx("Appointment", {
        "Appointment--cancelled": appointment.cancelled || appointment.missed,
        "Appointment--completed": appointment.completed,
      })}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start">
          <div className="Appointment__icon-container flex items-center justify-center mr-3">
            <AppointmentStatusIcon appointment={appointment} />
          </div>
          <div className="Appointment__info-container flex flex-col items-baseline">
            <p>
              <strong>
                <span className="mr-2">
                  <FAIcon icon={["far", "clock"]} />
                </span>
                {appointment.startDateString}
                {" - "}
                {appointment.startTimeString}
              </strong>
            </p>
            <p>
              <span className="mr-2">
                <FAIcon icon="user-md" />
              </span>
              {appointment.receivingProvider.nameWithAppellation}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-end mr-4">
          <p className="Appointment__status-label">
            {appointmentStatusLabel(appointment)}
          </p>
          {appointment.requiresReschedule ? (
            <p className="Appointment__warning-label">
              <span className="Appointment__warning-label__icon-container">
                <FAIcon icon="exclamation-triangle" />
              </span>
              Reschedule Required
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

enum ActiveModal {
  ViewAppointment = "VIEW_APPOINTMENT",
  CancelAppointment = "CANCEL_APPOINTMENT",
  MissAppointment = "MISS_APPOINTMENT",
  RescheduleAppointment = "RESCHEDULE_APPOINTMENT",
}

/**
 * Indicates whether the appointment is in the past. It's in the past once
 * `startTime + duration` is before `right now`.
 */
function appointmentIsPast(appointment: AppointmentModel) {
  const endTime = parseISO(appointment.timeRange.finish);
  const now = new Date();
  return isAfter(now, endTime);
}

/**
 * Indicates whether an appointment is expected to happen (or expected to have happened).
 * In other words, it's neither `cancelled` nor `missed`.
 */
function appointmentIsOn(appointment: AppointmentModel) {
  return !appointment.cancelled && !appointment.missed;
}

/**
 * Appointments.
 */

interface AppointmentsProps {
  appointmentRequest: AppointmentRequestModel;
  appointments: AppointmentModel[];
}

export const Appointments: FC<AppointmentsProps> = (props) => {
  const { appointmentRequest, appointments } = props;
  const [
    viewingAppointment,
    viewAppointment,
  ] = useState<AppointmentModel | null>(null);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openViewAppointment = useCallback((appointment: AppointmentModel) => {
    viewAppointment(appointment);
    setActiveModal(ActiveModal.ViewAppointment);
  }, []);

  const openCancelModal = useCallback((appointment: AppointmentModel) => {
    viewAppointment(appointment);
    setActiveModal(ActiveModal.CancelAppointment);
  }, []);

  const openMissModal = useCallback((appointment: AppointmentModel) => {
    viewAppointment(appointment);
    setActiveModal(ActiveModal.MissAppointment);
  }, []);

  const openRescheduleModal = useCallback((appointment: AppointmentModel) => {
    viewAppointment(appointment);
    setActiveModal(ActiveModal.RescheduleAppointment);
  }, []);

  const handleCloseModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const currentAppointments = appointments.filter(
    (appt) => appointmentIsOn(appt) && !appointmentIsPast(appt)
  );

  const previousAppointments = appointments.filter(
    (appt) => !appointmentIsOn(appt) || appointmentIsPast(appt)
  );

  return (
    <>
      <ViewAppointmentModal
        appointmentRequest={appointmentRequest}
        appointment={viewingAppointment}
        isOpen={
          !!viewingAppointment && activeModal === ActiveModal.ViewAppointment
        }
        onClose={handleCloseModal}
        openCancelModal={openCancelModal}
        openMissModal={openMissModal}
        openRescheduleModal={openRescheduleModal}
      />
      <CancelAppointmentModal
        appointmentRequest={appointmentRequest}
        appointment={viewingAppointment}
        isOpen={
          !!viewingAppointment && activeModal === ActiveModal.CancelAppointment
        }
        onClose={handleCloseModal}
      />
      <MissAppointmentModal
        appointmentRequest={appointmentRequest}
        appointment={viewingAppointment}
        isOpen={
          !!viewingAppointment && activeModal === ActiveModal.MissAppointment
        }
        onClose={handleCloseModal}
      />

      <RescheduleAppointmentModal
        isOpen={
          !!viewingAppointment &&
          activeModal === ActiveModal.RescheduleAppointment
        }
        appointment={viewingAppointment}
        onClose={handleCloseModal}
      />
      <div className="_Appointments">
        {currentAppointments.length > 0 && (
          <>
            <label
              className="block font-semibold text-gray-700"
              style={{ margin: "1rem 1rem 1.5rem" }}
            >
              Scheduled
            </label>
            {currentAppointments.map((appt: AppointmentModel) => (
              <Appointment
                key={appt.id}
                appointment={appt}
                onClick={() => openViewAppointment(appt)}
              />
            ))}
          </>
        )}

        {previousAppointments.length > 0 && (
          <>
            <label
              className="block mx-4 mt-5 mb-6 font-semibold text-gray-700"
              style={{ margin: "1rem 1rem 1.5rem" }}
            >
              Previous
            </label>
            {previousAppointments.map((appt: AppointmentModel) => (
              <Appointment
                key={appt.id}
                appointment={appt}
                onClick={() => openViewAppointment(appt)}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};
