import React, { createRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "components/Tooltip";
import "./CopyText.css";

type CopyTextProps = {
  text: string;
  label?: string;
};

type CopyTextState = {
  copied: boolean;
};

class CopyText extends React.Component<CopyTextProps, CopyTextState> {
  state: CopyTextState = {
    copied: false
  };

  private timeout: ReturnType<typeof setTimeout> | null = null;
  private textRef = createRef<HTMLSpanElement>();

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleCopy = () => {
    this.setState({ copied: true });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 4000);
  };

  clearMessage = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 150);
  };

  render() {
    const { label, text } = this.props;
    const { copied } = this.state;

    return (
      <Tooltip
        tip={copied ? "Copied!" : "Click to Copy"}
        onMouseLeave={this.clearMessage}
        color="purple"
        style={{ width: 110 }}
      >
        <CopyToClipboard text={text} onCopy={this.handleCopy}>
          <span className="CopyText">{label || text}</span>
        </CopyToClipboard>
      </Tooltip>
    );
  }
}

export { CopyText };
