import React from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./StepProgressBar.css";

type StepProgressBarProps = {
  stepLabels: string[];
  currentStep: number;
};

const StepProgressBar: React.SFC<StepProgressBarProps> = ({
  stepLabels,
  currentStep
}) => (
  <div className="StepProgressBar__container">
    <ol className="StepProgressBar__progress_bar">
      {stepLabels.map((step, idx) => {
        const thisStep = idx + 1; // .map zero indexes, so step 1 == idx 0
        const stepClasses = cx("StepProgressBar__step", {
          is_current: currentStep === thisStep,
          is_complete: currentStep > thisStep
        });
        return (
          <li key={step} className={stepClasses}>
            <span className="StepProgressBar__step_stone">
              <FontAwesomeIcon icon="check" />
            </span>
            <span className="StepProgressBar__step_label">
              {thisStep}. {step}
            </span>
          </li>
        );
      })}
    </ol>
  </div>
);

StepProgressBar.displayName = "StepProgressBar";

export { StepProgressBar };
