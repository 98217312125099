import { FC, useState } from "react";
import { parseISO, lightFormat } from "date-fns";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import ReactModal from "react-modal";
import { TimeRange } from "../types";
import { LineChart } from "../charts/LineChart";
import { CircleIcon } from "components/CircleIcon";
import { SystemSchedulingVolumeExportForm } from "./SystemSchedulingVolumeExportForm";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";

const SYSTEM_SCHEDULING_VOLUME = gql`
  query SystemSchedulingVolume($dateRange: InclusiveDateRangeInput!) {
    analyticsSystemSchedulingVolume(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      total
      countByCaseSystem {
        segment
        value
      }
      selfServeTotal
      selfServeCountByCaseSystem {
        segment
        value
      }
      data {
        datetime
        value
      }
    }
  }
`;

interface Data {
  analyticsSystemSchedulingVolume: {
    timeRange: {
      start: string;
      finish: string;
    };
    total: number;
    countByCaseSystem: {
      segment: string;
      value: number;
    }[];
    selfServeTotal: number;
    selfServeCountByCaseSystem: {
      segment: string;
      value: number;
    }[];
    data: {
      datetime: string;
      value: number;
    }[];
  };
}

function transform(data: { datetime: string; value: number }[]) {
  const p = data.map((d) => ({
    ...d,
    datetime: lightFormat(parseISO(d.datetime), "M/d/yy h:mm a"),
  }));
  return p;
}

type SystemSchedulingVolumeProps = {
  timeRange: TimeRange;
};

export const SystemSchedulingVolume: FC<SystemSchedulingVolumeProps> = (
  props
) => {
  const { timeRange } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const dateRange = {
    start: lightFormat(timeRange.start, "MM/dd/yyyy"),
    finish: lightFormat(timeRange.finish, "MM/dd/yyyy"),
  };
  const { data, loading, error } = useQuery<Data>(SYSTEM_SCHEDULING_VOLUME, {
    variables: { dateRange },
  });

  return (
    <div className="SystemSchedulingVolume" style={{ margin: "1.5rem" }}>
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={150}
      >
        <SystemSchedulingVolumeExportForm
          onClose={() => setModalOpen(false)}
          initialValues={{
            windowStart: dateRange.start,
            windowFinish: dateRange.finish,
          }}
        />
      </ReactModal>
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-line" />
            <h4 className="ml-3 text-xl font-semibold text-gray-800">
              System Scheduling Volume
            </h4>
          </div>
          <Button
            type="button"
            kind="tertiary"
            className="border"
            onClick={() => setModalOpen(true)}
          >
            Export CSV{" "}
            <span className="ml-2">
              <FAIcon icon="file-download" />
            </span>
          </Button>
        </div>
        <div className="section">
          {loading ? (
            <p>Loading...</p>
          ) : error || !(data && data.analyticsSystemSchedulingVolume) ? (
            <div>
              <h1>Failed to Load</h1>
            </div>
          ) : (
            <>
              <div className="pt-8">
                <table className="w-full max-w-sm mx-auto">
                  <thead>
                    <tr>
                      <th className="py-1 text-left">Case System</th>
                      <th />
                      <th className="text-right">Count</th>
                      <th className="text-right">Web Scheduled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.analyticsSystemSchedulingVolume.countByCaseSystem.map(
                      (datum) => (
                        <tr key={datum.segment}>
                          <td className="py-2 text-left">{datum.segment}</td>
                          <td />
                          <td className="text-right">{datum.value}</td>
                          <td className="text-right">
                            {data.analyticsSystemSchedulingVolume.selfServeCountByCaseSystem.find(
                              (d) => d.segment === datum.segment
                            )?.value || 0}
                          </td>
                        </tr>
                      )
                    )}
                    <tr style={{ borderTop: "2px solid black" }}>
                      <td />
                      <td>Total:</td>
                      <td className="py-2 text-right">
                        {data.analyticsSystemSchedulingVolume.total}
                      </td>
                      <td className="py-2 text-right">
                        {data.analyticsSystemSchedulingVolume.selfServeTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="p-8 text-left">
                <LineChart
                  data={transform(data.analyticsSystemSchedulingVolume.data)}
                  xValKey="datetime"
                  yValKey="value"
                  yValLabel="Scheduled Appointments"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
