import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "../FAIcon";
import { ZERO_WIDTH_SPACE } from "lib/constants";

interface FilterFieldProps {
  label?: string;
  icon?: IconProp;
  className?: string;
  labelClassName?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  htmlFor?: string;
  children?: React.ReactNode;
  removable?: boolean;
  onRemove?(): void;
}

export const FilterField = React.forwardRef<HTMLDivElement, FilterFieldProps>(
  (props, ref) => {
    const {
      label = ZERO_WIDTH_SPACE,
      icon,
      className = "",
      labelClassName = "",
      style,
      labelStyle,
      htmlFor,
      children,
      removable = false,
      onRemove,
    } = props;

    return (
      <div
        ref={ref}
        className={`_FilterField flex flex-col justify-end ${className}`}
        style={style}
      >
        <label
          htmlFor={htmlFor}
          className={`flex items-center text-gray-700 text-sm text-left font-bold mb-1 ${labelClassName}`}
          style={labelStyle}
        >
          {icon ? (
            <span className="mr-2 text-xs text-gray-400">
              <FAIcon icon={icon} />
            </span>
          ) : null}
          {label}
          {removable ? (
            <div className="p-1 ml-2">
              <button
                type="button"
                className="text-sm text-red-500"
                onClick={onRemove}
              >
                <FAIcon icon="times" />
              </button>
            </div>
          ) : null}
        </label>
        {children}
      </div>
    );
  }
);
