export type TimeRange = {
  start: Date;
  finish: Date;
};

export enum AppointmentSubset {
  All = "ALL",
  Reschedule = "RESCHEDULE",
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Missed = "MISSED"
}

export type AppointmentQueryFilter = {
  subset: AppointmentSubset;
  // startTimeTimeRange?: TimeRange;
  healthPlanId?: string;
  caseProgramId?: string;
  modalityIds?: string[];
  providerIds?: string[];
};
