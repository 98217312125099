import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useToast } from "layouts/PortalLayout/Toast";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
} from "components/Dropdown";
import {
  AppointmentModel,
  AppointmentRequestModel,
} from "../../AppointmentRequestShowPage";
import { APPOINTMENT_REQUEST_SHOW_QUERY } from "../../AppointmentRequestShowPage";
import { BigAppointmentDetails } from "./BigAppointmentDetails";

const TOGGLE_APPOINTMENT_COMPLETION = gql`
  mutation ToggleAppointmentCompletion($id: UUID4!, $completed: Boolean!) {
    toggleAppointmentCompletion(id: $id, completed: $completed) {
      errors {
        key
        message
      }
      appointment {
        id
        completed
      }
    }
  }
`;

interface CompletionData {
  toggleAppointmentCompletion: {
    errors?: InputError[];
    appointment?: {
      id: string;
      completed: boolean;
    };
  };
}

const TOGGLE_REQUIRES_RESCHEDULE = gql`
  mutation ToggleAppointmentRequiresReschedule(
    $id: UUID4!
    $requiresReschedule: Boolean!
  ) {
    toggleAppointmentRequiresReschedule(
      id: $id
      requiresReschedule: $requiresReschedule
    ) {
      errors {
        key
        message
      }
      appointment {
        id
        requiresReschedule
      }
    }
  }
`;

interface RequiresRescheduleData {
  toggleAppointmentRequiresReschedule: {
    errors?: InputError[];
    appointment?: {
      id: string;
      requiresReschedule: boolean;
    };
  };
}

interface ViewAppointmentModalProps {
  appointmentRequest: AppointmentRequestModel;
  appointment: null | AppointmentModel;
  isOpen: boolean;
  onClose(): void;
  openCancelModal(appointment: AppointmentModel): void;
  openMissModal(appointment: AppointmentModel): void;
  openRescheduleModal(appointment: AppointmentModel): void;
}

export const ViewAppointmentModal: FC<ViewAppointmentModalProps> = (props) => {
  const {
    appointmentRequest,
    appointment,
    isOpen,
    onClose,
    openCancelModal,
    openMissModal,
    openRescheduleModal,
  } = props;

  const toast = useToast();
  const [toggleCompletion] = useMutation<CompletionData>(
    TOGGLE_APPOINTMENT_COMPLETION
  );
  const [toggleRequiresReschedule] = useMutation<RequiresRescheduleData>(
    TOGGLE_REQUIRES_RESCHEDULE
  );

  if (appointment === null) {
    return null;
  } else {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="_ViewAppointmentModal"
        style={{ minWidth: 600 }}
      >
        <ModalHeader
          icon={["far", "calendar-alt"]}
          title="Appointment"
          onClose={onClose}
        />
        <div className="Modal__section">
          <div className="flex justify-between">
            <div className="flex-grow">
              <BigAppointmentDetails
                appointmentRequest={appointmentRequest}
                appointment={appointment}
              />
              <div
                className="text-gray-500"
                style={{ fontSize: "0.75rem", marginTop: "0.5rem" }}
              >
                <p>ID: {appointment.id}</p>
              </div>
            </div>
            <div>
              <Dropdown>
                <OptionsButton label="Actions" />
                <DropdownMenu>
                  <DropdownMenuButton
                    onClick={() => openCancelModal(appointment)}
                  >
                    Cancel Appointment
                  </DropdownMenuButton>
                  <DropdownMenuButton
                    onClick={() => openMissModal(appointment)}
                  >
                    Mark as Missed
                  </DropdownMenuButton>
                  <DropdownMenuButton
                    onClick={() => openRescheduleModal(appointment)}
                  >
                    Reschedule
                  </DropdownMenuButton>
                  <DropdownMenuButton
                    onClick={() => {
                      toggleCompletion({
                        variables: {
                          id: appointment.id,
                          completed: !appointment.completed,
                        },
                        refetchQueries: [
                          {
                            query: APPOINTMENT_REQUEST_SHOW_QUERY,
                            variables: {
                              id: appointment.appointmentRequestId,
                            },
                          },
                        ],
                      }).then((res) => {
                        toast.success(
                          !appointment.completed
                            ? "Completed Appointment"
                            : "Marked Appointment as Not Completed"
                        );
                        onClose();
                      });
                    }}
                  >
                    {appointment.completed
                      ? "Mark as Not Completed"
                      : "Mark as Completed"}
                  </DropdownMenuButton>
                  <DropdownMenuButton
                    onClick={() => {
                      toggleRequiresReschedule({
                        variables: {
                          id: appointment.id,
                          requiresReschedule: !appointment.requiresReschedule,
                        },
                        refetchQueries: [
                          {
                            query: APPOINTMENT_REQUEST_SHOW_QUERY,
                            variables: {
                              id: appointment.appointmentRequestId,
                            },
                          },
                        ],
                      }).then((res) => {
                        if (
                          res?.data?.toggleAppointmentRequiresReschedule.errors
                        ) {
                          toast.danger(
                            res.data.toggleAppointmentRequiresReschedule
                              .errors[0].message
                          );
                        } else {
                          toast.success("Appointment Updated");
                          onClose();
                        }
                      });
                    }}
                  >
                    {appointment.requiresReschedule
                      ? "Un-Flag for Rescheduling"
                      : "Flag for Rescheduling"}
                  </DropdownMenuButton>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        {appointment.cancelled ? (
          <div className="Modal__section">
            <div className="field">
              <label className="block pt-1 pb-3 text-base font-semibold">
                Cancellation Reason
              </label>
              <div className="control">
                <p>{appointment.cancellationReason}</p>
              </div>
            </div>
          </div>
        ) : null}
        {appointment.missed ? (
          <div className="Modal__section">
            <div className="field">
              <label className="block pt-1 pb-3 text-base font-semibold">
                Missed Reason
              </label>
              <div className="control">
                <p>{appointment.missedReason}</p>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
};
