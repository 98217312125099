import * as Yup from "yup";

const validPassword = Yup.string()
  .required("No password provided.")
  .min(8, "Password is too short - should be 8 chars minimum.")
  .matches(
    /[a-zA-Z]+/,
    "Password must contain at least one alphabetic character."
  )
  .matches(/[0-9]+/, "Password must contain at least 1 number character.")
  .matches(
    /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]+/,
    "Password must contain at least 1 special character (e.g. ! @ # $ % ^ & * etc.)"
  );

export { validPassword };
