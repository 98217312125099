import { FC } from "react";
import { CommentEventModel } from "../AppointmentRequestEvent";
import { Tooltip } from "components/Tooltip";
import { Avatar } from "components/Avatar";
import { hMmA, mmDdYy } from "lib/dateFormatters";

interface CommentEventProps {
  event: CommentEventModel;
}

export const CommentEvent: FC<CommentEventProps> = (props) => {
  const { event } = props;

  const actorOrg = event.user?.organization.name || "External User";

  // NB: Only one of [user, agent] will ever be present.
  const actorName = [
    event.agent?.firstName,
    event.user?.firstName,
    event.agent?.lastName,
    event.user?.lastName,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className="_CommentEvent">
      <article className="gap-x-2 flex">
        <figure className="w-32 pl-2">
          <Avatar
            size={40}
            firstName={event.user?.firstName || event.agent?.firstName || ""}
            lastName={event.user?.lastName || event.agent?.lastName || ""}
            className="ml-auto"
          />
        </figure>
        <div className="flex-grow flex-shrink">
          <div className="_bubble px-3 pt-1 pb-2 bg-gray-100 rounded-lg">
            <p className="text-sm">
              <Tooltip tip={actorOrg}>
                <strong>{actorName}</strong>
              </Tooltip>
              <span className="ml-2 text-xs text-gray-500">
                {`${mmDdYy(event.insertedAt)} - ${hMmA(event.insertedAt)}`}
              </span>
            </p>
            <p className="whitespace-pre-wrap">{event.data.comment}</p>
          </div>
        </div>
      </article>
    </div>
  );
};
