import { FC, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { TextField, TextInput } from "components/formik/TextField";
import { PhoneMaskField } from "components/formik/PhoneMaskField";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { SelectField } from "components/formik/SelectField";
import { PlainSelectField } from "components/formik/PlainSelectField";
import { Button } from "components/Button";
import { VerticalField } from "components/formik/FieldStructure";
import {
  CallNotesInput,
  combineCallNotes,
  procedureDateLabel,
} from "pages/AppointmentRequestConfirmationPage/ConfirmationForm/CallNotesInput";
import { FAIcon } from "components/FAIcon";

const APPOINTMENT_REQUEST_CONTACT = gql`
  query AppointmentRequestContact($id: UUID4!) {
    appointmentRequest(id: $id) {
      id
      requestingProviderName
      requestingProviderFirstName
      requestingProviderLastName
      requestingProviderCredential
      nameOfContactPerson
      contactPersonFirstName
      contactPersonLastName
      contactPersonCredential
      contactPersonLocation
      whoIsPerformingP2p
      otherP2pContactName
      otherP2pContactFirstName
      otherP2pContactLastName
      otherP2pContactCredential
      contactPhoneNumber {
        raw
        formatted
      }
      contactPhoneNumberExtension
      alternateContactPhoneNumber {
        raw
        formatted
      }
      alternateContactPhoneNumberExtension
      contactEmail
      contactInstructions
      callNotes
      contactDetailsUpdatedAt
    }
  }
`;

interface QueryData {
  appointmentRequest: {
    id: string;
    requestingProviderName: string | null;
    requestingProviderFirstName: string | null;
    requestingProviderLastName: string | null;
    requestingProviderCredential: string | null;
    nameOfContactPerson: string | null;
    contactPersonFirstName: string | null;
    contactPersonLastName: string | null;
    contactPersonCredential: string | null;
    contactPersonLocation: string | null;
    whoIsPerformingP2p: string | null;
    otherP2pContactName: string | null;
    otherP2pContactFirstName: string | null;
    otherP2pContactLastName: string | null;
    otherP2pContactCredential: string | null;
    contactPhoneNumber: null | {
      raw: string;
      formatted: string;
    };
    contactPhoneNumberExtension: string | null;
    alternateContactPhoneNumber: null | {
      raw: string;
      formatted: string;
    };
    alternateContactPhoneNumberExtension: string | null;
    contactEmail: string | null;
    contactInstructions: string | null;
    callNotes?: string[];
    contactDetailsUpdatedAt: string | null;
  };
}

export interface FormValues {
  requestingProviderFirstName: string;
  requestingProviderLastName: string;
  requestingProviderCredential: string;
  otherRequestingProviderCredential: string;
  nameOfContactPerson?: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonLocation: string;
  contactPersonCredential: string;
  otherContactPersonCredential: string;
  whoIsPerformingP2p: string;
  otherP2pContactFirstName: string;
  otherP2pContactLastName: string;
  otherP2pContactCredential: string;
  otherP2pContactOtherCredential: string;
  contactPhoneNumber: string;
  contactPhoneNumberExtension: string;
  alternateContactPhoneNumber: string;
  alternateContactPhoneNumberExtension: string;
  contactEmail: string;
  contactInstructions: string;
  callNotes: string[];
  procedureDate?: string;
}

const contactPersonLocationOptions = [
  "Provider Office",
  "Health Plan",
  "Other",
].map(asOption);

const credentials = [
  "MD",
  "NP",
  "PA",
  "DO",
  "APN/APRN",
  "OB",
  "PT",
  "OT",
  "ST",
  "DC",
  "Acupuncturist",
  "MT",
  "Genetic Counselor",
];

const credentialOptions = credentials.concat("Other").map(asOption);

const whoIsPerformingP2pOptions: Option[] = [
  {
    value: "Requesting Provider",
    label: "Requesting Provider",
  },
  {
    value: "Contact Person",
    label: "Contact Person",
  },
  {
    value: "Other",
    label: "Someone else",
  },
];

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    requestingProviderFirstName: Yup.string().required("Required"),
    requestingProviderLastName: Yup.string().required("Required"),
    requestingProviderCredential: Yup.string(),
    contactPersonFirstName: Yup.string().required("Required"),
    contactPersonLastName: Yup.string().required("Required"),
    whoIsPerformingP2p: Yup.string().required("Required"),
    contactPhoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    alternateContactPhoneNumber: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    contactEmail: Yup.string().email("Invalid email address"),
    contactInstructions: Yup.string(),
  })
  .required();

/**
 * ContactDetailsFields.
 */

interface ContactDetailsFieldsProps {
  field: string;
}

export const ContactDetailsFields: FC<ContactDetailsFieldsProps> = (props) => {
  const { field } = props;
  const [showAllFields, setShowAllFields] = useState(false);
  const { values } = useFormikContext<any>();

  return (
    <div className="border-t">
      {!showAllFields ? (
        <>
          <div>
            <div className="gap-x-3 grid grid-cols-2 mt-3">
              <TextField
                name={`${field}.contactPersonFirstName`}
                label="Contact First Name"
                indicateRequired
              />
              <TextField
                name={`${field}.contactPersonLastName`}
                label="Contact Last Name"
                indicateRequired
              />
            </div>
            <div className="p-2 text-center">
              <Button
                type="button"
                kind="tertiary"
                color="blue"
                size="xs"
                onClick={() => setShowAllFields(true)}
              >
                Show all contact fields
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <RadioGroupField
              name={`${field}.whoIsPerformingP2p`}
              label="Who will be performing the P2P consultation?"
              options={whoIsPerformingP2pOptions}
              indicateRequired
            />
          </div>
          <section className="p-4 mt-4 border rounded">
            <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
              <FAIcon icon="user-md" className="mr-2" />
              Requesting Provider
            </h4>
            <div className="flex mt-4">
              <div className="flex-1">
                <VerticalField
                  label="Name of Provider on Case"
                  indicateRequired
                >
                  <div className="gap-x-1 grid grid-cols-2">
                    <div>
                      <TextInput
                        name={`${field}.requestingProviderFirstName`}
                        placeholder="First Name"
                      />
                    </div>
                    <div>
                      <TextInput
                        name={`${field}.requestingProviderLastName`}
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </VerticalField>
              </div>
              <div className="w-36 ml-2">
                <PlainSelectField
                  name={`${field}.requestingProviderCredential`}
                  label="Credential"
                  options={credentialOptions}
                  indicateRequired
                />
              </div>
            </div>

            {values[field].requestingProviderCredential === "Other" ? (
              <div className="mt-4">
                <TextField
                  name={`${field}.otherRequestingProviderCredential`}
                  label="Please specify provider credential"
                  indicateRequired
                />
              </div>
            ) : null}
          </section>

          <section className="p-4 mt-4 border rounded">
            <div className="flex items-center">
              <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                <FAIcon icon={["far", "address-card"]} className="mr-2" />
                Contact Person
              </h4>

              <SameAsProviderCheckbox fieldPrefix={field} className="ml-4" />
            </div>
            <div className="flex mt-4">
              <div className="grid flex-1 grid-cols-2 gap-3">
                <div>
                  <TextField
                    name={`${field}.contactPersonFirstName`}
                    label="Contact First Name"
                    indicateRequired
                  />
                </div>
                <div>
                  <TextField
                    name={`${field}.contactPersonLastName`}
                    label="Contact Last Name"
                    indicateRequired
                  />
                </div>
              </div>
              {values[field].whoIsPerformingP2p === "Contact Person" ? (
                <div className="w-36 ml-2">
                  <PlainSelectField
                    name={`${field}.contactPersonCredential`}
                    label="Credential"
                    options={credentialOptions}
                    indicateRequired
                  />
                </div>
              ) : null}
            </div>

            {values[field].whoIsPerformingP2p === "Contact Person" &&
            values[field].contactPersonCredential === "Other" ? (
              <div className="mt-4">
                <TextField
                  name={`${field}.otherContactPersonCredential`}
                  label="Please specify provider credential"
                  indicateRequired
                />
              </div>
            ) : null}

            <div className="mt-4">
              <SelectField
                name={`${field}.contactPersonLocation`}
                label="Contact Person Location"
                options={contactPersonLocationOptions}
                indicateRequired
              />
            </div>
          </section>

          <section className="p-4 mt-4 border rounded">
            <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
              <FAIcon icon={"phone"} className="mr-2" />
              P2P Information
            </h4>
            {values[field].whoIsPerformingP2p === "Other" ? (
              <>
                <div className="flex mt-4">
                  <div className="flex-1">
                    <VerticalField
                      label="Name of Person Performing P2P"
                      indicateRequired
                    >
                      <div className="gap-x-1 grid grid-cols-2">
                        <div>
                          <TextInput
                            name={`${field}.otherP2pContactFirstName`}
                            placeholder="First Name"
                          />
                        </div>
                        <div>
                          <TextInput
                            name={`${field}.otherP2pContactLastName`}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </VerticalField>
                  </div>
                  <div className="w-36 ml-2">
                    <PlainSelectField
                      name={`${field}.otherP2pContactCredential`}
                      label="Credential"
                      options={credentialOptions}
                      indicateRequired
                    />
                  </div>
                </div>
                {values[field].otherP2pContactCredential === "Other" ? (
                  <div className="mt-4">
                    <TextField
                      name={`${field}.otherP2pContactOtherCredential`}
                      label="Please specify credential for Person Performing P2P"
                      indicateRequired
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            <div className="flex mt-4">
              <div className="flex-1">
                <PhoneMaskField
                  name={`${field}.contactPhoneNumber`}
                  label="Phone Number for P2P"
                  icon="phone"
                  indicateRequired
                />
              </div>

              <div className="w-36 ml-2">
                <TextField
                  name={`${field}.contactPhoneNumberExtension`}
                  label="Phone Ext."
                  icon="phone"
                />
              </div>
            </div>

            <div className="flex mt-4">
              <div className="flex-1">
                <PhoneMaskField
                  name={`${field}.alternateContactPhoneNumber`}
                  label="Alternate Phone"
                  icon="phone"
                />
              </div>

              <div className="w-36 ml-2">
                <TextField
                  name={`${field}.alternateContactPhoneNumberExtension`}
                  label="Phone Ext."
                  icon="phone"
                />
              </div>
            </div>

            <div className="mt-4">
              <TextField
                name={`${field}.contactEmail`}
                label="Requesting Provider Email"
              />
            </div>

            <div className="mt-4">
              <TextField
                name={`${field}.contactInstructions`}
                label="Contact Instructions"
              />
            </div>

            <div className="mt-4">
              <VerticalField label="Call Notes">
                <CallNotesInput name={`${field}.callNotes`} />
              </VerticalField>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

function SameAsProviderCheckbox(props: {
  fieldPrefix: string;
  className?: string;
}) {
  const { fieldPrefix, className = "" } = props;
  const { values, setFieldValue } = useFormikContext<any>();

  const [checked, setChecked] = useState(false);

  function handleChange() {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      setFieldValue(
        `${fieldPrefix}.contactPersonFirstName`,
        values[fieldPrefix].requestingProviderFirstName
      );
      setFieldValue(
        `${fieldPrefix}.contactPersonLastName`,
        values[fieldPrefix].requestingProviderLastName
      );
      setFieldValue(
        `${fieldPrefix}.contactPersonCredential`,
        values[fieldPrefix].requestingProviderCredential
      );
    } else {
      setFieldValue(`${fieldPrefix}.contactPersonFirstName`, "");
      setFieldValue(`${fieldPrefix}.contactPersonLastName`, "");
      setFieldValue(`${fieldPrefix}.contactPersonCredential`, "");
    }
  }

  console.log({
    fieldPrefix,
    values,
    check: values[fieldPrefix].whoIsPerformingP2p,
  });

  return values[fieldPrefix].whoIsPerformingP2p === "Requesting Provider" ? (
    <label className={`checkbox flex items-center text-sm ${className}`}>
      <input
        id="sameAsProvider"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ marginRight: "0.5rem" }}
        className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
      />
      Same as Requesting Provider
    </label>
  ) : null;
}

function asOption(value: string): Option {
  return { label: value, value };
}

type AppointmentRequestContactDetails = QueryData["appointmentRequest"];

export function useAppointmentRequestContact(id: string) {
  const { data, loading, error } = useQuery<QueryData>(
    APPOINTMENT_REQUEST_CONTACT,
    {
      fetchPolicy: "network-only",
      variables: { id },
    }
  );

  const ar = data?.appointmentRequest;

  function requestingProviderFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.requestingProviderName?.split(" ") || [];
    return appointmentRequest.requestingProviderFirstName || firstName;
  }

  function requestingProviderLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.requestingProviderName?.split(" ") || [];
    return (
      appointmentRequest.requestingProviderLastName || lastNameParts.join(" ")
    );
  }

  function requestingProviderCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.requestingProviderCredential || "")
      ? appointmentRequest.requestingProviderCredential
      : "Other";
  }

  function otherRequestingProviderCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.requestingProviderCredential || "")
      ? ""
      : appointmentRequest.requestingProviderCredential || "";
  }

  function contactPersonFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.nameOfContactPerson?.split(" ") || [];
    return appointmentRequest.contactPersonFirstName || firstName;
  }

  function contactPersonLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.nameOfContactPerson?.split(" ") || [];
    return appointmentRequest.contactPersonLastName || lastNameParts.join(" ");
  }

  function contactPersonCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.contactPersonCredential || "")
      ? appointmentRequest.contactPersonCredential || ""
      : "Other";
  }

  function otherContactPersonCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.contactPersonCredential || "")
      ? ""
      : appointmentRequest.contactPersonCredential || "";
  }

  function otherP2pContactFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.otherP2pContactName?.split(" ") || [];
    return appointmentRequest.otherP2pContactFirstName || firstName;
  }

  function otherP2pContactLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.otherP2pContactName?.split(" ") || [];
    return (
      appointmentRequest.otherP2pContactLastName || lastNameParts.join(" ")
    );
  }

  function otherP2pContactCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.otherP2pContactCredential || "")
      ? appointmentRequest.otherP2pContactCredential || ""
      : "Other";
  }

  function otherP2pContactOtherCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.otherP2pContactCredential || "")
      ? ""
      : appointmentRequest.otherP2pContactCredential || "";
  }

  function callNotes(
    appointmentRequest: AppointmentRequestContactDetails
  ): string[] {
    return (
      appointmentRequest.callNotes?.map((callNote) => {
        if (callNote.includes(procedureDateLabel)) {
          return procedureDateLabel;
        } else {
          return callNote;
        }
      }) || []
    );
  }

  function procedureDate(
    appointmentRequest: AppointmentRequestContactDetails
  ): string {
    const procedureDateNote = appointmentRequest.callNotes?.find((callNote) =>
      callNote.includes(procedureDateLabel)
    );
    return procedureDateNote?.split(" ").pop() || "";
  }

  return {
    data: ar && {
      requestingProviderFirstName:
        requestingProviderFirstName(data.appointmentRequest) || "",
      requestingProviderLastName:
        requestingProviderLastName(data.appointmentRequest) || "",
      requestingProviderCredential:
        requestingProviderCredential(data.appointmentRequest) || "",
      otherRequestingProviderCredential:
        otherRequestingProviderCredential(data.appointmentRequest) || "",
      contactPersonFirstName:
        contactPersonFirstName(data.appointmentRequest) || "",
      contactPersonLastName:
        contactPersonLastName(data.appointmentRequest) || "",
      contactPersonCredential:
        contactPersonCredential(data.appointmentRequest) || "",
      otherContactPersonCredential:
        otherContactPersonCredential(data.appointmentRequest) || "",
      contactPersonLocation:
        data.appointmentRequest.contactPersonLocation || "",
      contactPhoneNumber: data.appointmentRequest.contactPhoneNumber?.raw || "",
      whoIsPerformingP2p: data.appointmentRequest.whoIsPerformingP2p || "",
      otherP2pContactFirstName:
        otherP2pContactFirstName(data.appointmentRequest) || "",
      otherP2pContactLastName:
        otherP2pContactLastName(data.appointmentRequest) || "",
      otherP2pContactCredential:
        otherP2pContactCredential(data.appointmentRequest) || "",
      otherP2pContactOtherCredential:
        otherP2pContactOtherCredential(data.appointmentRequest) || "",
      contactPhoneNumberExtension:
        data.appointmentRequest.contactPhoneNumberExtension || "",
      alternateContactPhoneNumber:
        data.appointmentRequest.alternateContactPhoneNumber?.raw || "",
      alternateContactPhoneNumberExtension:
        data.appointmentRequest.alternateContactPhoneNumberExtension || "",
      contactInstructions: data.appointmentRequest.contactInstructions || "",
      callNotes: callNotes(data.appointmentRequest),
      procedureDate: procedureDate(data.appointmentRequest),
      contactEmail: data.appointmentRequest.contactEmail || "",
    },
    loading,
    error,
  };
}

export function toMutationVariables(formValues: FormValues): any {
  const values = combineCallNotes("callNotes", formValues);

  console.log(values);
  return {
    ...values,
    requestingProviderCredential:
      values.requestingProviderCredential === "Other"
        ? values.otherRequestingProviderCredential.trim()
        : values.requestingProviderCredential,
    otherRequestingProviderCredential: undefined,
    contactPersonCredential:
      values.contactPersonCredential === "Other"
        ? values.otherContactPersonCredential.trim()
        : values.contactPersonCredential,
    otherContactPersonCredential: undefined,
    otherP2pContactCredential:
      values.otherP2pContactCredential === "Other"
        ? values.otherP2pContactOtherCredential.trim()
        : values.otherP2pContactCredential,
    otherP2pContactOtherCredential: undefined,
  };
}
