import { forwardRef, HTMLAttributes } from "react";

/**
 * TableContainer.
 */

type TableContainerProps = HTMLAttributes<HTMLDivElement>;

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  (props, ref) => {
    const { className = "", ...rest } = props;
    return (
      <div className={`flex flex-col ${className}`}>
        <div className="sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 py-2 -my-2">
          <div
            className="sm:rounded-lg inline-block min-w-full align-middle border border-gray-200 shadow-md"
            ref={ref}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

/**
 * Table.
 */

type TableProps = HTMLAttributes<HTMLTableElement>;

export const Table = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  const { className = "", ...rest } = props;
  return (
    <table className={`_Table min-w-full ${className}`} ref={ref} {...rest} />
  );
});

/**
 * TH.
 */

type THProps = HTMLAttributes<HTMLTableCellElement>;

export const TH = forwardRef<HTMLTableCellElement, THProps>((props, ref) => {
  const { className = "", ...rest } = props;
  return (
    <th
      className={`px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider truncate ${className}`}
      ref={ref}
      {...rest}
    />
  );
});

/**
 * TD.
 */

interface TDProps extends HTMLAttributes<HTMLTableCellElement> {
  wrap?: boolean;
}

export const TD = forwardRef<HTMLTableCellElement, TDProps>((props, ref) => {
  const { className = "", wrap = false, ...rest } = props;
  return (
    <td
      className={`px-6 py-4 ${
        wrap ? "" : "whitespace-no-wrap"
      } border-t border-gray-200 text-sm leading-5 ${className}`}
      ref={ref}
      {...rest}
    />
  );
});
