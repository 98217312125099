import { FC } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { FAIcon } from "components/FAIcon";
import { LoginAttempts } from "./LoginAttempts";
import "./OrganizationUserShow.css";

interface RouteParams {
  userId: string;
}

interface OrganizationUserShowProps {}

export const OrganizationUserShow: FC<OrganizationUserShowProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { userId } = match.params;
  return (
    <div className="OrganizationUserShow">
      <NavTitle title="Settings » Users » User Details" />
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <NavTabs>
          {/* <NavTabLink to={`/o/settings/users/${userId}/profile`}>
            <span className="mr-2">
              <FAIcon icon="user-edit" aria-hidden="true" />
            </span>
            Profile
          </NavTabLink>

          <NavTabLink to={`/o/settings/users/${userId}/account`}>
            <span className="mr-2">
              <FAIcon icon="lock" aria-hidden="true" />
            </span>
            Account
          </NavTabLink> */}

          <NavTabLink to={`/o/settings/users/${userId}/login_attempts`}>
            <span className="mr-2">
              <FAIcon icon="list-alt" aria-hidden="true" />
            </span>
            Login Attempts
          </NavTabLink>
        </NavTabs>

        <div className="mt-4">
          <Route
            path="/o/settings/users/:userId/login_attempts"
            component={LoginAttempts}
          />
        </div>
      </div>
    </div>
  );
};
