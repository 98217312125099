import { FC } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import { HorizontalTextField } from "components/formik/TextField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { useToast } from "layouts/PortalLayout/Toast";
import { HorizontalField } from "components/formik/FieldStructure";
import { Button } from "components/Button";
// import "./ProfileForm.css";

const CURRENT_ORGANIZATION_PROFILE_DATA_QUERY = gql`
  query CurrentOrgProfileInfo {
    me {
      id
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      name: string;
    };
  };
}

const UPDATE_ORGANIZATION_PROFILE_MUTATION = gql`
  mutation UpdateOrganizationProfile($input: OrganizationProfileInput!) {
    updateOrganizationProfile(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface MutationData {
  updateOrganizationProfile: {
    errors?: InputError[];
  };
}

type ProfileFormProps = {};

export const ProfileForm: FC<ProfileFormProps> = () => {
  const toast = useToast();
  const { loading, data, error } = useQuery<Data>(
    CURRENT_ORGANIZATION_PROFILE_DATA_QUERY
  );
  const [updateOrganization] = useMutation<MutationData>(
    UPDATE_ORGANIZATION_PROFILE_MUTATION
  );

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Organization Profile" />
      <GradientHeader
        title="Organization Information"
        subtitle="Update your organization's profile information."
      />
      <div className="ProfileForm">
        {loading ? (
          <h1>Loading...</h1>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : (
          <Formik
            initialValues={{
              name: data.me.organization.name,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              updateOrganization({
                variables: { input: values },
                refetchQueries: [
                  { query: CURRENT_ORGANIZATION_PROFILE_DATA_QUERY },
                ],
              }).then(
                (resp) => {
                  if (resp?.data?.updateOrganizationProfile.errors) {
                    setStatus({
                      errors: resp.data.updateOrganizationProfile.errors,
                    });
                  } else {
                    toast.success("Updated organization profile!");
                  }
                  setSubmitting(false);
                },
                (rej) => setSubmitting(false)
              );
            }}
          >
            {({ values, status, isSubmitting, handleSubmit }) => (
              <div style={{ padding: "1.5rem" }}>
                <form onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />

                  <div className="mt-3">
                    <HorizontalTextField name="name" label="Name" />
                  </div>

                  <div className="mt-3">
                    <HorizontalField label="">
                      <Button
                        type="submit"
                        kind="primary"
                        color="gold"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </HorizontalField>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};
