import { useState, memo } from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import { firstSecondThird } from "lib/firstSecondThird";
import { CircleIcon } from "components/CircleIcon";
import { coalesceString } from "lib/coalesce";
import { ProviderResult as IProviderResult } from "../ProviderResultsFetcher";
import { ddd, mmDdYy } from "lib/dateFormatters";
import "./ProviderResult.css";

function makeLink(params: {
  startTime: string;
  startTimeString: string;
  finishTime: string;
  finishTimeString: string;
  providerId: string;
  appointmentRequestId: string;
}) {
  const {
    startTime,
    startTimeString,
    finishTime,
    finishTimeString,
    providerId,
    appointmentRequestId,
  } = params;
  return `/o/appointment_request_wizard/4?${qs.stringify({
    startTime,
    startTimeString,
    finishTime,
    finishTimeString,
    providerId,
    appointmentRequestId,
  })}`;
}

interface ProviderResultProps {
  providerResult: IProviderResult;
  appointmentRequestId: string;
}

export const ProviderResult = memo<ProviderResultProps>((props) => {
  const { providerResult, appointmentRequestId } = props;
  const [showAll, setShowAll] = useState(false);

  function toggleShowAll() {
    setShowAll(!showAll);
  }

  return (
    <div className="ResultsPage__ProviderResult">
      <div className="ResultsPage__ProviderResult__header">
        <div className="gap-x-3 flex items-center justify-between">
          <div className="gap-x-3 flex items-center ml-2">
            <CircleIcon icon="user-md" size={32} iconSize={16} />
            <div className="text-left">
              <h2 className="ResultsPage__ProviderResult__provider-name">
                {providerResult.provider.nameWithAppellation}
              </h2>
              {providerResult.provider.specialties &&
              providerResult.provider.specialties.length > 0 ? (
                <p style={{ fontSize: "0.75rem", color: "#6e7988" }}>
                  {providerResult.provider.specialties[0].name}
                </p>
              ) : providerResult.provider.primarySpecialty ? (
                providerResult.provider.primarySpecialty
              ) : providerResult.provider.skills &&
                providerResult.provider.skills.length > 0 ? (
                <p style={{ fontSize: "0.75rem", color: "#6e7988" }}>
                  {providerResult.provider.skills
                    .slice(0, 3)
                    .map((s) => s.name)
                    .join(", ")}
                  {providerResult.provider.skills.length > 3 ? (
                    <span>
                      {" "}
                      and {providerResult.provider.skills.length - 3} other
                      skills
                    </span>
                  ) : null}
                </p>
              ) : null}
            </div>
          </div>
          {providerResult.provider.priorityBySkill ? (
            <p className="ResultsPage__ProviderResult__priority">
              {firstSecondThird(providerResult.provider.priorityBySkill)}{" "}
              Priority by Skill
            </p>
          ) : null}
        </div>
      </div>
      <div className="ResultsPage__ProviderResult__body">
        <div className="cols">
          {providerResult.dates.map((day) => (
            <div
              className="col ResultsPage__ProviderResult__day"
              key={day.date}
            >
              <h3 className="RescheduleModal__ProviderResult__date text-sm">
                <span className="font-normal">{ddd(day.date)}</span>{" "}
                {mmDdYy(day.date)}
              </h3>
              {!day.slots || day.slots.length === 0 ? (
                <div className="ResultsPage__ProviderResult__empty">-</div>
              ) : (
                <>
                  <ul className="flex flex-col items-center">
                    {(!showAll ? day.slots.slice(0, 4) : day.slots).map(
                      (timeSlot) => (
                        <li key={timeSlot.timeRange.startTimeString}>
                          <Link
                            style={{ margin: "0 auto 4px" }}
                            className="ResultsPage__ProviderResult__slot"
                            to={makeLink({
                              appointmentRequestId: coalesceString(
                                appointmentRequestId
                              ),
                              startTime: timeSlot.timeRange.start,
                              startTimeString:
                                timeSlot.timeRange.startTimeString,
                              finishTime: timeSlot.timeRange.finish,
                              finishTimeString:
                                timeSlot.timeRange.finishTimeString,
                              providerId: providerResult.provider.id,
                            })}
                          >
                            {timeSlot.timeRange.startTimeString}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                  {!showAll && day.slots && day.slots.length > 4 ? (
                    <button
                      type="button"
                      className="a ResultsPage__ProviderResult__show-more"
                      onClick={toggleShowAll}
                    >
                      Show more...
                    </button>
                  ) : null}
                </>
              )}
            </div>
          ))}
        </div>
        <div className="ResultsPage__ProviderResult__show-less">
          {showAll ? (
            <button type="button" className="a" onClick={toggleShowAll}>
              Show less...
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
});
