import React, { Component } from "react";
import { ToastConsumer, ToastMessage } from "./Toast";
import { Transition, animated, config } from "react-spring";
import "./ToastDisplay.css";

type ToastDisplayProps = {
  items: ToastMessage[];
  leaving: ToastMessage[];
  leave: any;
  removeToast: any;
};

// NB: This is a hack to get around TS checking Transition's props.
// The typescript definitions for the lib seem to be incomplete/wrong.
// https://github.com/drcmda/react-spring/issues/26
const AnyTransition: React.SFC<any> = props => <Transition {...props} />;

let spring = { ...config.default, precision: 0.1 };

class InnerToastDisplay extends Component<ToastDisplayProps> {
  cancelMap = new WeakMap();

  config = (_item: ToastMessage, state: string) => {
    return state === "leave" ? [{ duration: 4000 }, spring, spring] : spring;
  };

  cancel = (item: ToastMessage) => {
    return this.cancelMap.has(item) && this.cancelMap.get(item)();
  };

  leave = (item: ToastMessage) => async (next: any, cancel: any) => {
    this.cancelMap.set(item, cancel);
    await next({ life: 0 });
    await next({ opacity: 0 });
    await next({ height: 0 }, true); // Inform Keyframes that this is the last frame.
  };

  render() {
    return (
      <div className="ToastDisplay__container">
        <AnyTransition
          native
          items={this.props.items}
          keys={(item: ToastMessage) => item.key}
          from={{ opacity: 0, height: 0, life: 1 }}
          enter={{ opacity: 1, height: "auto" }}
          leave={this.leave}
          onRest={this.props.removeToast}
          config={this.config}
        >
          {(item: ToastMessage) => (props: any) => {
            const { life, ...rest } = props;
            return (
              <animated.div style={rest} className="Toast__msg">
                <div className={`Toast__content Toast__content-${item.type}`}>
                  <animated.div
                    style={{
                      right: life.interpolate(
                        (l: number) => `calc(${l * 100}%)`
                      )
                    }}
                    className="Toast__life"
                  />
                  <p>{item.message}</p>
                  <button type="button" onClick={() => this.cancel(item)} />
                </div>
              </animated.div>
            );
          }}
        </AnyTransition>
      </div>
    );
  }
}

const ToastDisplay = () => {
  return (
    <ToastConsumer>
      {({ items, leaving, removeToast, leave }) => (
        <InnerToastDisplay
          items={items}
          leaving={leaving}
          removeToast={removeToast}
          leave={leave}
        />
      )}
    </ToastConsumer>
  );
};

export { ToastDisplay };
