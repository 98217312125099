import { FC, useState } from "react";
import { SelectedAppointment } from "../RescheduleAppointmentModal";
import { AppointmentModel } from "../../../AppointmentRequestShowPage";
import { JustRescheduleForm } from "./JustRescheduleForm";
import { CancelAndRescheduleForm } from "./CancelAndRescheduleForm";
import { MissAndRescheduleForm } from "./MissAndRescheduleForm";
import { RadioGroup, Radio } from "components/RadioGroup";
import { HorizontalField } from "components/formik/FieldStructure";

interface RescheduleFormProps {
  oldAppointment: AppointmentModel;
  newAppointment: SelectedAppointment;
  forgetAppointmentSelection(): void;
  onSuccess(): void;
}

export const RescheduleForm: FC<RescheduleFormProps> = (props) => {
  const {
    oldAppointment,
    newAppointment,
    forgetAppointmentSelection,
    onSuccess,
  } = props;

  const [action, setAction] = useState("cancel");

  return (
    <div className="_RescheduleForm">
      <HorizontalField label="Action" indicateRequired>
        <RadioGroup value={action} onChange={setAction}>
          <Radio value="cancel">Cancel Old Appointment</Radio>
          <Radio value="miss">Mark Old Appointment as Missed</Radio>
        </RadioGroup>
      </HorizontalField>
      {action === "cancel" ? (
        <CancelAndRescheduleForm
          oldAppointment={oldAppointment}
          newAppointment={newAppointment}
          forgetAppointmentSelection={forgetAppointmentSelection}
          onSuccess={onSuccess}
        />
      ) : null}
      {action === "miss" ? (
        <MissAndRescheduleForm
          oldAppointment={oldAppointment}
          newAppointment={newAppointment}
          forgetAppointmentSelection={forgetAppointmentSelection}
          onSuccess={onSuccess}
        />
      ) : null}
      {action === "keep" ? (
        <JustRescheduleForm
          oldAppointment={oldAppointment}
          newAppointment={newAppointment}
          forgetAppointmentSelection={forgetAppointmentSelection}
          onSuccess={onSuccess}
        />
      ) : null}
    </div>
  );
};
