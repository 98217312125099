import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

const RESET_PASSWORD = gql`
  mutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

interface MutationData {
  requestPasswordReset?: string;
}

interface FormValues {
  email: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .required("Required"),
  })
  .required();

interface ForgotPasswordFormProps {}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = () => {
  const [resetPassword] = useMutation<MutationData>(RESET_PASSWORD);

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setSubmitting, setStatus } = formikHelpers;

      try {
        const { data } = await resetPassword({ variables: values });
        if (data?.requestPasswordReset === "ok") {
          window.alert("Password Reset Email Sent!");
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [resetPassword]
  );

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="md:px-6 rounded-xl px-2 py-6 bg-white border shadow-md shadow-xl"
        >
          <h4 className="my-4 text-xl font-semibold text-center">
            Request Password Reset
          </h4>
          <FormStatusErrors status={status} />

          <TextField
            label="Email"
            type="email"
            name="email"
            icon="envelope"
            placeholder="email@example.com"
          />

          <div className="flex justify-center mt-6">
            <Button
              type="submit"
              kind="primary"
              color="gold"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Reset my password
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
