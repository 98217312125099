import { FC, useCallback } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useToast } from "layouts/PortalLayout/Toast";
import { TextAreaInput } from "components/formik/TextArea";
import { MyAvatar } from "components/Avatar";
import { APPOINTMENT_REQUEST_SHOW_QUERY } from "../AppointmentRequestShowPage";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button } from "components/Button";

const POST_COMMENT = gql`
  mutation AddAppointmentRequestComment($id: UUID4!, $comment: String!) {
    addAppointmentRequestComment(id: $id, comment: $comment) {
      errors {
        key
        message
      }
      appointmentRequestEvent {
        id
      }
    }
  }
`;

interface MutationData {
  addAppointmentRequestComment: {
    errors?: InputError[];
    appointmentRequestEvent: {
      id: string;
    };
  };
}

interface FormValues {
  comment: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    comment: Yup.string().required("Required"),
  })
  .required();

interface CommentFormProps {
  appointmentRequestId: string;
}

export const CommentForm: FC<CommentFormProps> = (props) => {
  const { appointmentRequestId } = props;
  const toast = useToast();
  const [postComment] = useMutation<MutationData>(POST_COMMENT);

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpres: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting, resetForm } = formikHelpres;

      const variables = {
        id: appointmentRequestId,
        comment: values.comment,
      };

      const refetchQueries = [
        {
          query: APPOINTMENT_REQUEST_SHOW_QUERY,
          variables: {
            id: appointmentRequestId,
          },
        },
      ];

      try {
        const { data } = await postComment({ variables, refetchQueries });

        if (data?.addAppointmentRequestComment.errors) {
          setStatus({ errors: data.addAppointmentRequestComment.errors });
        } else if (data?.addAppointmentRequestComment.appointmentRequestEvent) {
          // it worked...
          toast.success("Comment Posted");
          resetForm();
        }
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
      }
      setSubmitting(false);
    },
    [appointmentRequestId, toast, postComment]
  );

  return (
    <Formik
      initialValues={{ comment: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="_CommentForm">
          <article className="gap-x-2 flex">
            <figure className="w-32 pl-2">
              <MyAvatar size={60} style={{ marginLeft: "auto" }} />
            </figure>
            <div className="flex-grow flex-shrink">
              <FormStatusErrors status={status} />

              <TextAreaInput
                name="comment"
                placeholder="Add a comment"
                minHeight={84}
              />

              <Button
                type="submit"
                kind="primary"
                color="gold"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                className="mt-3"
              >
                Post Comment
              </Button>
            </div>
          </article>
        </form>
      )}
    </Formik>
  );
};
