import { FC, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { FAIcon } from "components/FAIcon";
import { Modal, ModalHeader } from "components/Modal";
import { SkillSpecialtyPriorities } from "./SkillSpecialtyPriorities";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
} from "components/Dropdown";
import { Table, TH, TD, TableContainer } from "components/Table";
import styled from "styled-components/macro";

const PAGE_SIZE = 25;

const SKILLS = gql`
  query ListSkills {
    skills {
      id
      name
      externalSystemName
      bufferLengthForShifts
      bufferLengthForAppointments
      bufferLengthForLunch
      bufferLengthForMeetings
      appointmentLength
      specialtyPrioritizationCount
    }
  }
`;

type Skill = {
  id: string;
  name: string;
  externalSystemName: string;
  bufferLengthForShifts: number;
  bufferLengthForAppointments: number;
  bufferLengthForLunch: number;
  bufferLengthForMeetings: number;
  appointmentLength: number;
  specialtyPrioritizationCount: number;
};

interface Data {
  skills: Skill[];
}

const RotateTH = styled.div`
  position: absolute;
  -webkit-transform: translate(-2px, -50%) rotate(-45deg);
  -ms-transform: translate(-2px, -50%) rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 10;
  bottom: 46px;
  left: 9px;
  text-align: left;
  width: 134px;
  background-color: #f9fafb;
  padding: 10px 0;
  border-radius: 10px;
`;

type ActiveModal = { type: "EDIT_SKILL_SPECIALTY_PRIORITIES"; skillId: string };

interface SkillsProps {}

export const Skills: FC<SkillsProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openSpecialtyPrioritiesModal = (skillId: string) =>
    setActiveModal({ type: "EDIT_SKILL_SPECIALTY_PRIORITIES", skillId });
  const closeModal = () => setActiveModal(null);

  const { data, loading, error } = useQuery<Data>(SKILLS, {
    variables: { first: PAGE_SIZE },
  });

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Skills" />
      <Modal
        isOpen={activeModal?.type === "EDIT_SKILL_SPECIALTY_PRIORITIES"}
        onRequestClose={closeModal}
        style={{ maxWidth: 800 }}
      >
        <ModalHeader
          icon="stethoscope"
          title="Skill Specialty Priorities"
          onClose={closeModal}
        />
        {activeModal?.skillId && (
          <SkillSpecialtyPriorities skillId={activeModal?.skillId} />
        )}
      </Modal>
      <GradientHeader
        title="Skills"
        subtitle="Manage your organization's skills"
      />
      <div style={{ padding: "1rem" }}>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : (
          <div className="pt-6">
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TH>Name</TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Specialties</RotateTH>
                    </TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Buffer/Shifts</RotateTH>
                    </TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Buffer/Appts</RotateTH>
                    </TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Buffer/Lunch</RotateTH>
                    </TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Buffer/Meetings</RotateTH>
                    </TH>
                    <TH className="relative" style={{ overflow: "visible" }}>
                      <RotateTH>Appt Length</RotateTH>
                    </TH>
                    <TH />
                  </tr>
                </thead>
                <tbody>
                  {data.skills.map((skill: Skill) => (
                    <tr key={skill.id}>
                      <TD>
                        {skill.name} ({skill.externalSystemName})
                      </TD>
                      <TD className="flex">
                        {skill.specialtyPrioritizationCount}
                        {skill.specialtyPrioritizationCount === 0 ? (
                          <span className="ml-2 text-yellow-600">
                            <Tooltip
                              tip={
                                <div style={{ width: 100 }}>
                                  Requests can't be scheduled unless there are
                                  specialties prioritized for this skill.
                                </div>
                              }
                            >
                              <FAIcon icon="exclamation-triangle" />
                            </Tooltip>
                          </span>
                        ) : null}
                      </TD>
                      <TD>{skill.bufferLengthForShifts}</TD>
                      <TD>{skill.bufferLengthForAppointments}</TD>
                      <TD>{skill.bufferLengthForLunch}</TD>
                      <TD>{skill.bufferLengthForMeetings}</TD>
                      <TD>{skill.appointmentLength}</TD>
                      <TD>
                        <Dropdown>
                          <OptionsButton label="Actions" />
                          <DropdownMenu>
                            <DropdownMenuButton
                              onClick={() =>
                                openSpecialtyPrioritiesModal(skill.id)
                              }
                            >
                              Edit Specialty Priorities
                            </DropdownMenuButton>
                          </DropdownMenu>
                        </Dropdown>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
