import { FAIcon } from "components/FAIcon";

interface TagProps {
  label: string;
  color: string;
  onDelete?(): void;
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  className?: string;
}

export const Tag: React.FC<TagProps> = (props) => {
  const { color, label, onDelete, className = "" } = props;

  const textTone = getTextTone(color);

  const labelClassName = [
    textTone === "dark" ? "text-black" : "text-white",
    "opacity-90 px-2 py-0.5",
  ].join(" ");

  const buttonClassName = [
    textTone === "dark"
      ? "text-black hover:bg-red-200 hover:text-red-700"
      : "text-white hover:bg-red-700 hover:text-red-200",
    "opacity-70 px-2 py-0.5 hover:opacity-100 rounded-r",
  ].join(" ");

  return (
    <span
      style={{ backgroundColor: color }}
      className={`_Tag ${className} inline-flex items-center rounded text-xs font-medium`}
    >
      <span className={labelClassName}>{label}</span>
      {onDelete ? (
        <span className="border-opacity-40 leading-tight border-l">
          <button
            type="button"
            className={`bg-none outline-none cursor-pointer ${buttonClassName}`}
            onClick={onDelete}
          >
            <FAIcon icon="times" />
          </button>
        </span>
      ) : null}
    </span>
  );
};

function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(_m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function getTextTone(hex: string): "light" | "dark" {
  const rgb = hexToRgb(hex);

  if (!rgb) return "dark";

  const o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
  return o > 125 ? "dark" : "light";
}
