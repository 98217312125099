import { FC, useCallback, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";
import { FAIcon } from "components/FAIcon";
import { makeAppendItems } from "lib/makeAppendItems";
import { FilterPanel, Filter } from "./FilterPanel";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { ViewWebformSubmissionModal } from "./ViewWebformSubmissionModal";
import { Day } from "components/PreferredDays";
import { Tag } from "components/Tag";
import { AddTagDropdown } from "./AddTagDropdown";
import { stopPropogation } from "lib/reactHelpers";
import { hMmA, mmDdYyyy } from "lib/dateFormatters";
import { useToast } from "layouts/PortalLayout/Toast";
import { Button } from "components/Button";

const ADD_TAG = gql`
  mutation AddWebformSubmissionTag(
    $webformSubmissionId: UUID4!
    $webformSubmissionTagId: UUID4!
  ) {
    addWebformSubmissionTag(
      webformSubmissionId: $webformSubmissionId
      webformSubmissionTagId: $webformSubmissionTagId
    ) {
      errors {
        key
        message
      }
      webformSubmission {
        id
        webformSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface AddMutData {
  addWebformSubmissionTag: {
    errors: void | InputError[];
    webformSubmission: void | { id: string; webformSubmissionTags: TagModel[] };
  };
}

const REMOVE_TAG = gql`
  mutation RemoveWebformSubmissionTag(
    $webformSubmissionId: UUID4!
    $webformSubmissionTagId: UUID4!
  ) {
    removeWebformSubmissionTag(
      webformSubmissionId: $webformSubmissionId
      webformSubmissionTagId: $webformSubmissionTagId
    ) {
      errors {
        key
        message
      }
      webformSubmission {
        id
        webformSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface RemoveMutData {
  removeWebformSubmissionTag: {
    errors: void | InputError[];
    webformSubmission: void | { id: string; webformSubmissionTags: TagModel[] };
  };
}

const PAGE_SIZE = 50;

const WEBFORM_SUBMISSIONS = gql`
  query ListWebformSubmissions(
    $first: Int!
    $after: UUID4
    $filter: WebformSubmissionsFilter
  ) {
    webformSubmissionTags(first: 100) {
      items {
        id
        label
        color
      }
    }
    webformSubmissions(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        caseReferenceNumber
        memberFirstName
        memberLastName
        memberDob
        healthPlan {
          id
          name
        }
        timePreference {
          preferredDays
          preferredTimes
          timeZoneName
        }
        lineOfBusiness
        requestingProviderName
        requestingProviderCredential
        nameOfContactPerson
        whoIsPerformingP2p
        otherP2pContactName
        otherP2pContactCredential
        contactPersonLocation
        contactPhoneNumber {
          formatted
          raw
        }
        contactPhoneNumberExtension
        alternateContactPhoneNumber {
          formatted
          raw
        }
        alternateContactPhoneNumberExtension
        contactEmail
        contactInstructions
        ohioMemberConsentGiven
        procedurePerformed
        dateOfService
        status
        archivedAt
        archivedAtString(
          format: "{WDshort} {M}/{D}/{YY} {h12}:{m} {am} {Zabbr}"
        )
        archivedByUser {
          id
          firstName
          lastName
          email
        }
        insertedAt
        updatedAt
        webformSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface Data {
  webformSubmissionTags: Paginated<TagModel>;
  webformSubmissions: Paginated<WebformSubmissionModel>;
}

export interface WebformSubmissionModel {
  id: string;
  caseReferenceNumber: string;
  memberFirstName?: string;
  memberLastName?: string;
  memberDob: string;
  healthPlan?: {
    id: string;
    name: string;
  };
  lineOfBusiness?: string;
  timePreference?: TimePreferenceModel;
  requestingProviderName?: string;
  requestingProviderCredential?: string;
  nameOfContactPerson?: string;
  whoIsPerformingP2p?: string;
  otherP2pContactName?: string;
  otherP2pContactCredential?: string;
  contactPersonLocation?: string;
  contactPhoneNumber?: {
    formatted: string;
    raw: string;
  };
  contactPhoneNumberExtension?: string;
  alternateContactPhoneNumber?: {
    formatted: string;
    raw: string;
  };
  alternateContactPhoneNumberExtension?: string;
  contactEmail?: string;
  contactInstructions?: string;
  status: string;
  archivedAt?: string;
  archivedAtString?: string;
  archivedByUser?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  ohioMemberConsentGiven?: string;
  procedurePerformed?: string;
  dateOfService?: string;
  insertedAt: string;
  updatedAt: string;
  webformSubmissionTags: TagModel[];
}

export interface TagModel {
  id: string;
  label: string;
  color: string;
}

export interface TimePreferenceModel {
  preferredDays: Day[];
  preferredTimes: string[];
  timeZoneName: string;
}

const updateQuery = makeAppendItems<Data>("webformSubmissions");

/**
 * WebformSubmissionRow.
 */

interface WebformSubmissionRowProps {
  webformSubmission: WebformSubmissionModel;
  allWebformSubmissionTags: TagModel[];
  onClick?(): void;
  onRemoveTag(webformSubmissionId: string, tagId: string): void;
  onAddTag(webformSubmissionId: string, tagId: string): void;
}

const WebformSubmissionRow: FC<WebformSubmissionRowProps> = (props) => {
  const {
    webformSubmission,
    allWebformSubmissionTags,
    onClick,
    onAddTag,
    onRemoveTag,
  } = props;
  const memberName =
    webformSubmission.memberFirstName && webformSubmission.memberLastName
      ? `${webformSubmission.memberLastName}, ${webformSubmission.memberFirstName}`
      : "-";

  const availableTags = unusedTags(
    webformSubmission.webformSubmissionTags,
    allWebformSubmissionTags
  );

  return (
    <div className="WorklistPage__tr" role="link" onClick={onClick}>
      <div
        className="WorklistPage__td WorklistPage__td--case_number"
        style={{ maxWidth: 160, flexBasis: 160 }}
      >
        <p className="WorklistPage__emph">
          {webformSubmission.caseReferenceNumber}
        </p>
      </div>
      <div className="WorklistPage__td">
        <p className="WorklistPage__emph capitalize">{memberName}</p>
        <p className="WorklistPage__sub">DOB: {webformSubmission.memberDob}</p>
      </div>
      <div className="WorklistPage__td">{webformSubmission.status}</div>
      <div
        className="WorklistPage__td cursor-default"
        onClick={stopPropogation}
      >
        <div className="flex">
          <div className="flex flex-wrap flex-grow">
            {webformSubmission.webformSubmissionTags.map((tag) => (
              <div key={tag.id} className="inline-block">
                <Tag
                  label={tag.label}
                  color={tag.color}
                  onDelete={() => onRemoveTag(webformSubmission.id, tag.id)}
                />
              </div>
            ))}
          </div>
          <div className="flex-shrink-0">
            <AddTagDropdown
              items={availableTags}
              onSelect={(tag) => onAddTag(webformSubmission.id, tag.id)}
            />
          </div>
        </div>
      </div>
      <div className="WorklistPage__td">
        <p className="WorklistPage__emph">
          {webformSubmission.healthPlan?.name}
        </p>
      </div>
      <div className="WorklistPage__td">
        {webformSubmission.requestingProviderName ||
          webformSubmission.nameOfContactPerson ||
          "-"}
      </div>

      <div className="WorklistPage__td WorklistPage__td--health_plan">
        <p className="WorklistPage__emph">
          {mmDdYyyy(webformSubmission.insertedAt)}
          {" - "}
          {hMmA(webformSubmission.insertedAt)}
        </p>
      </div>
      {/* <div className="WorklistPage__td">
        <p>{createdByUser.organization.name}</p>
      </div> */}
      {/* <div className="WorklistPage__td WorklistPage__td--date">
        <p className="WorklistPage__emph">
          {format(parse(appointmentRequest.insertedAt), "M/D/YY")}
        </p>
        <p className="WorklistPage__sub">
          at {format(parse(appointmentRequest.insertedAt), "h:mm a")}
        </p>
      </div> */}
    </div>
  );
};

/**
 * NoResults.
 */

const NoResults: FC = () => {
  return (
    <div className="WorklistPage__no-results">
      <div className="WorklistPage__no-results__icon-container">
        <FAIcon icon="search" />
      </div>
      <h1>No matching webform submissions</h1>
    </div>
  );
};

/**
 * WebformSubmissionsPageProps.
 */

type ActiveModal = { modal: "VIEW"; webformSubmission: WebformSubmissionModel };

interface WebformSubmissionsPageProps {}

export const WebformSubmissionsPage: FC<WebformSubmissionsPageProps> = () => {
  const [filter, setFilter] = useState<Filter>({
    team: "ALL",
    status: "open",
    caseReferenceNumber: "",
    tagIds: [],
  });
  const { data, loading, error, refetch, fetchMore } = useQuery<Data>(
    WEBFORM_SUBMISSIONS,
    {
      variables: { first: PAGE_SIZE, filter },
      fetchPolicy: "network-only",
    }
  );

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const openModal = useCallback(
    (webformSubmission: WebformSubmissionModel) => {
      return setActiveModal({ modal: "VIEW", webformSubmission });
    },
    [setActiveModal]
  );

  const [addTagMut] = useMutation<AddMutData>(ADD_TAG);
  const [removeTagMut] = useMutation<RemoveMutData>(REMOVE_TAG);

  const toast = useToast();

  const onAddTag = useCallback(
    async (webformSubmissionId: string, tagId: string) => {
      const variables = {
        webformSubmissionId,
        webformSubmissionTagId: tagId,
      };

      try {
        const { data } = await addTagMut({ variables });

        for (let error of data?.addWebformSubmissionTag.errors || []) {
          toast.warning(error.message);
        }
        // NB: No op on success
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
      }
    },
    [addTagMut, toast]
  );

  const onRemoveTag = useCallback(
    async (webformSubmissionId: string, tagId: string) => {
      const variables = {
        webformSubmissionId,
        webformSubmissionTagId: tagId,
      };

      try {
        const { data } = await removeTagMut({ variables });

        for (let error of data?.removeWebformSubmissionTag.errors || []) {
          toast.warning(error.message);
        }
        // NB: No op on success
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
      }
    },
    [removeTagMut, toast]
  );

  return (
    <>
      <NavTitle title="Webform Submissions" />
      <ViewWebformSubmissionModal
        isOpen={activeModal?.modal === "VIEW"}
        onClose={closeModal}
        webformSubmission={activeModal?.webformSubmission}
        onSuccess={refetch}
      />
      <div className="_WebformSubmissionsPage container px-4 pb-8 mx-auto">
        <div className="py-4">
          <FilterPanel
            value={filter}
            onChange={setFilter}
            isLoading={loading}
            filterData={{
              tags: data?.webformSubmissionTags.items,
            }}
          />
        </div>

        <div className="bg-white border rounded-lg shadow-lg">
          {error || (!data && !loading) ? (
            <div style={{ padding: "1.5rem" }}>
              <h1>Error Loading</h1>
            </div>
          ) : (
            <div>
              <div className="WorklistPage__table">
                <div className="WorklistPage__thead">
                  <div className="WorklistPage__tr">
                    <div
                      className="WorklistPage__td WorklistPage__td--case_number"
                      style={{ maxWidth: 160, flexBasis: 160 }}
                    >
                      Case #
                    </div>
                    <div className="WorklistPage__td">Member Name</div>
                    <div className="WorklistPage__td">Status</div>
                    <div className="WorklistPage__td">Tags</div>
                    <div className="WorklistPage__td">Health Plan</div>
                    <div className="WorklistPage__td">Request By</div>
                    {/* <div className="WorklistPage__td">Requesting Org</div> */}
                    <div className="WorklistPage__td">Received At</div>
                  </div>
                </div>
                {loading ? (
                  <div className="WorklistPage__tbody">
                    <h1>Loading...</h1>
                  </div>
                ) : error || !data?.webformSubmissions ? (
                  <div style={{ padding: "1.5rem" }}>
                    <h1>Error Loading</h1>
                  </div>
                ) : (
                  <div className="WorklistPage__tbody">
                    {data.webformSubmissions.items.length === 0 ? (
                      <>
                        <NoResults />
                        {filter.caseReferenceNumber ? (
                          <div>
                            <button
                              type="button"
                              className="a"
                              onClick={() =>
                                setFilter({
                                  ...filter,
                                  caseReferenceNumber: "",
                                })
                              }
                            >
                              Clear search term
                            </button>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {data.webformSubmissions.items.map(
                          (webformSubmission) => (
                            <WebformSubmissionRow
                              key={webformSubmission.id}
                              onClick={() => openModal(webformSubmission)}
                              webformSubmission={webformSubmission}
                              allWebformSubmissionTags={
                                data.webformSubmissionTags.items
                              }
                              onAddTag={onAddTag}
                              onRemoveTag={onRemoveTag}
                            />
                          )
                        )}
                      </>
                    )}
                    <div className="WorklistPage__load-more">
                      {!data.webformSubmissions.endOfList ? (
                        <Button
                          type="button"
                          onClick={() =>
                            fetchMore({
                              query: WEBFORM_SUBMISSIONS,
                              variables: {
                                first: PAGE_SIZE,
                                after: data.webformSubmissions.cursor,
                                filter,
                              },
                              updateQuery,
                            })
                          }
                        >
                          Load More
                        </Button>
                      ) : (
                        <p className="text-gray-500"> End of List</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function unusedTags(currentTags: TagModel[], allTags: TagModel[]) {
  const currentIds = currentTags.map((t) => t.id);
  return allTags.filter((t) => !currentIds.includes(t.id));
}
