import React, { FC, useEffect } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { ConfirmationSuccess } from "./ConfirmationSuccess";
import { ConfirmationFailure } from "./ConfirmationFailure";

const CONFIRM_ACCOUNT = gql`
  mutation ConfirmAccount($id: UUID4!, $token: String!) {
    confirmAccount(id: $id, token: $token) {
      success
      failureReason
    }
  }
`;

interface MutationData {
  confirmAccount: {
    success: boolean;
    failureReason?: FAILURE_REASON;
  };
}

type FAILURE_REASON = "ALREADY_CONFIRMED" | "INVALID_TOKEN" | "TOKEN_EXPIRED";

const DisplayFailure: React.SFC<{ reason: FAILURE_REASON }> = ({ reason }) => {
  switch (reason) {
    case "ALREADY_CONFIRMED":
      return <ConfirmationSuccess />;
    case "TOKEN_EXPIRED":
      return <ConfirmationFailure message="This link has expired" />;
    case "INVALID_TOKEN":
      return <ConfirmationFailure message="Invalid verification URL" />;
    default:
      return <ConfirmationFailure message="Something went wrong" />;
  }
};

interface ConfirmAccountPageProps {}

export const ConfirmAccountPage: FC<ConfirmAccountPageProps> = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);

  const [confirmAccount, { data, loading, error }] = useMutation<MutationData>(
    CONFIRM_ACCOUNT
  );

  useEffect(() => {
    confirmAccount({ variables: { id, token } });
  });

  return (
    <section className="ConfirmAccountPage hero is-fullheight is-medium is-bold">
      <div className="hero-body">
        <div className="container mx-auto">
          <div className="columns is-centered">
            {loading || (!data && !error) ? (
              <h1>Loading</h1>
            ) : error ? (
              <div style={{ padding: "1.5rem" }}>
                <h1>Failed to Load</h1>
              </div>
            ) : data && data.confirmAccount ? (
              data.confirmAccount.success ? (
                <ConfirmationSuccess />
              ) : data.confirmAccount.failureReason ? (
                <DisplayFailure reason={data.confirmAccount.failureReason} />
              ) : (
                <h1>
                  {/* This should never happen. "Error Code 55" is arbitrary, but grep-able */}
                  Something Went Wrong. Error Code 55.
                </h1>
              )
            ) : (
              <h1>
                {/* This should never happen. "Error Code 64" is arbitrary, but grep-able */}
                Something Went Wrong. Error Code 64.
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
