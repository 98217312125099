import React from "react";
import "./DropdownMenu.css";

const DropdownContent: React.StatelessComponent<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => (
  <div className="DropdownMenu__contentWrapper">
    <div className="DropdownMenu__content">{children}</div>
  </div>
);

DropdownContent.displayName = "DropdownContent";

export { DropdownContent };
