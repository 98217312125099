import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import ReactModal from "react-modal";
import noWifiSrc from "./no_wifi.svg";
import { Button } from "components/Button";

const GET_NETWORK_NOTICE_ACTIVE = gql`
  query NetworkNoticeActive {
    networkStatus @client {
      noticeActive
    }
  }
`;

interface Data {
  networkStatus: {
    noticeActive: boolean;
  };
}

type NetworkStatusNotifierProps = {};

const NetworkStatusNotifier: React.SFC<NetworkStatusNotifierProps> = () => {
  return (
    <div className="NetworkStatusNotifier">
      <Query<Data> query={GET_NETWORK_NOTICE_ACTIVE}>
        {({ data, client }) => {
          const closeModal = () =>
            client.writeData({
              data: {
                networkStatus: {
                  __typename: "NetworkStatus",
                  noticeActive: false,
                },
              },
            });
          if (!data) {
            return null;
          }
          return (
            <>
              <ReactModal
                isOpen={data.networkStatus.noticeActive}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
                closeTimeoutMS={150}
              >
                <div
                  className="NetworkStatusNoticeModal"
                  style={{ width: 500 }}
                >
                  <div className="text-center bg-white rounded-lg shadow-lg">
                    <div style={{ padding: "2.5rem 2rem", margin: "0 auto" }}>
                      <img
                        className="mx-auto"
                        style={{ width: 130 }}
                        src={noWifiSrc}
                        alt="No network connection"
                      />
                    </div>
                    <section style={{ padding: "0 1.5rem 2rem" }}>
                      <h1 style={{ fontSize: "1.5rem", fontWeight: 600 }}>
                        Couldn't reach the internet
                      </h1>
                      <p style={{ fontSize: "1.25rem" }}>
                        It seems like you're offline. Please check your internet
                        connection, and then try again.
                      </p>
                    </section>
                    <section style={{ padding: "0 1.5rem 2rem" }}>
                      <Button
                        type="button"
                        kind="primary"
                        color="gold"
                        onClick={closeModal}
                      >
                        Dismiss
                      </Button>
                    </section>
                  </div>
                </div>
              </ReactModal>
            </>
          );
        }}
      </Query>
    </div>
  );
};

export { NetworkStatusNotifier };
