import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthConsumer } from "../AuthContext";

const PrivateRoute: React.SFC<RouteProps> = ({ render, ...rest }) => (
  <AuthConsumer>
    {({ token }) => (
      <Route
        {...rest}
        render={props =>
          !token ? (
            <Redirect
              to={{
                pathname: "/auth/sign_in",
                state: { from: props.location }
              }}
            />
          ) : render ? (
            render(props)
          ) : rest.component ? (
            <rest.component {...props} />
          ) : null
        }
      />
    )}
  </AuthConsumer>
);

export { PrivateRoute };
