import { FC } from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import evicoreLogoSrc from "images/eviCore_big_logo.png";
import { Link } from "react-router-dom";

export const ForgotPasswordPage: FC = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <img
        className="mx-auto mt-6"
        src={evicoreLogoSrc}
        alt="logo"
        width="200"
      />

      <div className="md:px-4 w-full max-w-sm mx-auto mt-10 text-left">
        <ForgotPasswordForm />

        <div className="my-4 text-sm text-center">
          <Link className="link ml-2 leading-loose" to="/auth/old_sign_in">
            Sign in with your email and password
          </Link>
          <p className="mb-2">
            Using SSO?
            <Link className="link ml-2" to="/auth/sign_in">
              Sign in with SSO
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
