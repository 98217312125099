import { FC } from "react";
import { FieldArray, Formik } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import { ORGANIZATION_PROVIDERS } from "../Providers";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { VerticalField } from "components/formik/FieldStructure";
import { FAIcon } from "components/FAIcon";
import { SelectInput } from "components/formik/SelectField";
import { Button } from "components/Button";

const CREATE_PROVIDER = gql`
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface MutationData {
  createProvider: {
    errors?: InputError[];
  };
}

const STATES_AND_SKILLS = gql`
  {
    states {
      id
      name
      abbreviation
    }
    skills {
      id
      name
    }
  }
`;

interface StatesData {
  states: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
  skills: {
    id: string;
    name: string;
  }[];
}

interface AddProviderFormProps {
  onSuccess(): void;
}

export const AddProviderForm: FC<AddProviderFormProps> = (props) => {
  const { onSuccess } = props;
  const toast = useToast();
  const { data, loading } = useQuery<StatesData>(STATES_AND_SKILLS);
  const [createProvider] = useMutation<MutationData>(CREATE_PROVIDER);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        skillIds: [""],
        stateIds: [""],
        iexId: "",
        email: "",
        domainId: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        iexId: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        domainId: Yup.string().required("Required"),
        skillIds: Yup.array().of(Yup.string().required("Required")),
        stateIds: Yup.array().of(Yup.string().required("Required")),
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus({ errors: null });
        createProvider({
          variables: { input: values },
          refetchQueries: [{ query: ORGANIZATION_PROVIDERS }],
        }).then((resp) => {
          if (resp?.data?.createProvider.errors) {
            setStatus({
              errors: resp.data.createProvider.errors,
            });
          } else {
            toast.success(`Added Provider`);
            onSuccess();
          }
          setSubmitting(false);
        });
      }}
    >
      {({ values, status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="gap-x-2 grid grid-cols-2">
            <TextField name="firstName" label="First Name" />
            <TextField name="lastName" label="Last Name" />
          </div>
          <div className="mt-3">
            <TextField name="email" label="Email" />
          </div>
          <div className="mt-3">
            <TextField name="domainId" label="Domain ID" />
          </div>
          <div className="mt-3">
            <TextField name="iexId" label="IEX ID" />
          </div>

          <div className="mt-3">
            <VerticalField label="Skills">
              <FieldArray
                name="skillIds"
                render={(arrayHelpers) => (
                  <div>
                    {values.skillIds && values.skillIds.length > 0 ? (
                      <div className="mb-2">
                        {values.skillIds.map((_skillId, index) => (
                          <div key={index} className="mb-2">
                            <div className="flex items-center">
                              <div className="flex-grow">
                                <SelectInput
                                  name={`skillIds.${index}`}
                                  options={
                                    data?.skills?.map((s) => ({
                                      value: s.id,
                                      label: s.name,
                                    })) || []
                                  }
                                  isLoading={loading}
                                />
                              </div>
                              <div className="ml-2">
                                <Button
                                  type="button"
                                  kind="tertiary"
                                  color="red"
                                  size="sm"
                                  className="border"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FAIcon icon="times" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="text-center">
                          <Button
                            type="button"
                            kind="tertiary"
                            color="blue"
                            size="xs"
                            onClick={() => arrayHelpers.push("")}
                          >
                            Add another skill
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <Button
                          type="button"
                          kind="tertiary"
                          color="blue"
                          size="xs"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add a skill
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </VerticalField>
          </div>

          <div className="mt-3">
            <VerticalField label="State Licensures">
              <FieldArray
                name="stateIds"
                render={(arrayHelpers) => (
                  <div>
                    {values.stateIds && values.stateIds.length > 0 ? (
                      <div className="mb-2">
                        {values.stateIds.map((_skillId, index) => (
                          <div
                            key={index}
                            style={{
                              marginBottom: "0.5rem",
                            }}
                          >
                            <div className="flex items-center">
                              <div className="flex-grow">
                                <SelectInput
                                  name={`stateIds.${index}`}
                                  options={
                                    data?.states?.map((s) => ({
                                      value: s.id,
                                      label: s.name,
                                    })) || []
                                  }
                                  isLoading={loading}
                                />
                              </div>
                              <div className="ml-3">
                                <Button
                                  type="button"
                                  kind="tertiary"
                                  color="red"
                                  size="sm"
                                  className="border"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FAIcon icon="times" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="text-center">
                          <Button
                            type="button"
                            kind="tertiary"
                            color="blue"
                            size="xs"
                            onClick={() => arrayHelpers.push("")}
                          >
                            Add another state licensure
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <Button
                          type="button"
                          kind="tertiary"
                          color="blue"
                          size="xs"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add a state licensure
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </VerticalField>
          </div>

          <div className="mt-6 text-center">
            <Button
              type="submit"
              kind="primary"
              color="gold"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Create Provider
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
