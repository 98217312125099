import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./WizardStep.css";

type WizardStepContainerProps = {
  children: React.ReactNode;
};

export const WizardStepContainer: React.SFC<WizardStepContainerProps> = ({
  children
}) => <div className="WizardContainer">{children}</div>;

type WizardStepParamsType = {
  forStep: number;
  currentStep: number;
  fullWidth?: boolean;
  children: React.ReactNode;
};

const propTypes: React.ValidationMap<WizardStepParamsType> = {
  forStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const WizardStep: React.SFC<WizardStepParamsType> = ({
  forStep: myStep,
  currentStep,
  fullWidth = false,
  children
}) => (
  <div
    className={cx("WizardStep", {
      past: myStep < currentStep,
      future: myStep > currentStep,
      full_width: fullWidth
    })}
  >
    {children}
  </div>
);

WizardStep.propTypes = propTypes;

WizardStep.defaultProps = {
  fullWidth: false
};

export { WizardStep };
