import React, { useRef, FC } from "react";
import { usePopper } from 'react-popper';
import { useCombobox } from 'downshift';
import styled from 'styled-components/macro';
import { FAIcon } from 'components/FAIcon';

const Input = styled.input`
  padding-left: 72px !important;
`;

const Swatch = styled.li`
  width: 12.5%;
  height: 0;
  padding-bottom: 12.5%;
  background-color: ${(props) => props.color};

  &:hover {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%),
              inset 0 0 0px 3px #fff,
              0 2px 4px -1px rgb(0 0 0 / 6%) !important;
    z-index: 10;
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const swatches = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
];

export interface ColorSwatchPickerProps {
  value: string;
  inputProps?: Omit<Partial<React.InputHTMLAttributes<HTMLInputElement>>, "onChange">;
  className?: string;
  placeholder?: string;
  onChange(value: string): void;
  onBlur?(e: any): void;
  disabled?: boolean;
};

export const ColorSwatchPicker: FC<ColorSwatchPickerProps> = props => {
  const {
    value,
    onChange,
    onBlur,
    inputProps = {},
    className = '',
    placeholder = '#123ABC',
    disabled = false
  } = props;

  const disclosureRef = useRef(null);
  const popoverRef = useRef(null);
  const { styles, attributes } = usePopper(
    disclosureRef.current,
    popoverRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    getToggleButtonProps,
    openMenu,
  } = useCombobox({
    items: swatches,
    onInputValueChange({ inputValue }) {
      onChange(inputValue || '');
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        onChange(selectedItem);
      }
    },
    itemToString(item) {
      return item || "";
    },
  });

  return (
    <div className="_ColorSwatchPicker">
      <div {...getComboboxProps({ className: 'relative' })}>
        <button
          type="button"
          className="rounded-l-md absolute inset-y-0 left-0 flex items-center p-1 bg-gray-100 border"
          {...getToggleButtonProps({
            onClick: () => {
              if (!isOpen) {
                openMenu();
              }
            }
          })}
        >
          <div
            className="w-8 h-8 border rounded shadow-inner"
            style={{ backgroundColor: value }}
          />
          <span className="ml-2 text-gray-400">
            <FAIcon icon="chevron-down" />
          </span>
        </button>
        <Input
          className=""
          {...getInputProps({
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
            onClick: () => {
              if (!isOpen) {
                openMenu();
              }
            },
            onBlur,
            className: `${className} focus:border-blue-300 focus:outline-none focus:shadow-outline-blue sm:leading-5 sm:text-sm block w-full h-auto px-3 py-2 m-0 font-mono placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none`,
            placeholder,
            disabled,
            ...inputProps
          })}
        />
      </div>
      <div className="relative z-10">
        <div
          style={styles.popper}
          {...attributes.popper}
          {...getMenuProps({ ref: popoverRef, className: ' w-full' })}
        >
          <ul
            className={
              isOpen
                ? 'flex flex-wrap p-1 mt-1 bg-white border rounded shadow-lg'
                : 'hidden'
            }
          >
            {isOpen &&
              swatches.map((item, index) => (
                <Swatch
                  key={`${item}${index}`}
                  color={item}
                  {...getItemProps({
                    item,
                    index
                  })}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
