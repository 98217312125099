import { useChannelPresence } from "./useChannelPresence";

export function useAppointmentRequestsPresence() {
  const { presenceState } = useChannelPresence(
    `appointment_request:lobby`,
    transformData
  );

  return presenceState;
}

export interface AppointmentRequestsPresenceData {
  onlineUsers: {
    [key: string]: any;
  };
  openAppointmentRequests: {
    [key: string]: Array<{
      user_id: string;
      opened_at: string;
    }>;
  };
}

function transformData(inputData: any[]): AppointmentRequestsPresenceData {
  const result: AppointmentRequestsPresenceData = {
    onlineUsers: {},
    openAppointmentRequests: {},
  };

  // Helper function to compare timestamps
  const isEarlier = (timestamp1: string, timestamp2: string): boolean =>
    parseInt(timestamp1) < parseInt(timestamp2);

  inputData.forEach((item: any) => {
    // Add user to onlineUsers
    result.onlineUsers[item.user.id] = item.user;

    // Process metas
    item.metas.forEach((meta: any) => {
      if (meta.appointment_request_id) {
        const requestId = meta.appointment_request_id;
        const userId = meta.user_id;

        if (!result.openAppointmentRequests[requestId]) {
          result.openAppointmentRequests[requestId] = [];
        }

        // Check if an entry for this user already exists
        const existingEntry = result.openAppointmentRequests[requestId].find(
          (entry) => entry.user_id === userId
        );

        if (!existingEntry) {
          // If no entry exists, add this one
          result.openAppointmentRequests[requestId].push({
            user_id: userId,
            opened_at: meta.online_at,
          });
        } else if (isEarlier(meta.online_at, existingEntry.opened_at)) {
          // If an entry exists but this one is earlier, update it
          existingEntry.opened_at = meta.online_at;
        }
      }
    });
  });

  return result;
}
