import React, { useContext } from "react";
// import "./Toast.css";

export interface ToastMessage {
  type: "info" | "success" | "warning" | "danger";
  key: number;
  message: string;
}

interface ToastFunction {
  (message: string): void;
}

interface ToastContextState {
  items: ToastMessage[];
  leaving: ToastMessage[];
  removeToast: (toast: ToastMessage) => void;
  leave: (toast: ToastMessage) => void;
  toastFunctions: {
    info: ToastFunction;
    success: ToastFunction;
    warning: ToastFunction;
    danger: ToastFunction;
  };
}

let id = 0;

// Create a context for the Toast with [] as the default value.
const ToastContext = React.createContext<ToastContextState>({
  items: [],
  leaving: [],
  removeToast: () => null,
  leave: () => null,
  toastFunctions: {
    info: () => null,
    success: () => null,
    warning: () => null,
    danger: () => null
  }
});

export function useToast() {
  const { toastFunctions } = useContext(ToastContext);
  return toastFunctions;
}

class ToastProvider extends React.Component<{}, ToastContextState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      items: [],
      leaving: [],
      removeToast: this.removeToast,
      leave: this.leave,
      toastFunctions: {
        info: (message: string) =>
          this.addToast({ type: "info", key: id++, message }),
        success: (message: string) =>
          this.addToast({ type: "success", key: id++, message }),
        warning: (message: string) =>
          this.addToast({ type: "warning", key: id++, message }),
        danger: (message: string) =>
          this.addToast({ type: "danger", key: id++, message })
      }
    };
  }

  addToast = (toast: ToastMessage) => {
    console.log("adding", toast.key);
    this.setState(state => ({ items: [...state.items, toast] }));
  };

  removeToast = (toast: ToastMessage) => {
    this.setState(state => ({
      items: state.items.filter(i => i.key !== toast.key),
      leaving: [toast, ...state.leaving]
    }));
  };

  leave = (toast: ToastMessage) => {
    this.setState(state => ({
      leaving: state.leaving.filter(i => i.key !== toast.key)
    }));
  };

  render() {
    const { children } = this.props;
    return (
      <ToastContext.Provider value={this.state}>
        {children}
      </ToastContext.Provider>
    );
  }
}

const ToastConsumer = ToastContext.Consumer;

interface InjectedToasterProps {
  info: ToastFunction;
  success: ToastFunction;
  warning: ToastFunction;
  danger: ToastFunction;
}

interface ToasterProps {
  children: (props: InjectedToasterProps) => JSX.Element;
}

/**
 * Injects functions to flash toast messages into a render prop.
 *
 * Example usage:
```
<Toaster>
  {(toast) => (
    <button onClick={() => toast.success("Something succeeded!")}>
      Click me
    </button>
  )}
</Toaster>
```
 */
class Toaster extends React.Component<ToasterProps> {
  render() {
    const { children } = this.props;
    return (
      <ToastConsumer>
        {({ toastFunctions }) => children(toastFunctions)}
      </ToastConsumer>
    );
  }
}

export { ToastProvider, ToastConsumer, Toaster };
