import React, { FC } from "react";
import qs from "query-string";
import { coalesceString } from "lib/coalesce";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { AuthConsumer } from "components/AuthContext";
import { InvitationRegisterForm } from "./InvitationRegisterForm";
import imgSrc from "images/identity_form_min.svg";
import evicoreLogoSrc from "images/eviCore_big_logo.png";

const QUERY = gql`
  query FetchInvitedUser($id: UUID4!, $token: String!) {
    timeZoneNames
    invitedUser(id: $id, token: $token) {
      id
      email
      firstName
      lastName
      timeZoneName
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  invitedUser: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    timeZoneName: string;
    organization: {
      id: string;
      name: string;
    };
  };
}

const InvalidPageMessage = () => (
  <div>
    <img src={evicoreLogoSrc} alt="logo" width="200" />
    <div>
      <h1 className="is-size-2">Invalid Password Reset URL</h1>
      <p>
        <Link to="/auth/forgot_password">Click Here</Link> to request a new
        password reset link
      </p>
    </div>
  </div>
);

interface InvitationRegisterPageProps {}

export const InvitationRegisterPage: FC<InvitationRegisterPageProps> = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);
  const { data, loading, error } = useQuery<Data>(QUERY, {
    variables: { id, token },
  });

  if (!(id && token)) {
    return <InvalidPageMessage />;
  }

  return (
    <div className="InvitationRegisterPage">
      <h1 className="title">
        <img src={evicoreLogoSrc} alt="logo" width="200" />
      </h1>
      {loading ? (
        <h1>Loading...</h1>
      ) : error || !(data && data.invitedUser) ? (
        <p>Failed to load.</p>
      ) : (
        <div className="bg-white rounded-lg shadow-lg">
          <div className="columns is-gapless">
            <div
              className="column is-vcentered"
              style={{
                backgroundColor: "rgb(238, 242, 255)",
                borderTopLeftRadius: 6,
                borderBottomLeftRadius: 6,
              }}
            >
              <figure className="image" style={{ padding: "10%" }}>
                <img src={imgSrc} alt="Complete my account illustration" />
              </figure>
            </div>
            <div className="column text-left">
              <div className="section">
                <h4 className="title is-4 text-center">Finish Account Setup</h4>
                <ul>
                  <li>
                    <b>Organization:</b> {data.invitedUser.organization.name}
                  </li>
                  <li>
                    <b>Name:</b> {data.invitedUser.firstName}{" "}
                    {data.invitedUser.lastName}
                  </li>
                  <li>
                    <b>Email:</b> {data.invitedUser.email}
                  </li>
                </ul>
                <AuthConsumer>
                  {({ signIn }) => (
                    <InvitationRegisterForm
                      id={coalesceString(id)}
                      token={coalesceString(token)}
                      timeZoneName={data.invitedUser.timeZoneName}
                      timeZoneNames={data.timeZoneNames}
                      onSignIn={signIn}
                    />
                  )}
                </AuthConsumer>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
