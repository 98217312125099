import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";
import "./NavMenu.css";

export type LinkType = {
  icon: IconProp;
  path: string;
  label: string;
};

interface NavMenuProps {
  title: string;
  links: LinkType[];
}

export const NavMenu: FC<NavMenuProps> = props => {
  const { title, links } = props;
  return (
    <div className="NavMenu">
      <h6 className="is-6 text-gray-600">{title}</h6>
      <ul>
        {links.map(link => (
          <NavLink
            key={link.path}
            to={link.path}
            className="text-gray-800"
            activeClassName="is-active text-blue-500"
          >
            <li>
              <span>
                <FAIcon icon={link.icon} />
              </span>
              {link.label}
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};
