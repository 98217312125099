import { FC, useState, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { translateLOR } from "lib/kentuckyWording";
import { FAIcon } from "components/FAIcon";
import { CircleIcon } from "components/CircleIcon";
import { PreferredDays } from "components/PreferredDays";
import { PreferredTimes } from "components/PreferredTimes";
import { RefNum } from "components/RefNum";
import { Day } from "components/PreferredDays/PreferredDays";
import { DetailList, DetailItem } from "components/DetailList";
import { BorderLabel } from "components/BorderLabel";
import { CopyText } from "components/CopyText";
import { Spinner } from "components/Spinner";
import {
  AppointmentRequestEvent,
  AppointmentRequestEventType,
} from "./AppointmentRequestEvent";
import { Appointments } from "./Appointments";
import { CommentForm } from "./CommentForm";
import { UpdateTimePreferenceModal } from "./UpdateTimePreferenceModal";
import { UpdateContactDetailsModal } from "./UpdateContactDetailsModal";
import { UpdateRequestInformationModal } from "./UpdateRequestInformationModal";
import "./AppointmentRequestShowPage.css";
import { useChannelPresence } from "hooks/useChannelPresence";

export const APPOINTMENT_REQUEST_SHOW_QUERY = gql`
  query getAppointmentRequest($id: UUID4!) {
    appointmentRequest(id: $id) {
      id
      requestingProviderName
      requestingProviderCredential
      nameOfContactPerson
      contactPersonLocation
      whoIsPerformingP2p
      otherP2pContactName
      otherP2pContactCredential
      contactPhoneNumber {
        formatted
        raw
      }
      contactPhoneNumberExtension
      alternateContactPhoneNumber {
        formatted
        raw
      }
      alternateContactPhoneNumberExtension
      contactEmail
      contactInstructions
      callNotes
      insertedAt
      createdByUser {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
      caseProfiles {
        id
        caseReferenceNumber
        memberFirstName
        memberLastName
        memberDob
        externalSystemName
        insurancePlanCode
        levelOfReview {
          value
          label
        }
        modality {
          id
          name
        }
        healthPlan {
          id
          name
        }
        memberState {
          id
          name
        }
      }
      timePreference {
        id
        preferredDays
        preferredTimes
        timeZoneName
      }
      appointments {
        id
        appointmentRequestId
        startDateString: startTimeString(format: "{WDshort} {M}/{D}/{YY}")
        startTimeString(format: "{h12}:{m} {am} {Zabbr}")
        timeRange {
          start
          finish
          duration
        }
        completed
        cancelled
        cancellationReason
        missed
        missedReason
        requiresReschedule
        requiresReschedule
        replacedByAppointmentId
        receivingProvider {
          id
          nameWithAppellation
          firstName
          lastName
        }
      }
      appointmentRequestEvents {
        id
        data {
          __typename
          ... on AppointmentRequestScheduledEventData {
            eventType
            appointmentTime
            scheduledWith
          }
          ... on AppointmentRequestAppointmentCancelledEventData {
            eventType
            wasScheduledWith
            cancellationReason
            requiresReschedule
            appointmentId
          }
          ... on AppointmentRequestAppointmentMissedEventData {
            eventType
            wasScheduledWith
            missedReason
            requiresReschedule
            appointmentId
          }
          ... on AppointmentRequestCommentEventData {
            eventType
            comment
          }
          ... on AppointmentRequestAppointmentCompletionToggledEventData {
            eventType
            completed
          }
        }
        insertedAt
        rollupId
        user {
          __typename
          id
          firstName
          lastName
          organization {
            id
            name
          }
        }
        agent {
          __typename
          id
          firstName
          lastName
          email
        }
        type
      }
    }
  }
`;

interface Data {
  appointmentRequest: AppointmentRequestModel;
}

export interface AppointmentRequestModel {
  id: string;
  requestingProviderName: string;
  requestingProviderCredential: string | void;
  nameOfContactPerson?: string;
  whoIsPerformingP2p: string | void;
  otherP2pContactName: string | void;
  otherP2pContactCredential: string | void;
  contactPersonLocation?: string;
  contactPhoneNumber: {
    formatted: string;
    raw: string;
  };
  contactPhoneNumberExtension?: string;
  alternateContactPhoneNumber?: {
    formatted: string;
    raw: string;
  };
  alternateContactPhoneNumberExtension?: string;
  contactEmail?: string;
  contactInstructions?: string;
  callNotes: string[];
  createdByUser: {
    id: string;
    firstName: string;
    lastName: string;
    organization: {
      id: string;
      name: string;
    };
  };
  caseProfiles: {
    id: string;
    caseReferenceNumber: string;
    memberFirstName: string;
    memberLastName: string;
    memberDob: string;
    externalSystemName: string;
    insurancePlanCode?: string;
    levelOfReview: {
      value: string;
      label: string;
    };
    modality: {
      id: string;
      name: string;
    };
    healthPlan: {
      id: string;
      name: string;
    };
    memberState: {
      id: string;
      name: string;
    };
  }[];
  timePreference: {
    id: string;
    preferredDays: Day[];
    preferredTimes: string[];
    timeZoneName: string;
  };
  appointments: AppointmentModel[];
  appointmentRequestEvents: AppointmentRequestEventType[];
}

export interface AppointmentModel {
  id: string;
  appointmentRequestId: string;
  startDateString: string;
  startTimeString: string;
  timeRange: {
    start: string;
    finish: string;
    duration: number;
  };
  completed: boolean;
  cancelled: boolean;
  cancellationReason?: string;
  missed: boolean;
  missedReason?: string;
  requiresReschedule: boolean;
  replacedByAppointmentId?: string;
  receivingProvider: {
    id: string;
    nameWithAppellation: string;
    firstName: string;
    lastName: string;
  };
}

export function getRequiresReschedule(appointments: AppointmentModel[]) {
  return !!appointments.find((a) => a.requiresReschedule);
}

/**
 * QuickStatus.
 */

interface QuickStatusProps {
  appointments: AppointmentModel[];
}

const QuickStatus: FC<QuickStatusProps> = (props) => {
  const { appointments } = props;
  if (appointments.length === 0) {
    return null;
  } else {
    const rescheduleRequired = getRequiresReschedule(appointments);
    return (
      <div className="QuickStatus">
        {rescheduleRequired ? (
          <h4>
            <span className="QuickStatus__icon-container">
              <FAIcon icon="exclamation-triangle" />
            </span>
            Reschedule Required
          </h4>
        ) : null}
      </div>
    );
  }
};

/**
 * AppointmentRequestShowPage.
 */

type ActiveModal =
  | "UPDATE_REQUEST_INFORMATION"
  | "UPDATE_TIME_PREFERENCE"
  | "UPDATE_CONTACT_DETAILS";

interface RouteParams {
  id: string;
}

export const AppointmentRequestShowPage: FC = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const match = useRouteMatch<RouteParams>();
  const { id } = match.params;

  useChannelPresence(`appointment_request:${id}`);

  const { data, loading, error, refetch } = useQuery<Data>(
    APPOINTMENT_REQUEST_SHOW_QUERY,
    { variables: { id }, fetchPolicy: "network-only" }
  );

  const requestingProviderName =
    data?.appointmentRequest.requestingProviderName || "";

  const otherP2pContactName =
    data?.appointmentRequest.otherP2pContactName || "";

  return (
    <>
      <UpdateRequestInformationModal
        appointmentRequestId={id}
        isOpen={activeModal === "UPDATE_REQUEST_INFORMATION"}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <UpdateTimePreferenceModal
        appointmentRequestId={id}
        isOpen={activeModal === "UPDATE_TIME_PREFERENCE"}
        onClose={closeModal}
      />
      {/* NB: Removing this from DOM when not open to force the query inside to refetch on open. */}
      {activeModal === "UPDATE_CONTACT_DETAILS" ? (
        <UpdateContactDetailsModal
          isOpen={activeModal === "UPDATE_CONTACT_DETAILS"}
          appointmentRequestId={id}
          onClose={closeModal}
          refetchQueries={[
            {
              query: APPOINTMENT_REQUEST_SHOW_QUERY,
              variables: { id },
            },
          ]}
        />
      ) : null}
      <div className="p-6 text-left">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !(data && data.appointmentRequest) ? (
          <div>
            <h1>Failed to Load</h1>
          </div>
        ) : (
          <div className="gap-x-4 flex">
            <div className="flex-1">
              <div className="p-4 bg-white rounded-lg shadow-lg">
                <div className="gap-x-3 flex items-center">
                  <CircleIcon icon={["far", "address-card"]} />
                  <h4 className="text-2xl font-semibold text-gray-800">
                    Request
                  </h4>
                </div>

                <div className="gap-x-8 flex items-center mt-8">
                  <h5 className="text-xl font-semibold">Request Information</h5>
                  <button
                    type="button"
                    className="a"
                    onClick={() => setActiveModal("UPDATE_REQUEST_INFORMATION")}
                  >
                    <span className="icon-container">
                      <FAIcon icon="pencil-alt" />
                    </span>
                    Edit
                  </button>
                </div>

                <div className="gap-x-3 flex flex-wrap p-3 mt-2">
                  <div className="flex-1">
                    <label className="de-emph-label">Level of Review</label>
                    <p className="emph">
                      {translateLOR(
                        data.appointmentRequest.caseProfiles[0].levelOfReview
                          .label,
                        data.appointmentRequest.caseProfiles[0]
                          .insurancePlanCode,
                        data.appointmentRequest.caseProfiles[0].memberState.name
                      )}
                    </p>
                  </div>
                  <div className="flex-1">
                    <label className="de-emph-label">System Name</label>
                    <p className="emph">
                      {
                        data.appointmentRequest.caseProfiles[0]
                          .externalSystemName
                      }
                    </p>
                  </div>
                </div>

                <h5 className="mt-8 text-xl font-semibold">
                  {data.appointmentRequest.caseProfiles.length > 1
                    ? "Cases"
                    : "Case"}
                </h5>
                {data.appointmentRequest.caseProfiles.map(
                  (caseProfile, idx) => (
                    <div
                      key={caseProfile.id}
                      className="p-4 pb-8 mt-4 border border-gray-200 rounded-lg shadow-md"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <p className="text-sm text-gray-700">Case #{idx + 1}</p>
                        <RefNum refNum={caseProfile.caseReferenceNumber} />
                      </div>
                      <div className="gap-x-3 flex flex-wrap ml-3">
                        <div className="flex-1">
                          <label className="de-emph-label">Member Name</label>
                          <p className="emph leading-tight capitalize">
                            {caseProfile.memberFirstName}{" "}
                            {caseProfile.memberLastName}
                          </p>
                        </div>
                        <div className="flex-1">
                          <label className="de-emph-label">
                            Member Date of Birth
                          </label>
                          <p className="emph leading-tight">
                            {caseProfile.memberDob}
                          </p>
                        </div>
                        <div className="flex-1">
                          <label className="de-emph-label">Member State</label>
                          <p className="emph leading-tight">
                            {caseProfile.memberState.name}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-wrap mt-4 ml-3">
                        <div className="flex-1">
                          <label className="de-emph-label">Health Plan</label>
                          <p className="emph leading-tight">
                            {caseProfile.healthPlan.name}
                          </p>
                        </div>
                        <div className="flex-1">
                          <label className="de-emph-label">Modality</label>
                          <p className="emph leading-tight">
                            {caseProfile.modality.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div className="gap-x-8 flex items-center mt-8">
                  <h5 className="text-xl font-semibold">Contact Information</h5>
                  <button
                    type="button"
                    className="a"
                    onClick={() => setActiveModal("UPDATE_CONTACT_DETAILS")}
                  >
                    <span className="icon-container">
                      <FAIcon icon="pencil-alt" />
                    </span>
                    Edit
                  </button>
                </div>

                <DetailList className="p-3 mt-2">
                  <DetailItem label="Name of Provider on Case" labelWidth={220}>
                    <BorderLabel
                      label="Performing P2P"
                      show={
                        data.appointmentRequest.whoIsPerformingP2p ===
                        "Requesting Provider"
                      }
                    >
                      <span className="capitalize">
                        {requestingProviderName || "-"}
                      </span>
                    </BorderLabel>
                  </DetailItem>
                  <DetailItem label="Contact Person Name" labelWidth={220}>
                    <BorderLabel
                      label="Performing P2P"
                      show={
                        data.appointmentRequest.whoIsPerformingP2p ===
                        "Contact Person"
                      }
                    >
                      <span className="capitalize">
                        {data.appointmentRequest.nameOfContactPerson || "-"}
                      </span>
                    </BorderLabel>
                  </DetailItem>

                  {data.appointmentRequest.whoIsPerformingP2p === "Other" &&
                  otherP2pContactName ? (
                    <DetailItem label="Other P2P Contact" labelWidth={220}>
                      <BorderLabel show label="Performing P2P">
                        <CopyText text={otherP2pContactName}>
                          {otherP2pContactName}
                        </CopyText>
                      </BorderLabel>
                    </DetailItem>
                  ) : null}
                  <DetailItem label="Contact Person Location" labelWidth={220}>
                    {data.appointmentRequest.contactPersonLocation || "-"}
                  </DetailItem>
                  {data.appointmentRequest.contactEmail ? (
                    <DetailItem
                      label="Requesting Provider Email"
                      labelWidth={220}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {data.appointmentRequest.contactEmail}
                      </span>
                    </DetailItem>
                  ) : null}
                  <DetailItem label="Phone Number for P2P" labelWidth={220}>
                    <p style={{ whiteSpace: "nowrap" }}>
                      {data.appointmentRequest.contactPhoneNumber.formatted}{" "}
                      {data.appointmentRequest.contactPhoneNumberExtension ? (
                        <span>
                          {" "}
                          ext.{" "}
                          {data.appointmentRequest.contactPhoneNumberExtension}
                        </span>
                      ) : null}
                    </p>
                  </DetailItem>
                  {data.appointmentRequest.alternateContactPhoneNumber ? (
                    <DetailItem
                      label="Alternate Contact Phone"
                      labelWidth={220}
                    >
                      <p style={{ whiteSpace: "nowrap" }}>
                        {
                          data.appointmentRequest.alternateContactPhoneNumber
                            .formatted
                        }{" "}
                        {data.appointmentRequest
                          .alternateContactPhoneNumberExtension ? (
                          <span>
                            {" "}
                            ext.{" "}
                            {
                              data.appointmentRequest
                                .alternateContactPhoneNumberExtension
                            }
                          </span>
                        ) : null}
                      </p>
                    </DetailItem>
                  ) : null}
                  <DetailItem label="Contact Instructions" labelWidth={220}>
                    {data.appointmentRequest.contactInstructions || "-"}
                  </DetailItem>
                  <DetailItem label="Call Notes" labelWidth={220}>
                    {data.appointmentRequest.callNotes.length > 0 ? (
                      <ul className="px-4 list-disc">
                        {data.appointmentRequest.callNotes.map((note) => (
                          <li key={note}>{note}</li>
                        ))}
                      </ul>
                    ) : (
                      "-"
                    )}
                  </DetailItem>
                </DetailList>

                <div className="gap-x-8 flex items-center mt-8">
                  <h5 className="text-xl font-semibold">Time Preferences</h5>
                  <button
                    type="button"
                    className="a"
                    onClick={() => setActiveModal("UPDATE_TIME_PREFERENCE")}
                  >
                    <span className="icon-container">
                      <FAIcon icon="pencil-alt" />
                    </span>
                    Edit
                  </button>
                </div>

                <div className="p-3">
                  <label className="de-emph-label">Preferred Days</label>
                  <div className="mt-3">
                    <PreferredDays
                      selectedDays={
                        data.appointmentRequest.timePreference.preferredDays
                      }
                    />
                  </div>
                </div>

                <div className="p-3">
                  <div className="flex items-center justify-between">
                    <label className="de-emph-label">Preferred Times</label>
                    <label className="font-semibold text-gray-700">
                      {data.appointmentRequest.timePreference.timeZoneName}
                    </label>
                  </div>

                  <div className="mt-3">
                    <PreferredTimes
                      selectedTimes={
                        data.appointmentRequest.timePreference.preferredTimes
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="p-4 bg-white rounded-lg shadow-lg">
                <div className="gap-x-3 flex items-center justify-between">
                  <div className="gap-x-3 flex items-center">
                    <CircleIcon icon="calendar-alt" />
                    <h4 className="text-2xl font-semibold text-gray-800">
                      Scheduling
                    </h4>
                  </div>

                  <QuickStatus
                    appointments={data.appointmentRequest.appointments}
                  />
                </div>

                <Appointments
                  appointmentRequest={data.appointmentRequest}
                  appointments={data.appointmentRequest.appointments}
                />
              </div>
              <div className="p-4 mt-4 bg-white rounded-lg shadow-lg">
                <div className="gap-x-3 flex items-center">
                  <CircleIcon icon="clipboard-list" />
                  <h4 className="text-2xl font-semibold text-gray-800">
                    Activity
                  </h4>
                </div>
                <div id="appointment-request-events">
                  {data.appointmentRequest.appointmentRequestEvents.map(
                    (event: AppointmentRequestEventType) => (
                      <AppointmentRequestEvent key={event.id} event={event} />
                    )
                  )}
                </div>
                <div className="pt-2">
                  <CommentForm appointmentRequestId={id} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
