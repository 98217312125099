import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { NewTagForm } from "./NewTagForm";

interface NewTagModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const NewTagModal: FC<NewTagModalProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="_NewTagModal">
      <ModalHeader icon="tags" onClose={onClose} title="New Tag" />

      <div className="p-4">
        <NewTagForm onSuccess={onSuccess} />
      </div>
    </Modal>
  );
};
