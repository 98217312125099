import { FC, useCallback } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { WebformSubmissionModel } from "./WebformSubmissionsPage";
import { Modal, ModalHeader } from "components/Modal";
import { DetailItem, DetailList } from "components/DetailList";
import lightFormat from "date-fns/lightFormat";
import parseISO from "date-fns/parseISO";
import { CopyText } from "components/CopyText";
import { useToast } from "layouts/PortalLayout/Toast";
import { PreferredDays } from "components/PreferredDays";
import { PreferredTimes } from "components/PreferredTimes";
import { CircleIcon } from "components/CircleIcon";
import { BorderLabel } from "components/BorderLabel";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
} from "components/Dropdown";

const ARCHIVE_SUBMISSION = gql`
  mutation ArchiveWebformSubmission($id: UUID4!) {
    archiveWebformSubmission(id: $id) {
      errors {
        key
        message
      }
      webformSubmission {
        id
        status
      }
    }
  }
`;

const UNARCHIVE_SUBMISSION = gql`
  mutation UnarchiveWebformSubmission($id: UUID4!) {
    unarchiveWebformSubmission(id: $id) {
      errors {
        key
        message
      }
      webformSubmission {
        id
        status
      }
    }
  }
`;

interface ViewWebformSubmissionModalProps {
  isOpen: boolean;
  onClose(): void;
  webformSubmission?: WebformSubmissionModel;
  onSuccess(): void;
}

export const ViewWebformSubmissionModal: FC<ViewWebformSubmissionModalProps> = (
  props
) => {
  const { isOpen, onClose, webformSubmission, onSuccess } = props;
  const formattedDob =
    webformSubmission?.memberDob &&
    lightFormat(parseISO(webformSubmission.memberDob), "MM/dd/yyyy");
  const memberName =
    webformSubmission?.memberFirstName && webformSubmission?.memberLastName
      ? `${webformSubmission.memberLastName}, ${webformSubmission.memberFirstName}`
      : "-";

  const dateOfService =
    webformSubmission?.dateOfService &&
    lightFormat(parseISO(webformSubmission.dateOfService), "MM/dd/yyyy");

  const requestingProviderName =
    webformSubmission?.requestingProviderName &&
    [
      webformSubmission.requestingProviderName,
      webformSubmission.requestingProviderCredential,
    ]
      .filter(Boolean)
      .join(", ");

  const otherP2pContactName =
    webformSubmission?.otherP2pContactName &&
    [
      webformSubmission.otherP2pContactName,
      webformSubmission.otherP2pContactCredential,
    ]
      .filter(Boolean)
      .join(", ");

  const [archiveSubmission] = useMutation(ARCHIVE_SUBMISSION);
  const [unarchiveSubmission] = useMutation(UNARCHIVE_SUBMISSION);
  const toast = useToast();

  const doArchive = useCallback(() => {
    if (webformSubmission?.id) {
      return archiveSubmission({
        variables: { id: webformSubmission.id },
      }).then((resp) => {
        if (resp.data?.archiveWebformSubmission.webformSubmission) {
          // success
          toast.success("Submission archived.");
          onSuccess();
          onClose();
        }
      });
    }
  }, [toast, webformSubmission, archiveSubmission, onSuccess, onClose]);

  const doUnarchive = useCallback(() => {
    if (webformSubmission?.id) {
      return unarchiveSubmission({
        variables: { id: webformSubmission.id },
      }).then((resp) => {
        if (resp.data?.unarchiveWebformSubmission.webformSubmission) {
          // success
          toast.success("Submission unarchived.");
          onSuccess();
          onClose();
        }
      });
    }
  }, [toast, webformSubmission, unarchiveSubmission, onSuccess, onClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={{ minWidth: 600 }}>
      <ModalHeader
        icon="envelope-open-text"
        title="Webform Submission"
        onClose={onClose}
      />
      <div className="flex justify-end px-4">
        <Dropdown>
          <OptionsButton label="Actions" />
          <DropdownMenu>
            {webformSubmission ? (
              <>
                {webformSubmission.status === "open" ? (
                  <DropdownMenuButton onClick={doArchive}>
                    Archive Submission
                  </DropdownMenuButton>
                ) : (
                  <DropdownMenuButton onClick={doUnarchive}>
                    Unarchive Submission
                  </DropdownMenuButton>
                )}
              </>
            ) : null}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div style={{ padding: "1rem" }}>
        {!!webformSubmission ? (
          <>
            <div>
              {webformSubmission.archivedAtString &&
              webformSubmission.archivedByUser ? (
                <div className="flex p-2 mb-4 bg-purple-100 border border-purple-300 rounded">
                  <CircleIcon
                    icon="archive"
                    color="purple"
                    className="flex-shrink-0"
                  />
                  <div className="ml-4 text-sm text-purple-800">
                    <p>
                      Archived on {webformSubmission.archivedAtString} by{" "}
                      {webformSubmission.archivedByUser.firstName}{" "}
                      {webformSubmission.archivedByUser.lastName} (
                      {webformSubmission.archivedByUser.email})
                    </p>
                  </div>
                </div>
              ) : null}
              <h3 className="my-2 text-lg font-semibold">Case Info</h3>
              <DetailList>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Case Number"
                >
                  <CopyText text={webformSubmission.caseReferenceNumber}>
                    {webformSubmission.caseReferenceNumber}
                  </CopyText>
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Member Name"
                >
                  <CopyText text={memberName}>{memberName}</CopyText>
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Member DOB"
                >
                  {formattedDob ? (
                    <CopyText text={formattedDob}>{formattedDob}</CopyText>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Line of Business"
                >
                  {webformSubmission.lineOfBusiness ? (
                    <CopyText text={webformSubmission.lineOfBusiness}>
                      {webformSubmission.lineOfBusiness}
                    </CopyText>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Health Plan"
                >
                  {webformSubmission.healthPlan?.name ? (
                    <CopyText text={webformSubmission.healthPlan.name}>
                      {webformSubmission.healthPlan.name}
                    </CopyText>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Has this procedure already been performed?"
                >
                  {webformSubmission.procedurePerformed || "-"}
                </DetailItem>
                {webformSubmission.dateOfService ? (
                  <DetailItem
                    style={{ minWidth: 200, maxWidth: 200 }}
                    label="Date of Service"
                  >
                    {dateOfService ? (
                      <CopyText text={dateOfService}>{dateOfService}</CopyText>
                    ) : (
                      "-"
                    )}
                  </DetailItem>
                ) : null}
              </DetailList>
            </div>
            <div
              style={{
                marginTop: "1rem",
                paddingTop: "1rem",
                borderTop: "1px solid #ddd",
              }}
            >
              <h3 className="my-2 text-lg font-semibold">Contact Info</h3>
              <DetailList>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Requesting Provider Name"
                >
                  {requestingProviderName ? (
                    <BorderLabel
                      label="Performing P2P"
                      show={
                        webformSubmission?.whoIsPerformingP2p ===
                        "Requesting Provider"
                      }
                    >
                      <CopyText text={requestingProviderName}>
                        {requestingProviderName}
                      </CopyText>
                    </BorderLabel>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Contact Person Name"
                >
                  {webformSubmission?.nameOfContactPerson ? (
                    <BorderLabel
                      label="Performing P2P"
                      show={
                        webformSubmission.whoIsPerformingP2p ===
                        "Contact Person"
                      }
                    >
                      <CopyText text={webformSubmission.nameOfContactPerson}>
                        {webformSubmission.nameOfContactPerson}
                      </CopyText>
                    </BorderLabel>
                  ) : (
                    "-"
                  )}
                </DetailItem>

                {webformSubmission?.whoIsPerformingP2p === "Other" &&
                otherP2pContactName ? (
                  <DetailItem
                    style={{ minWidth: 200, maxWidth: 200 }}
                    label="Other P2P Contact"
                  >
                    <BorderLabel show label="Performing P2P">
                      <CopyText text={otherP2pContactName}>
                        {otherP2pContactName}
                      </CopyText>
                    </BorderLabel>
                  </DetailItem>
                ) : null}

                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Contact Person Location"
                >
                  {webformSubmission.contactPersonLocation ? (
                    <CopyText text={webformSubmission.contactPersonLocation}>
                      {webformSubmission.contactPersonLocation}
                    </CopyText>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="If this request is for a member in the state of Ohio, has the member given consent for you to request a peer consultation on his/her behalf?"
                >
                  {webformSubmission.ohioMemberConsentGiven || "-"}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Contact Phone Number"
                >
                  {webformSubmission.contactPhoneNumber ? (
                    <>
                      <CopyText
                        text={webformSubmission.contactPhoneNumber.formatted}
                      >
                        {webformSubmission.contactPhoneNumber.formatted}
                      </CopyText>
                      {webformSubmission.contactPhoneNumberExtension ? (
                        <>
                          {" ext "}
                          <CopyText
                            text={webformSubmission.contactPhoneNumberExtension}
                          >
                            {webformSubmission.contactPhoneNumberExtension}
                          </CopyText>
                        </>
                      ) : null}
                    </>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                {webformSubmission.alternateContactPhoneNumber ? (
                  <DetailItem
                    style={{ minWidth: 200, maxWidth: 200 }}
                    label="Alternate Contact Phone Number"
                  >
                    <CopyText
                      text={
                        webformSubmission.alternateContactPhoneNumber.formatted
                      }
                    >
                      {webformSubmission.alternateContactPhoneNumber.formatted}
                    </CopyText>
                    {webformSubmission.alternateContactPhoneNumberExtension ? (
                      <>
                        {" ext "}
                        <CopyText
                          text={
                            webformSubmission.alternateContactPhoneNumberExtension
                          }
                        >
                          {
                            webformSubmission.alternateContactPhoneNumberExtension
                          }
                        </CopyText>
                      </>
                    ) : null}
                  </DetailItem>
                ) : null}
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Contact Email"
                >
                  {webformSubmission.contactEmail ? (
                    <CopyText text={webformSubmission.contactEmail}>
                      {webformSubmission.contactEmail}
                    </CopyText>
                  ) : (
                    "-"
                  )}
                </DetailItem>
                <DetailItem
                  style={{ minWidth: 200, maxWidth: 200 }}
                  label="Contact Instructions"
                >
                  {webformSubmission.contactInstructions || "-"}
                </DetailItem>
              </DetailList>
            </div>
            <div
              style={{
                marginTop: "1rem",
                paddingTop: "1rem",
                borderTop: "1px solid #ddd",
              }}
            >
              {webformSubmission.timePreference ? (
                <>
                  <div style={{ padding: "0.75rem" }}>
                    <label className="de-emph-label">Preferred Days</label>
                    <div style={{ marginTop: "0.75rem" }}>
                      <PreferredDays
                        selectedDays={
                          webformSubmission.timePreference.preferredDays
                        }
                      />
                    </div>
                  </div>
                  <div style={{ padding: "0.75rem" }}>
                    <div className="flex items-center justify-between">
                      <label className="de-emph-label">Preferred Times</label>

                      <label className="block pt-1 pb-3 text-base font-semibold">
                        {webformSubmission.timePreference.timeZoneName}
                      </label>
                    </div>
                    <div className="mt-3">
                      <PreferredTimes
                        selectedTimes={
                          webformSubmission.timePreference.preferredTimes
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center text-gray-700">
                  No indicated time preference
                </p>
              )}
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};
