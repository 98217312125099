import { FC, useState } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { addDays } from "date-fns";
import { SelectInput, StandardOption } from "components/formik/SelectField";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { TextInput } from "components/formik/TextField";
import { Button } from "components/Button";
import { TimeRange } from "pages/AnalyticsPage/types";
import { DateRangeDropdown } from "pages/AnalyticsPage/DateRangeDropdown";
import { useDropdown } from "hooks/useDropdown";
import { ZoomIn } from "components/Animations";
import { iso8601Date } from "lib/dateFormatters";
import { DateMaskInput } from "components/formik/DateMaskField";
import { required, validateLocalDate } from "components/formik/validation";

const FILTER_DATA = gql`
  query FilterData {
    externalSystemNames
    states {
      id
      name
    }
    modalities {
      id
      name
    }
    reviewLevels {
      value
      label
    }
    healthPlans {
      id
      name
    }
  }
`;

interface Data {
  externalSystemNames: string[];
  states: { id: string; name: string }[];
  modalities: { id: string; name: string }[];
  reviewLevels: StandardOption[];
  healthPlans: { id: string; name: string }[];
}

export enum Subset {
  All = "ALL",
  AllOpen = "ALL_OPEN",
  Reschedule = "RESCHEDULE",
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Missed = "MISSED",
  Denied = "DENIED",
  Past = "PAST",
}

export interface Filter extends Record<string, any> {
  subset: Subset;
  caseReferenceNumber?: string;
  levelOfReview?: string;
  caseSystem?: string;
  modalityId?: string;
  healthPlanId?: string;
  appointmentDate?: string | null;
  createdByGroup?: string | null;
}

type SubsetOption = {
  value: Subset;
  label: string;
};

export const defaultFilter: Filter = {
  subset: Subset.AllOpen,
  caseReferenceNumber: "",
  levelOfReview: "ALL",
  caseSystem: "ALL",
  modalityId: "ALL",
  healthPlanId: "ALL",
  appointmentDate: null,
  createdByGroup: "ANY",
  createdAfter: null,
  createdBefore: null,
};

const subsetOptions: SubsetOption[] = [
  {
    value: Subset.All,
    label: "All",
  },
  {
    value: Subset.AllOpen,
    label: "All Open",
  },
  {
    value: Subset.Reschedule,
    label: "Reschedule Queue",
  },
  {
    value: Subset.Scheduled,
    label: "Scheduled",
  },
  {
    value: Subset.Past,
    label: "Scheduled (Past)",
  },
  {
    value: Subset.Completed,
    label: "Completed",
  },
  {
    value: Subset.Cancelled,
    label: "Cancelled",
  },
  {
    value: Subset.Missed,
    label: "Missed",
  },
  // {
  //   value: Subset.Denied,
  //   label: "Denied"
  // }
];

const createdByGroupOptions = [
  { value: "ANY", label: "Any" },
  { value: "CLINICAL", label: "Clinical" },
  { value: "NON_CLINICAL", label: "Non-Clinical" },
  { value: "SELF_SERVE", label: "Self-Serve" },
];

const allOption = {
  value: "ALL",
  label: "All",
};

const now = new Date();
const minusOneWeek = addDays(now, -7);

const optionalFilters = [
  "dateRange",
  "appointmentDate",
  "createdByGroup",
  "levelOfReview",
  "caseSystem",
  "modalityId",
  "healthPlanId",
];

const filterLabels: Record<string, string> = {
  dateRange: "Request Date Range",
  appointmentDate: "Appointment Date",
  createdByGroup: "Created By Group",
  levelOfReview: "Level of Review",
  caseSystem: "Case System",
  modalityId: "Modality",
  healthPlanId: "Health Plan",
};

interface FilterPanelProps {
  value: Filter;
  onChange(filter: Filter): void;
  isLoading: boolean;
  enabledFilters: Array<keyof Filter>;
  setEnabledFilters: (value: Array<keyof Filter>) => void;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const {
    value,
    onChange,
    isLoading = false,
    enabledFilters,
    setEnabledFilters,
  } = props;

  const { data, loading } = useQuery<Data>(FILTER_DATA);

  const healthPlanOptions = addAllOption(
    data?.healthPlans.map((hp) => ({ value: hp.id, label: hp.name })) || []
  );
  const modalityOptions = addAllOption(
    data?.modalities.map((m) => ({ value: m.id, label: m.name })) || []
  );
  const levelOfReviewOptions = addAllOption(data?.reviewLevels || []);
  const caseSystemOptions = addAllOption(
    data?.externalSystemNames.map((s) => ({ value: s, label: s })) || []
  );

  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: minusOneWeek,
    finish: now,
  });

  function dropFilter(filter: string) {
    setEnabledFilters(enabledFilters.filter((f) => f !== filter));
  }
  // const [extraFilters, setExtraFilters] = useState<ExtraFilterName[]>([]);
  return (
    <FilterForm<Filter>
      defaultValue={defaultFilter}
      resettable={false}
      value={value}
      onChange={(values: Filter) =>
        onChange(
          selectEnabledFilters(
            {
              ...values,
              createdAfter: iso8601Date(timeRange.start),
              createdBefore: iso8601Date(timeRange.finish),
            },
            enabledFilters
          ) as any
        )
      }
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="subset" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput name="subset" options={subsetOptions} />
          </div>
        </FilterField>

        <FilterField
          htmlFor="caseReferenceNumber"
          icon="search"
          label="Case Ref #"
        >
          <div className="w-48">
            <TextInput
              name="caseReferenceNumber"
              placeholder="Case Ref #"
              icon="search"
            />
          </div>
        </FilterField>

        {enabledFilters.includes("dateRange") ? (
          <FilterField
            label="Request Date Range"
            removable
            onRemove={() => dropFilter("dateRange")}
          >
            <DateRangeDropdown value={timeRange} onChange={setTimeRange} />
          </FilterField>
        ) : null}

        {enabledFilters.includes("appointmentDate") ? (
          <FilterField
            label="Appointment Date"
            removable
            onRemove={() => dropFilter("appointmentDate")}
          >
            <div className="w-36">
              <DateMaskInput
                icon="calendar-alt"
                name="appointmentDate"
                validate={[required, validateLocalDate]}
              />
            </div>
          </FilterField>
        ) : null}

        {enabledFilters.includes("healthPlanId") ? (
          <FilterField
            htmlFor="healthPlanId"
            icon="filter"
            label="Health Plan"
            removable
            onRemove={() => dropFilter("healthPlanId")}
          >
            <div className="w-48">
              <SelectInput
                name="healthPlanId"
                options={healthPlanOptions}
                isLoading={loading}
              />
            </div>
          </FilterField>
        ) : null}

        {enabledFilters.includes("modalityId") ? (
          <FilterField
            htmlFor="modalityId"
            icon="filter"
            label="Modality"
            removable
            onRemove={() => dropFilter("modalityId")}
          >
            <div className="w-48">
              <SelectInput
                name="modalityId"
                options={modalityOptions}
                isLoading={loading}
              />
            </div>
          </FilterField>
        ) : null}

        {enabledFilters.includes("levelOfReview") ? (
          <FilterField
            htmlFor="levelOfReview"
            icon="filter"
            label="Level of Review"
            removable
            onRemove={() => dropFilter("levelOfReview")}
          >
            <div className="w-48">
              <SelectInput
                name="levelOfReview"
                options={levelOfReviewOptions}
                isLoading={loading}
              />
            </div>
          </FilterField>
        ) : null}

        {enabledFilters.includes("caseSystem") ? (
          <FilterField
            htmlFor="caseSystem"
            icon="filter"
            label="Case System"
            removable
            onRemove={() => dropFilter("caseSystem")}
          >
            <div className="w-36">
              <SelectInput
                name="caseSystem"
                options={caseSystemOptions}
                isLoading={loading}
              />
            </div>
          </FilterField>
        ) : null}

        {enabledFilters.includes("createdByGroup") ? (
          <FilterField
            htmlFor="createdByGroup"
            icon="filter"
            label="Created By Group"
            removable
            onRemove={() => dropFilter("createdByGroup")}
          >
            <div className="w-40">
              <SelectInput
                name="createdByGroup"
                options={createdByGroupOptions}
              />
            </div>
          </FilterField>
        ) : null}

        <EnabledFilters
          value={enabledFilters as string[]}
          options={optionalFilters}
          onChange={setEnabledFilters}
        />

        <FilterField>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

const EnabledFilters: FC<{
  value: string[];
  options: string[];
  onChange(value: string[]): void;
}> = (props) => {
  const { value, options, onChange } = props;
  const { isOpen, toggle, triggerRef, contentRef } = useDropdown();

  function isChecked(filter: string) {
    return value.includes(filter);
  }

  function toggleFilter(filter: string) {
    if (isChecked(filter)) {
      onChange(value.filter((v) => v !== filter));
    } else {
      onChange([filter, ...value]);
    }
  }

  return (
    <div className="_EnabledFilters relative inline-block mr-6">
      <div>
        <span className="rounded-lg shadow-sm">
          <button
            type="button"
            ref={triggerRef}
            onClick={toggle}
            className="hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 inline-flex items-center justify-center w-full p-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg"
          >
            + Filter
          </button>
        </span>
      </div>

      <ZoomIn
        ref={contentRef}
        show={isOpen}
        className="absolute right-0 z-20 w-56 mt-2 origin-top-right rounded-md shadow-lg"
      >
        <div className="bg-white rounded-md shadow-xs">
          <div className="px-4 py-3">
            <p className="pb-1 text-base font-semibold leading-5">Filters:</p>
            {options.map((option) => (
              <div key={option} className="flex items-start pt-2 text-sm">
                <div className="flex items-center">
                  &#8203;
                  <input
                    id={`${option}-filter`}
                    name={`${option}-filter`}
                    type="checkbox"
                    checked={isChecked(option)}
                    onChange={() => toggleFilter(option)}
                    className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <label
                  htmlFor={`${option}-filter`}
                  className="ml-3 font-medium text-gray-800"
                >
                  {filterLabels[option] || option}
                </label>
              </div>
            ))}
          </div>
        </div>
      </ZoomIn>
    </div>
  );
};

function selectEnabledFilters(
  filter: Filter,
  enabledFilters: Array<keyof Filter>
): Partial<Filter> {
  const enabledOptionalFilterKeys = optionalFilters.filter((key) =>
    enabledFilters.includes(key)
  );

  const nonOptionalFilterKeys = Object.keys(filter).filter(
    (key) =>
      !optionalFilters.concat(["createdBefore", "createdAfter"]).includes(key)
  );

  const enabledFiltersObj: Partial<Filter> = {};
  enabledOptionalFilterKeys.concat(nonOptionalFilterKeys).forEach((key) => {
    if (key === "dateRange") {
      enabledFiltersObj.createdAfter = filter.createdAfter;
      enabledFiltersObj.createdBefore = filter.createdBefore;
    } else {
      enabledFiltersObj[key] = filter[key];
    }
  });
  return enabledFiltersObj;
}

export function removeVoidKeys(
  filterWithNulls: Record<keyof Filter, any>
): Filter {
  let filter: Partial<Filter> = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter as Filter;
}

function addAllOption(options: StandardOption[]): StandardOption[] {
  return [allOption, ...options];
}
