import { FC, useEffect } from "react";
import qs from "query-string";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import { coalesceString } from "lib/coalesce";
import { AvailabilityForm } from "./AvailabilityForm";
import { RefNum } from "components/RefNum";
import { translateLOR } from "lib/kentuckyWording";
import { Spinner } from "components/Spinner";
import { DetailItem, DetailList } from "components/DetailList";
import { mmDdYyyy } from "lib/dateFormatters";

const FETCH_DRAFT_APPOINTMENT_REQUEST = gql`
  query GetDraftAppointmentRequest($id: UUID4!) {
    timeZoneNames
    appointmentRequest(id: $id) {
      id
      caseProfiles {
        id
        memberFirstName
        memberLastName
        memberDob
        memberMembershipNumber
        caseReferenceNumber
        episodeId
        externalSystemName
        insurancePlanCode
        icd10Code
        levelOfReview {
          value
          label
        }
        healthPlan {
          id
          name
        }
        modality {
          id
          name
        }
        memberState {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  appointmentRequest: {
    id: string;
    caseProfiles: {
      id: string;
      memberFirstName: string;
      memberLastName: string;
      memberDob: string;
      memberMembershipNumber: string;
      caseReferenceNumber: string;
      episodeId: string;
      externalSystemName: string;
      insurancePlanCode?: string;
      icd10Code: string | void;
      levelOfReview: {
        value: string;
        label: string;
      };
      healthPlan: {
        id: string;
        name: string;
      };
      modality: {
        id: string;
        name: string;
      };
      memberState: {
        id: string;
        name: string;
      };
    }[];
  };
}

interface RouteParams {
  step: string;
}

interface Props {}

export const AppointmentRequestQuestionsPage: FC<Props> = () => {
  const location = useLocation();
  const match = useRouteMatch<RouteParams>();
  const { appointmentRequestId } = qs.parse(location.search);

  // NB: This component is mounted even when not on step 2...
  const [loadData, { data, loading, error }] = useLazyQuery<Data>(
    FETCH_DRAFT_APPOINTMENT_REQUEST
  );

  // NB: ...so when it is step 2, load the data.
  useEffect(() => {
    if (match.params.step === "2") {
      loadData({ variables: { id: appointmentRequestId } });
    }
  }, [match.params.step, appointmentRequestId, loadData]);

  if (match.params.step !== "2") {
    return null;
  }

  return (
    <div className="_AppointmentRequestQuestionsPage text-left">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.timeZoneNames && data.appointmentRequest) ? (
        <div style={{ padding: "1.5rem" }}>
          <h1>Failed to Load</h1>
        </div>
      ) : (
        <div className="gap-x-4 flex">
          <div className="md:block md:w-5/12 hidden">
            <div className="px-6 pt-6 pb-12 text-left bg-white rounded-lg shadow-lg">
              <h4 className="text-2xl font-semibold text-gray-800">
                Case Info
              </h4>

              {data.appointmentRequest.caseProfiles.map((caseProfile, idx) => (
                <div
                  key={caseProfile.id}
                  className="p-4 pb-8 mt-4 border border-gray-200 rounded-lg shadow-md"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-700">Case #{idx + 1}</p>
                    <RefNum refNum={caseProfile.caseReferenceNumber} />
                  </div>

                  <DetailList className="mt-5">
                    {caseProfile.episodeId && (
                      <DetailItem labelWidth={120} label="Episode ID">
                        {caseProfile.episodeId}
                      </DetailItem>
                    )}

                    <DetailItem labelWidth={120} label="Member Name">
                      <p className="capitalize">
                        {caseProfile.memberFirstName}{" "}
                        {caseProfile.memberLastName}
                      </p>
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member DOB">
                      {mmDdYyyy(caseProfile.memberDob)}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Level of Review">
                      {translateLOR(
                        caseProfile.levelOfReview.label,
                        caseProfile.insurancePlanCode,
                        caseProfile.memberState.name
                      )}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="System Name">
                      {caseProfile.externalSystemName}
                    </DetailItem>
                    {caseProfile.icd10Code ? (
                      <DetailItem labelWidth={120} label="ICD10 Code">
                        {caseProfile.icd10Code}
                      </DetailItem>
                    ) : null}
                    <DetailItem labelWidth={120} label="Health Plan Name">
                      {caseProfile.healthPlan.name}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member ID">
                      {caseProfile.memberMembershipNumber}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Case Type/Modality">
                      {caseProfile.modality.name}
                    </DetailItem>
                    <DetailItem labelWidth={120} label="Member State">
                      {caseProfile.memberState.name}
                    </DetailItem>
                  </DetailList>
                </div>
              ))}
            </div>
          </div>
          <div className="w-7/12">
            <div className="p-4 bg-white rounded-lg shadow-lg">
              <section className="px-6 pt-6 pb-12">
                <h1 className="text-3xl font-bold leading-relaxed">
                  Questions
                </h1>
                <h2 className="text-xl">Please indicate your availability</h2>
                <div className="pt-5">
                  <AvailabilityForm
                    appointmentRequestId={coalesceString(appointmentRequestId)}
                    initialValues={{
                      timeZoneName: inferTimeZone(
                        data.appointmentRequest.caseProfiles[0].memberState.name
                      ),
                    }}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const singleTimeZoneStates: Record<string, string> = {
  Alabama: "US/Central",
  Arizona: "US/Mountain",
  Arkansas: "US/Central",
  California: "US/Pacific",
  Colorado: "US/Mountain",
  Connecticut: "US/Eastern",
  Delaware: "US/Eastern",
  Georgia: "US/Eastern",
  Hawaii: "US/Hawaii",
  Illinois: "US/Central",
  Iowa: "US/Central",
  Louisiana: "US/Central",
  Maine: "US/Eastern",
  Maryland: "US/Eastern",
  Massachusetts: "US/Eastern",
  Minnesota: "US/Central",
  Mississippi: "US/Central",
  Missouri: "US/Central",
  Montana: "US/Mountain",
  "New Hampshire": "US/Eastern",
  "New Jersey": "US/Eastern",
  "New Mexico": "US/Mountain",
  "New York": "US/Eastern",
  "North Carolina": "US/Eastern",
  Ohio: "US/Eastern",
  Oklahoma: "US/Central",
  Pennsylvania: "US/Eastern",
  "Rhode Island": "US/Eastern",
  "South Carolina": "US/Eastern",
  Utah: "US/Mountain",
  Vermont: "US/Eastern",
  Virginia: "US/Eastern",
  Washington: "US/Pacific",
  "West Virginia": "US/Eastern",
  Wisconsin: "US/Central",
  Wyoming: "US/Mountain",
};

function inferTimeZone(stateName: string): string {
  return singleTimeZoneStates[stateName] || "";
}
