import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
// import "bulma/css/bulma.css";
import "./index.css";
import "./styles/colors.css";
import "./styles/custom.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import createIconLibrary from "./createIconLibrary";

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
