import { FC, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mmDdYyyy } from "lib/dateFormatters";
import { DataExport } from "../ExportsWidget";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useToast } from "layouts/PortalLayout/Toast";

export const DOWNLOAD_EXPORT = gql`
  mutation DownloadDataExport($id: UUID4!) {
    downloadDataExport(id: $id) {
      error
      url
    }
  }
`;

export interface MutationData {
  downloadDataExport: {
    error: null | string;
    url: null | string;
  };
}

export interface MutationVariables {
  id: string;
}

interface ExportItemProps {
  export: DataExport;
}

export const ExportItem: FC<ExportItemProps> = (props) => {
  const { export: dataExport } = props;

  const toast = useToast();

  const [downloadDataExportMut, { loading }] = useMutation<
    MutationData,
    MutationVariables
  >(DOWNLOAD_EXPORT);

  const downloadDataExport = useCallback(async () => {
    const variables = { id: dataExport.id };

    try {
      const { data } = await downloadDataExportMut({ variables });

      if (data?.downloadDataExport.url) {
        // it worked...
        window.open(data.downloadDataExport.url);
      } else if (data?.downloadDataExport.error) {
        toast.danger(data.downloadDataExport.error);
      } else {
        toast.danger("Something went wrong.");
      }
    } catch (e) {
      console.error(e);
      toast.danger("Something went wrong.");
    }
  }, [dataExport, downloadDataExportMut, toast]);

  return (
    <div className="ExportItem" style={{ margin: "0 0.25rem 0.5rem" }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="mr-3" style={{ fontSize: "1.75rem", color: "#aaa" }}>
            <FontAwesomeIcon icon="envelope" />
          </div>
          <div>
            <div style={{ fontSize: "0.825rem", fontWeight: 600 }}>
              {mmDdYyyy(dataExport.windowStart)} to{" "}
              {mmDdYyyy(dataExport.windowFinish)}
            </div>
            <div style={{ fontSize: "0.825rem" }}>
              Status: {dataExport.status}
            </div>
          </div>
        </div>
        <div>
          {dataExport.status === "completed" ? (
            <Button
              onClick={downloadDataExport}
              disabled={loading}
              isLoading={loading}
            >
              <FAIcon icon="file-download" />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
