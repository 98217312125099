import { FC } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { SelectInput } from "components/formik/SelectField";
import { Button } from "components/Button";
import { TextInput } from "components/formik/TextField";

const PROVIDERS_FILTER_DATA = gql`
  query ProvidersFilterData {
    specialties {
      id
      name
    }
    states {
      id
      name
    }
  }
`;

interface Data {
  specialties: SpecialtyModel[];
  states: StateModel[];
}

interface SpecialtyModel {
  id: string;
  name: string;
}

interface StateModel {
  id: string;
  name: string;
}

export interface ProvidersFilterModel extends Record<string, any> {
  searchTerm?: string;
  specialtyId?: string;
  stateLicensureId?: string;
}

export const blankFilter: ProvidersFilterModel = {
  searchTerm: "",
};

interface ProvidersFilterPanelProps {
  value: ProvidersFilterModel;
  onChange(filter: ProvidersFilterModel): void;
  isLoading?: boolean;
}

type Filter = ProvidersFilterModel;

export const ProvidersFilterPanel: FC<ProvidersFilterPanelProps> = (props) => {
  const { value, onChange, isLoading = false } = props;
  const { data, loading } = useQuery<Data>(PROVIDERS_FILTER_DATA);

  const specialtyOptions =
    data?.specialties
      .slice(0)
      .sort(byName)
      .map(asOption) || [];
  const stateOptions = data?.states.map(asOption) || [];

  return (
    <FilterForm<ProvidersFilterModel>
      defaultValue={blankFilter}
      value={value}
      onChange={(values: Filter) => onChange(removeVoidKeys(values as any))}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search Term">
          <div className="w-48">
            <TextInput
              name="searchTerm"
              placeholder="Search..."
              icon="search"
            />
          </div>
        </FilterField>

        <FilterField htmlFor="specialtyId" icon="stethoscope" label="Specialty">
          <div className="w-48">
            <SelectInput
              isLoading={loading}
              isClearable
              name="specialtyId"
              options={specialtyOptions}
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="stateLicensureId"
          icon="filter"
          label="State Licensure"
        >
          <div className="w-48">
            <SelectInput
              isLoading={loading}
              isClearable
              name="stateLicensureId"
              options={stateOptions}
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function asOption<T extends { id: string; name: string }>(item: T): Option {
  return {
    value: item.id,
    label: item.name,
  };
}

function byName<T extends { name: string }>(item1: T, item2: T) {
  return item1.name.localeCompare(item2.name);
}

function removeVoidKeys(filterWithNulls: Record<keyof Filter, any>): Filter {
  let filter: Partial<Filter> = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter as Filter;
}
