import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Socket } from "phoenix";

/**
 * SocketContext.
 */

interface SocketContextValue {
  socket: Socket;
}

const socketContext = createContext<SocketContextValue>(null!);

interface SocketProviderProps {
  endpoint: string;
  token: string;
}

export const SocketProvider: FC<SocketProviderProps> = (props) => {
  const { endpoint, token } = props;

  const socket = useRef(
    new Socket(endpoint, {
      params: { jwt: token },
    })
  );

  /**
   * Attempt to connect to the socket onMount
   */
  useEffect(() => {
    socket.current.connect();
    return () => socket.current.disconnect(); // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const ctxValue = useMemo(() => ({ socket: socket.current }), []);

  return <socketContext.Provider value={ctxValue} {...props} />;
};

export function useSocket() {
  const context = useContext(socketContext);
  if (context === undefined) {
    throw new Error(`useSocket must be used within a SocketProvider`);
  }
  return context;
}
