import React, { FC } from "react";
import { TimeRange } from "../types";

interface BookingDistanceProps {
  timeRange: TimeRange;
}

export const BookingDistance: FC<BookingDistanceProps> = () => {
  return (
    <div className="BookingDistance" style={{ padding: "3rem" }}>
      <p>Not Enough Data to Display</p>
    </div>
  );
};
