export type Validator<T = any> = (value: T) => string | void
export type ValidateProp = Validator | Validator[];
export interface ValidateOpts<InputValueType = string, CheckValueType = InputValueType> {
  message?: string;
  transform?(value: InputValueType): CheckValueType;
}

function defaultTransform<T = string>(value: T): T {
  return value;
}

export function compose<T>(validators: Validator<T>[]): Validator<T> {
  const nonEmptyValidators = validators.filter(Boolean);

  return (value) => {
    for (let validator of nonEmptyValidators) {
      let error = validator(value);
      if (error) {
        return error;
      }
    }
    return undefined;
  }
}

export const emailRegex = /^.+@.+\..+$/;
export const phoneRegex = /^\d{10}$/;
export const localDateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

export const validateEmail = validateFormat(emailRegex, { message: "Invalid email"});

export const validatePhone = validateFormat(phoneRegex, {
  message: "Invalid phone",
  transform: (value) => value.replace(/\D/g,'')
})

export const validateLocalDate = validateFormat(localDateRegex, { message: "Invalid date" });

export function validateFormat(format: RegExp, opts: ValidateOpts = {}) {
  return (value?: any) => {
    let error;

    if (value && typeof value === "string") {
      const transform = opts.transform || defaultTransform;
      const message = opts.message || "Invalid format";
      const transformedValue = transform(value.trim());

      if (!transformedValue.match(format)) {
        error = message
      }
    }

    return error;
  }
}

export function required(value?: any) {
  let error;
  if (isEmpty(value)) {
    error = "Required";
  }
  return error;
}

function isEmpty(value?: any) {
  return value === null || value === undefined || value?.length === 0;
}
