import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import {
  WizardStep,
  WizardStepContainer,
  ScrollToTopOnStepChange,
} from "components/WizardStep";
import { StepProgressBar } from "components/StepProgressBar";
import { NewAppointmentRequestPage } from "pages/NewAppointmentRequestPage";
import { AppointmentRequestQuestionsPage } from "pages/AppointmentRequestQuestionsPage";
import { ProviderResultsPage } from "pages/ProviderResultsPage";
import { AppointmentRequestConfirmationPage } from "pages/AppointmentRequestConfirmationPage";

function stepParser(step: string) {
  switch (parseInt(step, 10)) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    default:
      return "INVALID_STEP_PARAMETER";
  }
}

const StepDeterminer: React.SFC<RouteComponentProps> = ({ match }) => (
  <Redirect to={`${match.path}/1`} />
);

const steps = ["Case Info", "Questions", "Select Provider", "Confirmation"];

const AppointmentRequestStepIndicator: React.SFC<{ currentStep: number }> = ({
  currentStep,
}) => <StepProgressBar currentStep={currentStep} stepLabels={steps} />;

class AppointmentRequestWizard extends Component<RouteComponentProps> {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={match.path} component={StepDeterminer} />
        <Route
          path={`${match.path}/:step`}
          render={({ match }) => {
            const step = stepParser(match.params.step);
            if (step === "INVALID_STEP_PARAMETER") {
              return <Redirect to={`${match.path}`} />;
            }
            return (
              <ScrollToTopOnStepChange step={step}>
                <div className="container mx-auto">
                  <div style={{ padding: "1.5rem" }}>
                    <AppointmentRequestStepIndicator currentStep={step} />
                  </div>
                  <WizardStepContainer>
                    <WizardStep forStep={1} currentStep={step}>
                      {/* <ReferralProfiles.New /> */}
                      <div>
                        <NewAppointmentRequestPage />
                      </div>
                    </WizardStep>
                    <WizardStep forStep={2} currentStep={step}>
                      <div>
                        <AppointmentRequestQuestionsPage />
                      </div>
                    </WizardStep>
                    <WizardStep forStep={3} currentStep={step} fullWidth>
                      <ProviderResultsPage />
                    </WizardStep>
                    <WizardStep forStep={4} currentStep={step}>
                      <AppointmentRequestConfirmationPage />
                    </WizardStep>
                  </WizardStepContainer>
                </div>
              </ScrollToTopOnStepChange>
            );
          }}
        />
      </div>
    );
  }
}

export { AppointmentRequestWizard };
