import { FC, useState } from "react";
import { SelectedAppointment } from "../RescheduleAppointmentModal";
import { firstSecondThird } from "lib/firstSecondThird";
import { CircleIcon } from "components/CircleIcon";
import { ProviderResult as IProviderResult } from "pages/ProviderResultsPage/ProviderResultsFetcher";
import { ddd, mmDdYy } from "lib/dateFormatters";
import "./ProviderResult.css";

interface ProviderResultProps {
  providerResult: IProviderResult;
  onSlotClick(appointment: SelectedAppointment): void;
}

export const ProviderResult: FC<ProviderResultProps> = (props) => {
  const { providerResult, onSlotClick } = props;

  const [showAll, setShowAll] = useState(false);

  function toggleShowAll() {
    setShowAll(!showAll);
  }

  return (
    <div className="RescheduleModal__ProviderResult">
      <div className="RescheduleModal__ProviderResult__header">
        <div className="gap-x-3 flex items-center justify-between">
          <div className="gap-x-3 flex items-center ml-2">
            <CircleIcon icon="user-md" size={32} iconSize={16} />
            <div className="text-left">
              <h2 className="font-semibold text-gray-900">
                {providerResult.provider.nameWithAppellation}
              </h2>
              {providerResult.provider.primarySpecialty ? (
                providerResult.provider.primarySpecialty
              ) : providerResult.provider.skills &&
                providerResult.provider.skills.length > 0 ? (
                <p style={{ fontSize: "0.75rem", color: "#6e7988" }}>
                  {providerResult.provider.skills
                    .slice(0, 3)
                    .map((s) => s.name)
                    .join(", ")}
                  {providerResult.provider.skills.length > 3 ? (
                    <span>
                      {" "}
                      and {providerResult.provider.skills.length - 3} other
                      skills
                    </span>
                  ) : null}
                </p>
              ) : null}
            </div>
          </div>
          {providerResult.provider.priorityBySkill ? (
            <p className="RescheduleModal__ProviderResult__priority">
              {firstSecondThird(providerResult.provider.priorityBySkill)}{" "}
              Priority by Skill
            </p>
          ) : null}
        </div>
      </div>
      <div className="RescheduleModal__ProviderResult__body">
        <div className="cols">
          {providerResult.dates.map((day) => (
            <div
              className="col RescheduleModal__ProviderResult__day text-center"
              key={day.date}
            >
              <h3 className="RescheduleModal__ProviderResult__date text-sm">
                <span className="font-normal">{ddd(day.date)}</span>{" "}
                {mmDdYy(day.date)}
              </h3>
              {!day.slots || day.slots.length === 0 ? (
                <div className="RescheduleModal__ProviderResult__empty">-</div>
              ) : (
                <>
                  <ul className="flex flex-col items-center">
                    {(!showAll ? day.slots.slice(0, 4) : day.slots).map(
                      (timeSlot) => (
                        <li key={timeSlot.timeRange.startTimeString}>
                          <button
                            type="button"
                            className="RescheduleModal__ProviderResult__slot"
                            onClick={() => {
                              onSlotClick &&
                                onSlotClick({
                                  provider: providerResult.provider,
                                  slot: timeSlot,
                                });
                            }}
                          >
                            {timeSlot.timeRange.startTimeString}
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                  {!showAll && day.slots && day.slots.length > 4 ? (
                    <button
                      type="button"
                      className="a RescheduleModal__ProviderResult__show-more"
                      onClick={toggleShowAll}
                    >
                      Show more...
                    </button>
                  ) : null}
                </>
              )}
            </div>
          ))}
        </div>
        <div className="RescheduleModal__ProviderResult__show-less">
          {showAll ? (
            <button type="button" className="a" onClick={toggleShowAll}>
              Show less...
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
