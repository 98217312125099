import { FC, useState } from "react";
import { useMutation } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { HorizontalTextAreaField } from "components/formik/TextArea";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { SelectedAppointment } from "../RescheduleAppointmentModal";
import {
  AppointmentModel,
  APPOINTMENT_REQUEST_SHOW_QUERY,
} from "../../../AppointmentRequestShowPage";
import { useToast } from "layouts/PortalLayout/Toast";
import { NoLongerAvailable } from "./NoLongerAvailable";
import { isScheduleConflict } from "pages/AppointmentRequestConfirmationPage/ConfirmationForm";
import { Button } from "components/Button";
import {
  useAppointmentRequestContact,
  validationSchema as contactValidationSchema,
  toMutationVariables as contactValuesToMutationVariables,
  ContactDetailsFields,
} from "./ContactDetailsFields";
import { Spinner } from "components/Spinner";

const CANCEL_AND_RESCHEDULE = gql`
  mutation CancelAndReschedule(
    $id: UUID4!
    $cancelledAppointment: CancelAppointmentInput!
    $newAppointment: AppointmentInput!
    $appointmentRequest: ContactDetailsInput!
  ) {
    cancelAndRescheduleAppointment(
      id: $id
      cancelledAppointment: $cancelledAppointment
      newAppointment: $newAppointment
      appointmentRequest: $appointmentRequest
    ) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  cancelAndRescheduleAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface CancelAndRescheduleFormProps {
  oldAppointment: AppointmentModel;
  newAppointment: SelectedAppointment;
  forgetAppointmentSelection(): void;
  onSuccess(): void;
}

export const CancelAndRescheduleForm: FC<CancelAndRescheduleFormProps> = (
  props
) => {
  const {
    oldAppointment,
    newAppointment,
    onSuccess,
    forgetAppointmentSelection,
  } = props;
  const [noLongerAvailable, setNoLongerAvailable] = useState(false);
  const toast = useToast();

  const {
    loading,
    error,
    data: initialContactValues,
  } = useAppointmentRequestContact(oldAppointment.appointmentRequestId);

  const [cancelAndReschedule] = useMutation<MutationData>(
    CANCEL_AND_RESCHEDULE
  );

  return (
    <div className="_CancelAndRescheduleForm">
      {noLongerAvailable ? (
        <NoLongerAvailable onClick={forgetAppointmentSelection} />
      ) : loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !initialContactValues ? (
        <p>Failed to load.</p>
      ) : (
        <Formik
          initialValues={{
            cancellationReason: "",
            appointmentRequest: initialContactValues,
          }}
          validationSchema={Yup.object().shape({
            cancellationReason: Yup.string().required("Required"),
            appointmentRequest: contactValidationSchema,
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            cancelAndReschedule({
              variables: {
                id: oldAppointment.id,
                cancelledAppointment: {
                  cancellationReason: values.cancellationReason,
                },
                newAppointment: {
                  timeRange: {
                    start: newAppointment.slot.timeRange.start,
                    finish: newAppointment.slot.timeRange.finish,
                  },
                  receivingProviderId: newAppointment.provider.id,
                },
                appointmentRequest: contactValuesToMutationVariables(
                  values.appointmentRequest
                ),
              },
              refetchQueries: [
                {
                  query: APPOINTMENT_REQUEST_SHOW_QUERY,
                  variables: {
                    id: oldAppointment.appointmentRequestId,
                  },
                },
              ],
            }).then((resp) => {
              if (resp?.data?.cancelAndRescheduleAppointment.errors) {
                if (
                  isScheduleConflict(
                    resp.data.cancelAndRescheduleAppointment.errors
                  )
                ) {
                  setNoLongerAvailable(true);
                }
                setStatus({
                  errors: resp.data.cancelAndRescheduleAppointment.errors,
                });
              } else if (
                resp?.data?.cancelAndRescheduleAppointment.appointment
              ) {
                toast.success("Rescheduled Appointment");
                onSuccess();
              }
              setSubmitting(false);
            });
          }}
        >
          {({ handleSubmit, isSubmitting, status }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="mt-3">
                <HorizontalTextAreaField
                  name="cancellationReason"
                  label="Cancellation Reason"
                  minHeight={80}
                  indicateRequired
                />
              </div>

              <div className="mt-3">
                <ContactDetailsFields field="appointmentRequest" />
              </div>

              <div className="mx-auto mt-6 text-right">
                <Button
                  type="submit"
                  kind="primary"
                  color="gold"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Cancel and Reschedule
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
