// Define a type for objects that can be compared
export type Comparable = { [key: string]: unknown } | null | undefined;

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * Checks if two values are equal, including special cases for NaN.
 */
function is(x: unknown, y: unknown): boolean {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / (x as number) === 1 / (y as number);
  } else if (Array.isArray(x) && Array.isArray(y)) {
    if (x.length !== y.length) return false;
    for (let i = 0; i < x.length; i++) {
      if (!is(x[i], y[i])) return false;
    }
    return true;
  } else {
    return x !== x && y !== y; // eslint-disable-line no-self-compare
  }
}

/**
 * Performs a shallow equality check between two objects.
 */
export function shallowEqual(objA: Comparable, objB: Comparable): boolean {
  if (is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!hasOwnProperty.call(objB, key) || !is(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
}
