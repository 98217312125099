import { FC } from "react";
import { FAIcon } from "components/FAIcon";
import { Button } from "components/Button";

interface NoLongerAvailableProps {
  onClick(): void;
}

export const NoLongerAvailable: FC<NoLongerAvailableProps> = (props) => {
  const { onClick } = props;

  return (
    <div
      className="NoLongerAvailable"
      style={{
        maxWidth: 420,
        padding: "1rem",
        textAlign: "center",
        margin: "0 auto",
        fontSize: "1.25em",
      }}
    >
      <div style={{ fontSize: "3.5rem" }}>
        <FAIcon icon={["far", "calendar-times"]} />
      </div>
      <p style={{ marginTop: "1rem" }}>
        Sorry, the selected appointment is no longer available. Please select a
        new appointment time.
      </p>
      <div style={{ marginTop: "1.5rem" }}>
        <Button type="button" kind="primary" color="gold" onClick={onClick}>
          <span className="icon-container">
            <FAIcon icon="arrow-left" />
          </span>
          Back to Results
        </Button>
      </div>
    </div>
  );
};
