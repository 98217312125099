import React, { createRef, Component } from "react";

type ScrollToTopOnStepChangeProps = {
  step: number;
};

class ScrollToTopOnStepChange extends Component<ScrollToTopOnStepChangeProps> {
  private nodeRef = createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: ScrollToTopOnStepChangeProps) {
    if (prevProps.step !== this.props.step) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    if (this.nodeRef.current) {
      this.nodeRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  };

  render() {
    return <div ref={this.nodeRef}>{this.props.children}</div>;
  }
}

export { ScrollToTopOnStepChange };
