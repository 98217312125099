import { FC } from "react";
import { Route } from "react-router-dom";
import { ProviderCalendar } from "./ProviderCalendar";

interface ProviderShowProps {}

export const ProviderShow: FC<ProviderShowProps> = (props) => {
  return (
    <div className="_ProviderShow">
      <Route
        path="/o/settings/providers/:providerId/calendar"
        component={ProviderCalendar}
      />
    </div>
  );
};
