import { FC } from "react";
import { useLocation, Link } from "react-router-dom";
import { afterSignInPath } from "components/AuthRoutes";
import evicoreLogoSrc from "images/eviCore_big_logo.png";

export const SignInPage: FC = () => {
  const location = useLocation();
  const targetUrl = afterSignInPath(location);
  const encodedTargetUrl = encodeURIComponent(targetUrl);

  return (
    <div className="_SignInPage p-4 bg-white rounded-lg shadow-lg">
      <div className="gap-x-4 md:flex">
        <div className="flex items-center justify-center flex-grow p-4">
          <img className="w-48 max-w-xs" src={evicoreLogoSrc} alt="logo" />
        </div>
        <div className="flex-grow">
          <section className="py-12">
            <div className="content text-left">
              <h1
                style={{ fontSize: 64, color: "rgb(0, 40, 85)" }}
                className="yellow-border-btm font-semibold"
              >
                P2P Portal
              </h1>
              <p className="mt-6" style={{ fontSize: 20, lineHeight: 1.55 }}>
                Click below to sign in to the eviCore P2P portal.
              </p>
            </div>
            <a
              href={`/sso/auth/signin/cigna?target_url=${encodedTargetUrl}`}
              className="bg-gold-600 hover:bg-gold-500 focus:border-gold-700 focus:shadow-outline-gold active:bg-gold-700 inline-block px-6 py-3 m-8 text-xl font-medium leading-6 text-white uppercase border-transparent rounded-full"
            >
              Sign in
            </a>
          </section>
          <section>
            <p className="mb-3 text-xs">
              Or,{" "}
              <a
                className="link"
                href={`/sso/auth/signin/evicore?target_url=${encodedTargetUrl}`}
              >
                Sign in with PingFed SSO
              </a>
            </p>
            <p className="text-xs">
              Or,{" "}
              <Link className="link" to="/auth/old_sign_in">
                Sign in with Email
              </Link>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
