import { FC, CSSProperties } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import "./Avatar.css";

const CURRENT_USER = gql`
  query CurrentUserForAvatar {
    me {
      id
      firstName
      lastName
    }
  }
`;

interface Data {
  me: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

/**
 * Avatar.
 */

interface AvatarProps {
  src?: string;
  firstName: string;
  lastName: string;
  size: number;
  style?: CSSProperties;
  className?: string;
}

export const Avatar: FC<AvatarProps> = (props) => {
  const { src, size, style, firstName, lastName, className } = props;

  const cn = ["Avatar", className].filter(Boolean).join(" ");

  return !!src ? (
    <div
      className={cn}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${src})`,
        ...style,
      }}
    />
  ) : (
    <div
      className={cn}
      style={{
        width: size,
        height: size,
        backgroundColor: stringToHslColor(`${firstName} ${lastName}`, 30, 80),
        color: "#fff",
        ...style,
      }}
    >
      <span
        className="Avatar__letters"
        style={{ fontSize: Math.floor(size / 2.5) }}
      >
        {firstName.slice(0, 1)}
        {lastName.slice(0, 1)}
      </span>
    </div>
  );
};

/**
 * MyAvatar.
 */

interface MyAvatarProps {
  size?: number;
  style?: CSSProperties;
  className?: string;
}

export const MyAvatar: FC<MyAvatarProps> = (props) => {
  const { size = 50, style, className } = props;
  const { loading, data, error } = useQuery<Data>(CURRENT_USER);

  if (loading || error || !(data && data.me)) {
    return null;
  }

  return (
    <Avatar
      firstName={data.me.firstName}
      lastName={data.me.lastName}
      size={size}
      style={style}
      className={className}
    />
  );
};

function stringToHslColor(str: string, s: number, l: number) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}
