import React, { FC } from "react";
import { useCombobox } from "downshift";

export interface TagModel {
  id: string;
  label: string;
  color: string;
}

interface TagComboboxProps {
  items: TagModel[];
  onSelect(tag: TagModel): void;
  className?: string;
}

export const TagCombobox: FC<TagComboboxProps> = (props) => {
  const { items, onSelect, className = "" } = props;

  const [inputItems, setInputItems] = React.useState(items);

  const {
    getInputProps,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        items.filter((item) =>
          item.label.toLowerCase().startsWith((inputValue || "").toLowerCase())
        )
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      return selectedItem && onSelect(selectedItem);
    },
  });

  return (
    <div className="_TagCombobox pt-1">
      <div {...getComboboxProps()}>
        <input
          type="text"
          {...getInputProps({
            className: `${className} focus:border-blue-300 focus:outline-none focus:shadow-outline-blue sm:leading-5 text-xs block w-full h-auto px-2 py-1 m-0 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none`,
            autoFocus: true,
          })}
        />
      </div>
      {inputItems.length === 0 ? (
        <div className="p-2 text-center" {...getMenuProps()}>
          <p className="text-sm font-semibold leading-loose text-gray-600">
            {items.length === 0 ? "No Available Tags" : "No Matching Tags"}
          </p>
          <p className="my-1 text-xs leading-tight text-gray-400">
            Tags can be managed in settings
          </p>
        </div>
      ) : (
        <ul {...getMenuProps({ className: "max-h-56 overflow-y-scroll mt-1" })}>
          {inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
              }
              key={`${item.id}${index}`}
              {...getItemProps({
                item,
                index,
                className:
                  "cursor-pointer flex items-center font-semibold mt-1 px-3 py-2 rounded text-sm",
              })}
            >
              <div
                className="w-5 h-5 mr-2 rounded-full shadow-inner"
                style={{ backgroundColor: item.color }}
              />
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
