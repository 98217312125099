import { FC } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { Trail, animated } from "react-spring";
import { coalesceString } from "lib/coalesce";
import { BlockSpinner } from "components/BlockSpinner";
import { ProviderResultsFetcher } from "./ProviderResultsFetcher";
import { ProviderResult } from "./ProviderResult";
import "./ProviderResultsPage.css";

interface RouteParams {
  step: string;
}

interface ProviderResultsPageProps {}

export const ProviderResultsPage: FC<ProviderResultsPageProps> = () => {
  const location = useLocation();
  const match = useRouteMatch<RouteParams>();

  const { appointmentRequestId } = qs.parse(location.search);
  return (
    <div>
      {match.params.step === "3" ? (
        <ProviderResultsFetcher
          appointmentRequestId={coalesceString(appointmentRequestId)}
        >
          {({ loading, error, results }) =>
            loading ? (
              <div className="ProviderResultsPage__loading-container">
                <div style={{ margin: "2rem auto" }}>
                  <BlockSpinner />
                </div>
              </div>
            ) : error ? (
              <div style={{ padding: "1.5rem" }}>
                <h1>Failed to Load</h1>
              </div>
            ) : !results ||
              (results && results.items && results.items.length === 0) ? (
              <p>No results</p>
            ) : (
              <Trail
                native
                items={results.items}
                keys={(item) => item.provider.id}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
              >
                {(providerResult) =>
                  providerResult &&
                  ((styles) => (
                    <animated.div style={styles}>
                      <ProviderResult
                        key={providerResult.provider.id}
                        providerResult={providerResult}
                        appointmentRequestId={coalesceString(
                          appointmentRequestId
                        )}
                      />
                    </animated.div>
                  ))
                }
              </Trail>
            )
          }
        </ProviderResultsFetcher>
      ) : null}
    </div>
  );
};
