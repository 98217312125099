import { FC } from "react";
import { Formik } from "formik";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Transition } from "react-spring";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { SingleCheckboxField } from "components/formik/SingleCheckboxField";
import { SelectField } from "components/formik/SelectField";
import { validPassword } from "lib/validPassword";

const INVITATION_REGISTER = gql`
  mutation RegisterViaInvitation($input: RegisterViaInvitationInput!) {
    registerViaInvitation(input: $input) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  registerViaInvitation: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

type InvitationRegisterFormProps = {
  id: string;
  token: string;
  timeZoneName: string;
  timeZoneNames: string[];
  onSignIn: (jwt: string) => any;
};

const items = [{ key: "1" }];

export const InvitationRegisterForm: FC<InvitationRegisterFormProps> = (
  props
) => {
  const { id, token, timeZoneName, timeZoneNames, onSignIn } = props;
  const [updateAccount] = useMutation<MutationData>(INVITATION_REGISTER);

  return (
    <Transition
      items={items}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {(item) =>
        item &&
        ((styles) => (
          <div style={styles} className="InvitationRegisterForm">
            <Formik
              initialValues={{
                timeZoneName,
                password: "",
                passwordConfirmation: "",
                acceptsTermsOfService: false,
              }}
              validationSchema={Yup.object().shape({
                password: validPassword,
                passwordConfirmation: validPassword,
                acceptsTermsOfService: Yup.bool()
                  .test(
                    "acceptsTermsOfService",
                    "You must agree to the terms of service to continue.",
                    (value) => value === true
                  )
                  .required("Required"),
              })}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });
                const input = {
                  ...values,
                  id,
                  token,
                };
                updateAccount({ variables: { input } }).then((resp) => {
                  if (resp?.data?.registerViaInvitation.errors) {
                    setStatus({
                      errors: resp.data.registerViaInvitation.errors,
                    });
                  } else if (resp?.data?.registerViaInvitation.session) {
                    const { token } = resp.data.registerViaInvitation.session;

                    onSignIn(token);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <div style={{ padding: "1.5rem" }}>
                  <form onSubmit={handleSubmit}>
                    <FormStatusErrors status={status} />

                    <SelectField
                      name="timeZoneName"
                      label="Your Time Zone"
                      options={timeZoneNames.map((tz: string) => ({
                        value: tz,
                        label: tz,
                      }))}
                    />

                    <TextField
                      type="password"
                      name="password"
                      placeholder="••••••••••"
                      label="Choose a Password"
                    />

                    <TextField
                      name="passwordConfirmation"
                      placeholder="••••••••••"
                      label="Confirm Password"
                      type="password"
                    />

                    <SingleCheckboxField
                      name="acceptsTermsOfService"
                      checkboxLabel={
                        <>
                          I accept the <span>Terms of Service</span>
                        </>
                      }
                      label=""
                    />

                    <div className="field" style={{ marginTop: "1.5rem" }}>
                      <div className="control text-center">
                        <button
                          type="submit"
                          className="button is-link"
                          disabled={isSubmitting}
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        ))
      }
    </Transition>
  );
};
