import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import cx from "classnames";
import "./DropdownMenu.css";

type DropdownMenuProps = {
  open?: boolean;
  children?: JSX.Element | JSX.Element[];
};

type DropdownMenuState = {
  isOpen: boolean;
};

class DropdownMenu extends Component<DropdownMenuProps, DropdownMenuState> {
  constructor(props: DropdownMenuProps) {
    super(props);
    this.state = { isOpen: this.props.open || false };
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleWindowClick = (event: any) => {
    // TODO: Should we do `event.preventDefault();` here? To stop event from propogating.
    //       Reason to maybe do this is if the click outside the dropdown menu is just to
    //       hide the dropdown menu, rather than to actually `click` something. It is
    //       potentially a little surprising as the user if you click to hide dropdown,
    //       but happen to click a button or something and it does something.
    const element = findDOMNode(this);
    if (
      element &&
      event.target !== element &&
      !element.contains(event.target) &&
      this.state.isOpen
    ) {
      this.toggleOpen();
    }
  };

  render() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return (
      <div
        className={cx("DropdownMenu", {
          DropdownMenu__contentOpen: isOpen,
          DropdownMenu__contentClosed: !isOpen
        })}
        onClick={this.toggleOpen}
      >
        {children}
      </div>
    );
  }
}

export { DropdownMenu };
