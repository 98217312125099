import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import "./SidebarLink.css";

type SidebarLinkProps = {
  icon: IconProp;
  path: string;
  label: string;
};

const SidebarLink: React.SFC<SidebarLinkProps> = ({ icon, path, label }) => {
  return (
    <NavLink to={path} className="SidebarLink" activeClassName="is-active">
      <li>
        <FontAwesomeIcon className="SidebarLink__icon" icon={icon} />
        <div className="SidebarLink__tooltip">{label}</div>
      </li>
    </NavLink>
  );
};

export { SidebarLink };
