import { FC } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik } from "formik";
import * as Yup from "yup";
import { ModalHeader } from "components/Modal";
import { useToast } from "layouts/PortalLayout/Toast";
// import { SelectField } from 'components/formik/SelectField';
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { DateMaskField } from "components/formik/DateMaskField";
import { CheckboxesField } from "components/formik/CheckboxesField";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { LIST_EXPORTS } from "../../ExportsWidget/ExportsWidget";
import { Button } from "components/Button";

const CREATE_EXPORT = gql`
  mutation CreateDataExport($input: CreateDataExportInput!) {
    createDataExport(input: $input) {
      errors {
        key
        message
      }
      export {
        id
        filename
      }
    }
  }
`;

interface MutationData {
  createDataExport: {
    errors?: InputError[];
    export?: {
      id: string;
      filename: string;
    };
  };
}

const exportOptions = [
  { value: "appointments", label: "Appointments" },
  { value: "appointment_request_events", label: "Appointment Request Events" },
  { value: "iex_events", label: "Events for IEX" },
  { value: "requesting_providers", label: "Requesting Providers" },
];

const encryptionTypeOptions = [
  { value: "AES", label: "AES" },
  { value: "PGP", label: "PGP" },
];

type SystemSchedulingVolumeExportFormProps = {
  onClose: () => void;
  initialValues: {
    windowStart: string;
    windowFinish: string;
  };
};

export const SystemSchedulingVolumeExportForm: FC<SystemSchedulingVolumeExportFormProps> = (
  props
) => {
  const { onClose, initialValues } = props;
  const [createExport] = useMutation<MutationData>(CREATE_EXPORT);

  const toast = useToast();
  return (
    <div className="bg-white rounded-lg shadow-lg">
      <ModalHeader
        icon="file-download"
        title="Export CSV Report"
        onClose={onClose}
      />
      <div style={{ padding: "1rem" }}>
        <Formik
          initialValues={{
            windowStart: initialValues.windowStart,
            windowFinish: initialValues.windowFinish,
            password: "",
            encryptionType: "AES",
            exports: ["appointments"],
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .matches(/^[A-Za-z\d@$!%*#?&]+$/, {
                message: "Can only contain letters, numbers or @$!%*#?&",
                excludeEmptyString: true,
              })
              .min(8)
              .required("Required"),
            encryptionType: Yup.string()
              .oneOf(["AES", "PGP"], "Invalid selection")
              .required("Required"),
            exports: Yup.array()
              .min(1, "Must select at least 1")
              .required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            createExport({
              variables: { input: values },
              refetchQueries: [{ query: LIST_EXPORTS }],
            }).then((res) => {
              if (res && res.data) {
                if (res.data.createDataExport.errors) {
                  setStatus({
                    errors: res.data.createDataExport.errors,
                  });
                } else {
                  toast.success("Started data export.");
                  onClose();
                }
              }
              setSubmitting(false);
            });
          }}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="gap-x-3 grid grid-cols-2">
                <DateMaskField
                  name="windowStart"
                  label="Window Start"
                  minYear={2010}
                  maxYear={2040}
                />

                <DateMaskField
                  name="windowFinish"
                  label="Window End"
                  minYear={2010}
                  maxYear={2040}
                />
              </div>

              <div className="mt-3">
                <TextField
                  type="password"
                  name="password"
                  placeholder="••••••••••"
                  label="Password for file encryption"
                  icon="lock"
                  autoFocus
                />
              </div>

              <div className="mt-3">
                <RadioGroupField
                  name="encryptionType"
                  label="Encryption Type"
                  options={encryptionTypeOptions}
                />
              </div>

              <div className="mt-3">
                <CheckboxesField
                  name="exports"
                  label="Export Files"
                  options={exportOptions}
                />
              </div>
              <div style={{ margin: "1.5rem auto 1rem", textAlign: "center" }}>
                <Button
                  type="submit"
                  kind="primary"
                  color="gold"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Export
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
