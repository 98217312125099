import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { WebformSubmissionVolumeExportForm } from "./WebformSubmissionVolumeExportForm";

interface WebformSubmissionVolumeExportModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
  initialValues: {
    windowStart: string;
    windowFinish: string;
  };
}

export const WebformSubmissionVolumeExportModal: FC<WebformSubmissionVolumeExportModalProps> = (
  props
) => {
  const { isOpen, onClose, initialValues } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="file-download" title="Export CSV" onClose={onClose} />
      <div className="_WebformSubmissionVolumeExportModal p-4">
        <WebformSubmissionVolumeExportForm
          initialValues={initialValues}
          onSuccess={onClose}
        />
      </div>
    </Modal>
  );
};
