import { FC, useState, useCallback } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AppointmentModel } from "../../AppointmentRequestShowPage";
import {
  ProviderResultsFetcher,
  Provider as ProviderType,
  Slot as SlotType,
} from "pages/ProviderResultsPage/ProviderResultsFetcher";
import { BlockSpinner } from "components/BlockSpinner";
import { RescheduleForm, JustRescheduleForm } from "./RescheduleForm";
import { AppointmentInfo } from "components/AppointmentInfo";
import { FAIcon } from "components/FAIcon";
import { ProviderResult } from "./ProviderResult";

export interface SelectedAppointment {
  provider: ProviderType;
  slot: SlotType;
}

interface RescheduleAppointmentModalProps {
  appointment: null | AppointmentModel;
  isOpen: boolean;
  onClose(): void;
}

export const RescheduleAppointmentModal: FC<RescheduleAppointmentModalProps> = (
  props
) => {
  const { appointment, isOpen, onClose } = props;
  const [
    newAppointment,
    selectAppointment,
  ] = useState<SelectedAppointment | null>(null);

  const forgetSelection = useCallback(() => {
    selectAppointment(null);
  }, []);

  const closeAndForgetSlot = useCallback(() => {
    forgetSelection();
    onClose();
  }, [forgetSelection, onClose]);

  if (appointment === null) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeAndForgetSlot}
      className="_RescheduleAppointmentModal max-w-7xl"
    >
      <div
        className="pb-4 rounded-lg shadow-lg"
        style={{ backgroundColor: "#f2f6fa", minWidth: 600 }}
      >
        <ModalHeader
          icon="calendar-alt"
          title="Reschedule Appointment"
          onClose={closeAndForgetSlot}
        />
        {!newAppointment ? (
          <div
            id="reschedule-search-results"
            className="Modal__section text-center"
          >
            <ProviderResultsFetcher
              appointmentRequestId={appointment.appointmentRequestId}
            >
              {({ loading, results }) =>
                loading ? (
                  <div className="ProviderResultsPage__loading-container">
                    <div style={{ margin: "2rem auto" }}>
                      <BlockSpinner />
                    </div>
                  </div>
                ) : !results ||
                  (results && results.items && results.items.length === 0) ? (
                  <p>No results</p>
                ) : (
                  <div>
                    {results.items.map((providerResult) => (
                      <ProviderResult
                        key={providerResult.provider.id}
                        providerResult={providerResult}
                        onSlotClick={selectAppointment}
                      />
                    ))}
                  </div>
                )
              }
            </ProviderResultsFetcher>
          </div>
        ) : (
          <>
            <div
              id="reschedule-confirmation"
              className="Modal__section max-w-xl p-4 mx-auto mb-2 rounded-lg"
            >
              <button type="button" className="a" onClick={forgetSelection}>
                <span className="icon-container">
                  <FAIcon icon="arrow-left" />
                </span>
                Back to Results
              </button>
            </div>
            <div className="Modal__section max-w-xl p-4 mx-auto mb-2 bg-white rounded-lg">
              <div className="flex justify-around">
                <div className="column">
                  <label className="block pt-1 pb-3 text-base font-semibold">
                    Old Appointment
                  </label>
                  <AppointmentInfo
                    startTimeString={appointment.startTimeString}
                    startTime={appointment.timeRange.start}
                    providerName={
                      appointment.receivingProvider.nameWithAppellation
                    }
                  />
                </div>
                <div className="column">
                  <label className="block pt-1 pb-3 text-base font-semibold">
                    New Appointment
                  </label>
                  <AppointmentInfo
                    startTimeString={
                      newAppointment.slot.timeRange.startTimeString
                    }
                    startTime={newAppointment.slot.timeRange.start}
                    providerName={newAppointment.provider.nameWithAppellation}
                  />
                </div>
              </div>
            </div>
            {appointment.cancelled || appointment.missed ? (
              <div className="Modal__section max-w-xl p-4 mx-auto mb-2 bg-white rounded-lg">
                <JustRescheduleForm
                  onSuccess={closeAndForgetSlot}
                  oldAppointment={appointment}
                  newAppointment={newAppointment}
                  forgetAppointmentSelection={forgetSelection}
                />
              </div>
            ) : (
              <div className="Modal__section max-w-xl p-4 mx-auto mb-2 bg-white rounded-lg">
                <RescheduleForm
                  onSuccess={closeAndForgetSlot}
                  oldAppointment={appointment}
                  newAppointment={newAppointment}
                  forgetAppointmentSelection={forgetSelection}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
