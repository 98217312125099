import React, { FC, useState } from "react";
import cx from "classnames";
import gql from "graphql-tag";
import { parseISO, lightFormat } from "date-fns";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-apollo";
import { FAIcon } from "components/FAIcon";
import "./LoginAttempts.css";

type LoginAttempt = {
  id: string;
  insertedAt: string;
  successful: boolean;
  requestInfo: {
    remoteIp: string;
    userAgent: string;
    clientLocation?: string;
    latitude?: number;
    longitude?: number;
  };
};

/**
 * LoginAttemptDetails.
 */

interface LoginAttemptDetailsProps {
  loginAttempt: LoginAttempt;
}

const LoginAttemptDetails: FC<LoginAttemptDetailsProps> = ({
  loginAttempt,
}) => (
  <div>
    <ul>
      <li>
        <strong>ID</strong>: {loginAttempt.id}
      </li>
      <li>
        <strong>Time</strong>: {loginAttempt.insertedAt}
      </li>
      <li>
        <strong>Success / Failure</strong>:{" "}
        {loginAttempt.successful ? "Success" : "Failure"}
      </li>
      <li>
        <strong>User Agent</strong>: {loginAttempt.requestInfo.userAgent}
      </li>
      <li>
        <strong>Remote IP</strong>: {loginAttempt.requestInfo.remoteIp}
      </li>
      <li>
        <strong>Location</strong>: {loginAttempt.requestInfo.clientLocation}
      </li>
    </ul>
  </div>
);

const LOGIN_ATTEMPTS_QUERY = gql`
  query GetOrganizationUser($id: UUID4!) {
    organizationUser(id: $id) {
      id
      firstName
      lastName
      confirmedAt
      role
      loginAttempts {
        id
        insertedAt
        successful
        requestInfo {
          remoteIp
          userAgent
          clientLocation
          latitude
          longitude
        }
      }
    }
  }
`;

interface Data {
  organizationUser: {
    id: string;
    firstName: string;
    lastName: string;
    confirmedAt?: string;
    role: string;
    loginAttempts: LoginAttempt[];
  };
}

interface RouteParams {
  userId: string;
}

export const LoginAttempts: FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { userId } = match.params;
  const [selectedAttempt, selectAttempt] = useState<LoginAttempt | null>(null);
  const { loading, error, data } = useQuery<Data>(LOGIN_ATTEMPTS_QUERY, {
    variables: { id: userId },
  });

  return (
    <>
      <div className="p-4">
        {loading ? (
          <h1>Loading...</h1>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : data.organizationUser.loginAttempts.length === 0 ? (
          <div className="text-center" style={{ padding: "1rem 0 3rem" }}>
            <p style={{ fontSize: 72, color: "#ccc", margin: "1rem 0" }}>
              ¯\_(ツ)_/¯
            </p>
            <h3 className="subtitle is-3">No Login Attempts</h3>
            <p>There are no login attempts for this user's account yet.</p>
          </div>
        ) : (
          <div className="LoginAttempts gap-x-3 flex">
            <div className="flex-grow flex-shrink-0">
              <table className="is-fullwidth table">
                <tbody>
                  {data.organizationUser.loginAttempts.map(
                    (loginAttempt: LoginAttempt) => (
                      <tr
                        className={cx({
                          success: loginAttempt.successful,
                          failure: !loginAttempt.successful,
                          "is-active":
                            selectedAttempt &&
                            selectedAttempt.id === loginAttempt.id,
                        })}
                        key={loginAttempt.id}
                        onClick={() => selectAttempt(loginAttempt)}
                      >
                        <td>
                          <FAIcon
                            icon={
                              loginAttempt.successful
                                ? "check-circle"
                                : "times-circle"
                            }
                          />
                        </td>
                        <td>
                          {loginAttempt.requestInfo.clientLocation
                            ? loginAttempt.requestInfo.clientLocation
                            : "Unknown Location"}
                        </td>
                        <td>
                          {lightFormat(
                            parseISO(loginAttempt.insertedAt),
                            "MM/dd/yy - h:mm a"
                          )}
                        </td>
                        <td>{loginAttempt.requestInfo.remoteIp}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex-grow">
              {selectedAttempt === null ? (
                <pre>Select a login attempt to view details</pre>
              ) : (
                <LoginAttemptDetails loginAttempt={selectedAttempt} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
