import React from "react";

/**
 * This module provides 3 components:
 * - NavTitleProvider,
 * - NavTitleDisplay, and
 * - NavTitle
 *
 * They are created to allow context-based local state updates across
 * the component hierarchy (i.e. without relying on parent-child prop passing).
 *
 * These components are specifically for updating the text title of the NavBar
 * at the top of the portal UI layout.
 *
 * Here's how it works:
 * - NavTitleProvider is at the top of the component tree in the PortalLayout.
 *    - It makes the NavTitleContext available to all descendents
 * - NavTitleDisplay taps into that context to pull out the `title` value to
 *   display it.
 * - NavTitle is used in route/page components. It doesn't render anything, but
 *   mounting it has the side-effect of updating the value through a callback in
 *   context. So, `<NavTitle title="Analytics" />` sets the displayed value in
 *   `<NavTitleDisplay />` to "Analytics".
 *
 */

// Create a context for the NavTitle with "" as the default value.
const NavTitleContext = React.createContext({ title: "" });

class NavTitleProvider extends React.Component {
  /**
   * NB: We have our "update" function in `state`, which we pass into `value`.
   * This is due to a caveat in how the need to re-render a consumer is
   * determined.
   * See here: https://reactjs.org/docs/context.html#caveats
   */
  state = {
    title: "",
    updateTitle: (title: string) => this.setState({ title })
  };

  render() {
    const { children } = this.props;
    return (
      <NavTitleContext.Provider value={this.state}>
        {children}
      </NavTitleContext.Provider>
    );
  }
}

const NavTitleConsumer = NavTitleContext.Consumer;

class NavTitleDisplay extends React.Component {
  render() {
    return (
      <NavTitleConsumer>{({ title }) => <span>{title}</span>}</NavTitleConsumer>
    );
  }
}

type NavTitleProps = {
  title: string;
};

class NavTitle extends React.Component<NavTitleProps> {
  static contextType = NavTitleContext;
  componentDidMount() {
    const { title } = this.props;
    this.context.updateTitle(title);
  }
  componentWillUnmount() {
    this.context.updateTitle("");
  }
  render() {
    return null;
  }
}

export { NavTitle, NavTitleProvider, NavTitleDisplay };
